import { logger } from "./log";
let testConsole = logger(false);

const restoreSelectionAfterCorrections = (
  oldDom,
  newDom,
  targetStartOffset,
  targetEndOffset,
  collapsed,
  startAtNextNode,
  endAtNextNode,
) => {
  const textContentDiff = oldDom.textContent.length - newDom.textContent.length;

  //when delete in the middle, new cursor is at old start - textContentDiff
  if (textContentDiff > 0) {
    targetStartOffset -= textContentDiff;
    targetEndOffset = targetStartOffset;
  }
  //when add, new cursor is at old end + textContentDiff
  if (textContentDiff < 0) {
    targetEndOffset += textContentDiff;
    targetStartOffset = targetEndOffset;
  }

  testConsole.log({
    targetStartOffset,
    targetEndOffset,
    textContentDiff,
    startAtNextNode,
    endAtNextNode,
  });
  if (newDom.nodeName !== "#text") {
    return traverseChildNodes(newDom, {
      startOffset: targetStartOffset,
      endOffset: targetEndOffset,
      startContainer: newDom,
      endContainer: newDom,
      collapsed,
      startAtNextNode,
      endAtNextNode,
    }).res;
  } else {
    return {
      startContainer: newDom,
      endContainer: newDom,
      startOffset: targetStartOffset,
      endOffset: targetEndOffset,
    };
  }
};

const traverseChildNodes = (node, res, foundStart = false) => {
  for (let child of node.childNodes) {
    testConsole.log(child.nodeName, child.textContent, { foundStart });
    const len = child.textContent.length;
    if (!foundStart) {
      testConsole.log("not found start yet", {
        len,
        startOffset: res.startOffset,
      });
      // not found start node yet
      if (len < res.startOffset) {
        testConsole.log("start node is not in this child");
        // start node is not in this child
        res.startOffset -= len;
        res.endOffset -= len;
      } else {
        if (child.nodeName === "#text" || child.childNodes.length === 0) {
          if (res.startAtNextNode) {
            testConsole.log("start node is next node", {
              len,
              endOffset: res.endOffset,
            });
            res.startOffset -= len;
            res.endOffset -= len;
            res.startAtNextNode = false;
          } else {
            testConsole.log("start node is child", {
              len,
              endOffset: res.endOffset,
            });
            // start node is child
            foundStart = true;
            res.startContainer = child;
            if (res.collapsed) {
              res.endContainer = child;
              return { foundEnd: true, foundStart: true, res };
            } else if (len < res.endOffset) {
              testConsole.log("end node is not in this child");
              // end node is not in this child
              res.endOffset -= len;
            } else {
              if (res.endAtNextNode) {
                testConsole.log("end node is next node", {
                  len,
                  endOffset: res.endOffset,
                });
                res.endOffset -= len;
                res.endAtNextNode = false;
              } else {
                // end node is also in this child
                res.endContainer = child;
                testConsole.log("end node is also in this child", res);
                return { foundEnd: true, foundStart: true, res };
              }
            }
          }
        } else {
          // start node is in child
          testConsole.log("start node is inside child");
          const returnObj = traverseChildNodes(child, res, false);
          foundStart = returnObj.foundStart;
          if (returnObj.foundEnd) {
            return { foundEnd: true, foundStart: true, res: returnObj.res };
          }
        }
      }
    } else {
      testConsole.log("only need to find end node");
      // only need to find end node now
      if (len < res.endOffset) {
        testConsole.log("end node is not in this child");
        // end node is not in this child
        res.endOffset -= len;
      } else {
        if (res.endAtNextNode) {
          testConsole.log("end node is next node", {
            len,
            endOffset: res.endOffset,
          });
          res.endOffset -= len;
          res.endAtNextNode = false;
        } else {
          if (child.nodeName === "#text") {
            // end node is child
            testConsole.log("end node is child");
            res.endContainer = child;
            return { foundEnd: true, foundStart: true, res };
          } else {
            // end node is in child
            testConsole.log("end node is inside child");
            const returnObj = traverseChildNodes(child, res, true);
            if (returnObj.foundEnd) {
              return { foundEnd: true, foundStart: true, res: returnObj.res };
            }
          }
        }
      }
    }
  }
  testConsole.log("returning", { foundEnd: false, foundStart, res });
  return { foundEnd: false, foundStart, res };
};

export default restoreSelectionAfterCorrections;
