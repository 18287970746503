import { Record, Map } from "immutable";
import applyDeltaToString from "./applyDeltaToString";
import { logger } from "./log";

const tokenConsole = logger(false);

const TOKEN_NEXT_ID = Symbol("TOKEN_NEXT_ID");
export default class Token extends Record(
  {
    id: 0,
    value: "DEFAULT_TOKEN",
    after: "",
    offsetInSentence: 0,
    correction: null,
    ignored: null,
    offsetInSentenceWithoutNewline: 0,
  },
  "Token",
) {
  // Int // String // String // Int (character offset) // optional String key // Boolean
  constructor(args = {}) {
    const id = args.id || Token[TOKEN_NEXT_ID]++;
    super({ ...args, id });
  }

  get text() {
    return `${this.value}${this.after}`;
  }
  /**
  applies a Delta to the Token
  
  @this {Token}
  @param {Delta} delta quill delta to apply
  @param {number} offset character offset in sentence
  @param {number} offsetWithoutNewline
  @param {string} prevCorrection prevCorrection id
  @param {string} prevIgnored prevIgnored id
  @param {boolean} isLastTokenInLastSentence
  @return {Object} delta: remainder of delta; token: updated token; tokenChanged: bool;
  */
  applyDelta(
    delta,
    offset,
    offsetWithoutNewline,
    prevCorrection = null,
    prevIgnored = null,
    isLastTokenInLastSentence = false,
  ) {
    let updatedToken = this;
    let correctionsDiff = Map();
    tokenConsole.log("token apply delta", {
      delta: delta,
      offset,
      prevCorrection,
    });
    const { delta: newDelta, str: newText } = applyDeltaToString(
      delta,
      this.text,
      isLastTokenInLastSentence,
    );
    const unchanged = this.text === newText;
    tokenConsole.log("after apply delta to string", {
      newDelta,
      newText,
      unchanged,
    });
    if (unchanged) {
      if (prevCorrection && prevCorrection === this.correction) {
        updatedToken = updatedToken.merge({
          offsetInSentence: offset,
          correction: null,
          ignored: null,
          offsetInSentenceWithoutNewline: offsetWithoutNewline,
        });
      } else if (prevIgnored && prevIgnored === this.ignored) {
        updatedToken = updatedToken.merge({
          offsetInSentence: offset,
          correction: null,
          ignored: null,
          offsetInSentenceWithoutNewline: offsetWithoutNewline,
        });
      } else {
        updatedToken = updatedToken.merge({
          offsetInSentence: offset,
          offsetInSentenceWithoutNewline: offsetWithoutNewline,
        });
      }
    } else {
      updatedToken = updatedToken.merge({
        value: newText || "",
        after: "",
        offsetInSentence: offset,
        offsetInSentenceWithoutNewline: offsetWithoutNewline,
        correction: null,
        ignored: null,
      });
    }
    if (this.correction && !updatedToken.correction) {
      correctionsDiff = correctionsDiff.set(this.correction, null);
    }
    return {
      delta: newDelta,
      token: updatedToken,
      tokenChanged: !unchanged,
      correctionsDiff,
    };
  }
} // class Token
Token[TOKEN_NEXT_ID] = 1;
