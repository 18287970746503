import { Record, Set, Map } from "immutable";

export class Transformation extends Record(
  {
    appliedAfterText: "DEFAULT_APPLIED_AFTER_TEXT", // String
    appliedText: "DEFAULT_APPLIED_TEXT", // String
    confidence: 0, // Number
    hasReplacement: false, // Boolean
    isSuggestion: false, // Boolean
    key: "DEFAULT_TRANSFORMATION_JOB_KEY", // String
    message: "DEFAULT_TRANSFORMATION_MESSAGE", // String
    penalty: 0, // Number
    requestId: -1, // TODO
    ruleId: "DEFAULT_TRANSFORMATION_RULE_ID", // String
    sentAfterTransform: "DEFAULT_SENT_AFTER_TRANSFORM", // Array<Token> or String ? TODO
    sentenceIndex: -1, // Int
    signature: "DEFAULT_TRANSFORMATION_SIGNATURE", // String
    tokensAdded: [], // Array<Token> or List<Token> ? TODO
    tokensAffected: [], // Array<Token> or List<Token> ? TODO
    trIdx: -1,
    type: "DEFAULT_TRANSFORMATION_TYPE",
  },
  "Transformation",
) {
  constructor(obj) {
    // TODO?
    super(obj);
  }
}

export class Correction extends Record(
  {
    afterText: "", // String
    applicable: true, // Boolean
    applied: -1, // Int
    caption: "Correction Needed", // String
    decoratedText: "", // String
    initialStartIndex: -1, // Int
    initialEndIndex: -1, // Int
    jobKey: "DEFAULT_CORRECTION_JOB_KEY", // String
    key: "DEFAULT_CORRECTION_KEY", // String
    message: "DEFAULT_CORRECTION_MESSAGE", // String
    signature: "DEFAULT_CORRECTION_SIGNATURE", // String
    transformations: [], // Array<Transformation> or List<Transformation> ? TODO
  },
  "Correction",
) {
  constructor(obj = {}) {
    const transformations = obj.transformations || [];
    super({
      ...obj,
      transformations: transformations.map(t => new Transformation(t)),
    });
  }
  get penalty() {
    return this.transformations.reduce((acc, t) => acc + t.penalty, 0);
  }
  get tokensAffected() {
    return this.transformations.reduce(
      (acc, t) => acc.union(t.tokensAffected),
      Set(),
    );
  }

  //save some correction info for future reference check if we want to ignore the future correction
  get basicInfo() {
    const firstTransformation = this.transformations[0];
    const tokensAddedWithConfidence = this.transformations.reduce(
      (acc, transformation) =>
        acc +
        transformation.tokensAffected.reduce(
          (finalText, token) => finalText + token.value + token.after,
          "",
        ) +
        ":" +
        transformation.confidence +
        ":",
      "",
    );

    return Map({
      appliedText: firstTransformation.appliedText,
      appliedAfterText: firstTransformation.appliedAfterText,
      isSuggestion: firstTransformation.isSuggestion,
      hasReplacement: firstTransformation.hasReplacement,
      ruleId: firstTransformation.ruleId,
      requestId: firstTransformation.requestId,
      penalty: firstTransformation.penalty,
      tokensAddedWithConfidence,
    });
  }
}
