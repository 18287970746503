import { logger } from "./log";
let testConsole = logger(false);

const getTargetStartEndOffset = (dom, selectionRange) => {
  const {
    startContainer,
    endContainer,
    startOffset,
    endOffset,
    collapsed,
  } = selectionRange;
  const {
    characterStartOffset,
    characterEndOffset,
  } = getContainerCharOffsetFromDom(dom, selectionRange, false);
  const startAtNextNode = characterStartOffset !== 0 && startOffset === 0;
  const endAtNextNode = collapsed
    ? startAtNextNode
    : characterEndOffset !== 0 && endOffset === 0;
  testConsole.log({ characterStartOffset, characterEndOffset });

  return {
    targetStartOffset:
      getUpdatedOffsetForNonTextnode(startContainer, startOffset) +
      characterStartOffset,
    targetEndOffset:
      getUpdatedOffsetForNonTextnode(endContainer, endOffset) +
      characterEndOffset,
    startAtNextNode,
    endAtNextNode,
  };
};

const getUpdatedOffsetForNonTextnode = (node, offset) => {
  if (node.nodeName !== "#text") {
    return offset === 0 ? 0 : node.textContent.length;
  }
  return offset;
};

const getContainerCharOffsetFromDom = (tree, selectionRange, foundStart) => {
  const { startContainer, endContainer, collapsed } = selectionRange;
  testConsole.log(
    tree.nodeName,
    tree.textContent,
    startContainer.nodeName,
    startContainer.textContent,
    endContainer.nodeName,
    endContainer.textContent,
    foundStart,
  );
  let characterStartOffset = 0;
  let characterEndOffset = 0;
  let foundEnd = false;
  let currentFoundStart = foundStart;
  if (tree === startContainer && tree === endContainer) {
    return {
      foundStart: true,
      foundEnd: true,
      characterStartOffset,
      characterEndOffset,
    };
  }
  const childNodes = Array.from(tree.childNodes);
  for (let i = 0; i < childNodes.length; i++) {
    const child = childNodes[i];
    if (child === startContainer) {
      testConsole.log("child is startContainer");
      currentFoundStart = true;
    }
    if (child === endContainer) {
      testConsole.log("child is endContainer");
      foundEnd = true;
      return {
        foundStart: true,
        foundEnd,
        characterStartOffset,
        characterEndOffset,
      };
    }
    testConsole.log("child is not start node or end node");
    if (currentFoundStart) {
      testConsole.log("start node already found");
      // start already found
      if (child.nodeType === 3 || !child.contains(endContainer)) {
        testConsole.log("endContainer is not in child");
        characterEndOffset += child.textContent.length;
      } else {
        testConsole.log("child contains endContainer");
        const res = getContainerCharOffsetFromDom(
          child,
          selectionRange,
          currentFoundStart,
        );
        characterEndOffset += res.characterEndOffset;
        if (res.foundEnd) {
          return {
            foundStart: true,
            foundEnd: true,
            characterStartOffset,
            characterEndOffset,
          };
        }
      }
    } else {
      testConsole.log("start node not found yet");
      if (child.nodeType === 3 || !child.contains(startContainer)) {
        testConsole.log("child not contain start node");
        characterStartOffset += child.textContent.length;
        characterEndOffset += child.textContent.length;
      } else {
        testConsole.log("child contain start node");
        const res = getContainerCharOffsetFromDom(
          child,
          selectionRange,
          currentFoundStart,
        );
        characterStartOffset += res.characterStartOffset;
        characterEndOffset += res.characterEndOffset;
        currentFoundStart = res.foundStart;
        if (res.foundEnd || collapsed) {
          testConsole.log("end node found or collasped");
          return {
            foundStart: true,
            foundEnd: true,
            characterStartOffset,
            characterEndOffset,
          };
        }
      }
    }
  }
  testConsole.log("returning", {
    foundStart: currentFoundStart,
    foundEnd: false,
    characterStartOffset,
    characterEndOffset,
  });
  return {
    foundStart: currentFoundStart,
    foundEnd: false,
    characterStartOffset,
    characterEndOffset,
  };
};

export default getTargetStartEndOffset;
