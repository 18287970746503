import React, { Component } from "react";
import io from "socket.io-client";
import { Map, OrderedMap, Set } from "immutable";
import Delta from "quill-delta";
import _ from "lodash";
import Draft from "./draft";
import EditorContext from "../../utils/context";
import { isFirefox, isIE } from "../../utils/browserDetection";

import {
  parseTransformationKey,
  correctionKey,
  sentenceKey,
  parseCorrectionKey,
} from "../../utils/keys";

import WaterMark from "./waterMark";
import PortableTooltip from "./portableTooltip";
import entityStyles from "../../css/ptEntity.css";
import { Config } from "../../utils/configSchema";
import { getElementStyles } from "../../utils/css";
import initObservables from "../../utils/initObservables";
import initConsoles from "../../utils/initConsoles";
import { traverseContentEditableDomText } from "../../utils/dom";
import { generateInstantLoadResponse } from "../../utils/generateInstantLoadResponse";

const extractPosition = (editor, entityPosition) => {
  const editorPosition = editor.getBoundingClientRect();
  const editorMarginLeft = parseFloat(
    window
      .getComputedStyle(editor)
      .getPropertyValue("margin-left")
      .slice(0, -2),
  );
  const editorMarginTop = parseFloat(
    window
      .getComputedStyle(editor)
      .getPropertyValue("margin-top")
      .slice(0, -2),
  );
  const windowWidth = window.innerWidth;
  let x = entityPosition.left - editorPosition.left + editorMarginLeft;
  let y =
    entityPosition.top +
    entityPosition.height -
    editorPosition.top +
    editorMarginTop;
  // always get dynamic Y
  const xDelta = entityPosition.right - entityPosition.left;
  let xMoved = false;
  // if tooltip right bound is out of window size, move tooltip left top corner right
  if (entityPosition.left + 200 > windowWidth) {
    x = x - (220 - (windowWidth - entityPosition.left));
    xMoved = true;
  }

  return { x, y, xDelta, xMoved };
};

const defaultSocketUrl = "https://io.perfecttense.com";
const defaultApiEnv = "production";

class DraftEditor extends Component {
  constructor(props) {
    super(props);
    const {
      apiEnv,
      socketUrl,
      uid,
      clientId,
      clientSecret,
      extension,
      sandbox,
      targetBackgroundColor,
      targetElementId,
    } = props;
    const showLogs = apiEnv !== "production";
    initConsoles.call(this, showLogs, {
      jobConsole: false,
      responseConsole: false,
      observableConsole: false,
      errorConsole: true,
      correctionConsole: false,
      feedbackConsole: false,
      grammarScoreConsole: false,
      mouseMoveConsole: false,
      cursorConsole: false,
    });

    this.reconnectDelay = Math.floor(Math.random() * 4000 + 1000);
    this.instantLoad =
      [
        "5c01899e5d44777e7521f5aa",
        "5c01890472dc4a245753f064",
        "5c0188e01934ce64f6fa8934",
        "5b72efff50a9f779b453ccf0",
      ].indexOf(clientId) > -1;
    // we move the logic in observables.js to here to support multi instances on one page.
    // instead of import from observables.js, we use `this.xxx`
    // observables.js code starts here.
    initObservables.call(this);
    // observables.js code ends here.
    this.editorContainerId = `pt-editor-container-${targetElementId}`;
    this.currentText = "";
    let loading = false;
    this.socket = io(socketUrl, {
      autoConnect: false,
      reconnectionDelayMax: 600000,
      timeout: 600000,
      randomizationFactor: 0.8,
      reconnectionDelay: 2000,
      //reconnection: false,
      query: {
        clientId,
        clientSecret,
        extension,
        sandbox,
      },
    });
    if (uid) {
      loading = true;
    }
    this.mouseX = 0;
    this.mouseY = 0;
    this.state = {
      socketError: null,
      loading,
      focusedEntityKey: null,
      tooltipProps: {
        correction: null,
        position: {},
        sentenceId: 0,
        sentenceIndex: -1,
        visible: false,
      },
      groups: Map(),
      buffer: Set(),
      hoverOnCard: false,
      focusedEntityTop: 0,
      uid,
      assets: null,
      dynamicCssText: null,
      targetMarginRight: props.targetMarginRight,
      targetMarginBottom: props.targetMarginBottom,
      targetPaddingLeft: props.targetPaddingLeft,
      targetPaddingRight: props.targetPaddingRight,
      targetMargin: props.targetMargin,
      targetFontSize: 0,
      targetBackgroundColor,
      linkedKeyMap: Map(),
      config: Config(),
      editorHasScroll: false,
      targetPaddingBottom: props.targetPaddingBottom,
      targetScrollTop: 0,
      highlightEntity: null,
      targetOffsetHeight: props.targetOffsetHeight,
      targetLineHeight: props.targetLineHeight,
    }; // loading: boolean // groups: signature -> corrections // buffer: set of keys of jobs we're waiting for responses to
    this.currentSentence = OrderedMap(); // block key -> sentence index -> ...
    this.tokenizedMap = Map();
    this.mongoIdMap = Map();
    this.grammarScoreMap = Map();

    this.onClick = this.onClick.bind(this);
    this.handleTokenizedResponse = this.handleTokenizedResponse.bind(this);
    this.handleCorrectionsResponse = this.handleCorrectionsResponse.bind(this);
    this.handleUnchangedResponse = this.handleUnchangedResponse.bind(this);
    this.handleMongoId = this.handleMongoId.bind(this);
    this.exponentialBackoffReconnect = this.exponentialBackoffReconnect.bind(
      this,
    );
    this.updateFocusedCorrection = this.updateFocusedCorrection.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
    this.extractEntityClassname = this.extractEntityClassname.bind(this);
    this.accept = this.accept.bind(this);
    this.ignore = this.ignore.bind(this);
    this.registerEditorRef = this.registerEditorRef.bind(this);
    this.feedback = this.feedback.bind(this);
  } // constructor

  get config() {
    return this.configSubject.value;
  }

  /**
   * @method setTooltipProps
   * @param {object | (prev: object) => object} updater object to merge into props, or function mapping previous tooltip props to object to merge into props
   *
   * Updates `this.state.tooltipProps`; usage similar to `this.setState`.
   */
  setTooltipProps = updater => {
    this.setState(prevState => {
      const oldTooltipProps = prevState.tooltipProps;
      const newTooltipProps = _.isFunction(updater)
        ? { ...oldTooltipProps, ...updater(oldTooltipProps) }
        : { ...oldTooltipProps, ...updater };
      return { ...prevState, tooltipProps: newTooltipProps };
    });
  };

  componentDidUpdate(_prevProps, prevState) {
    const { textState, tooltipProps } = this.state;
    if (textState !== prevState.textState) {
      if (tooltipProps.correction) {
        const correctionDivUpdate = textState.divUpdates.find(
          du => du.removeUnderline && du.key === tooltipProps.correction.key,
        );
        if (correctionDivUpdate) {
          const replacementDivUpdate = textState.divUpdates.find(
            du =>
              du.addUnderline &&
              du.startOffset === correctionDivUpdate.startOffset,
          );
          if (replacementDivUpdate) {
            const { correction, sentenceId } = replacementDivUpdate;
            this.setTooltipProps({
              correction,
              sentenceId,
              sentenceIndex: textState.sentenceIndexMap.get(sentenceId),
              visible: true,
            });
          }
        }
      }
    }
  } // componentDidUpdate()

  componentDidMount() {
    const {
      clientId,
      clientSecret,
      contentEditable,
      css$,
      divContainer,
      extension,
      input$,
      mouseMove$,
      mouseOut$,
      sandbox,
      scrollTop$,
      target,
      tooltipObserver,
      underlineObserver,
    } = this.props;
    const ptEditorContainer = document.getElementById(this.editorContainerId);
    //seems like we are only using editorContainerDiv for scroll sync. so when contenteditable set to null
    const editorContainerDiv = contentEditable
      ? target
      : ptEditorContainer.firstElementChild;
    if (contentEditable) {
      this.editorContainer = target;
    }

    underlineObserver.subscribe(param => {
      if (param && param.correction) {
        // an underline/tooltip was moused in
        // set focused correction, position
        const { correction, position, sentenceId, sentenceIndex } = param;
        this.updateFocusedCorrection(
          correction,
          position,
          sentenceId,
          sentenceIndex,
        );
      }
    });

    tooltipObserver.subscribe(param => {
      if (param) {
        // tooltip should appear
        this.setTooltipProps({
          visible: true,
          reset: false,
        });
      } else {
        // tooltip should disappear
        this.setTooltipProps({
          correction: null,
          visible: false,
          reset: false,
        });
      }
    });

    const mouseInBetweenTooltipAndEntity = (
      mouseX,
      mouseY,
      entityRight,
      tooltipLeft,
      entityTop,
      entityBottom,
      tooltipTop,
    ) => {
      return (
        (mouseX >= tooltipLeft &&
          mouseX <= entityRight &&
          mouseY >= entityBottom &&
          mouseY <= tooltipTop) ||
        (mouseX >= entityRight &&
          mouseX <= tooltipLeft &&
          mouseY >= entityTop &&
          mouseY <= entityBottom)
      );
    };

    const mouseInTooltip = el => {
      while (el) {
        if (el.className.includes("pt-tooltip-div")) {
          return true;
        }
        el = el.parentElement;
      }
      return false;
    };

    const removeEntityBackgroundStyle = elClassName => {
      return elClassName
        .split(" ")
        .filter(
          className =>
            ![
              entityStyles["pt-correction-bg"],
              entityStyles["pt-suggestion-bg"],
              entityStyles["pt-applied-bg"],
              entityStyles["pt-remark-bg"],
            ].includes(className),
        )
        .join(" ");
    };

    if (!contentEditable) {
      css$.subscribe(mutationTarget => {
        if (!mutationTarget) {
          return;
        }
        let { targetBackgroundColor } = this.state;
        const css = getElementStyles(mutationTarget);
        const cssText = css.cssText;
        const cs = css.cs;
        const newBackgroundColor = cs.backgroundColor;
        const targetWidth = parseFloat(cs.width);
        const targetPaddingLeft = parseFloat(cs.paddingLeft);
        const targetPaddingRight = parseFloat(cs.paddingRight);
        const targetMarginBottom = parseFloat(cs.marginBottom);
        const targetMarginRight = parseFloat(cs.marginRight);
        const targetFontSize = parseFloat(cs.fontSize);
        const targetMargin = `${cs.marginTop} ${cs.marginRight} ${
          cs.marginBottom
        } ${cs.marginLeft}`;

        // fixing scroll with trailing new lines shadow div mis align by one line
        const targetLineHeight = cs.lineHeight;

        // fixing firefox padding bottom issue when scroll
        const targetHeight = parseFloat(cs.height);
        const targetPaddingBottom = parseFloat(cs.paddingBottom);
        let finalHeight = targetHeight;
        let overflowStyle = "";
        if (cs.boxSizing != "border-box") {
          overflowStyle = "overflow: hidden !important;";
        }
        const editorHasScroll =
          mutationTarget.clientHeight < mutationTarget.scrollHeight ||
          cs.overflowX == "scroll";
        if (editorHasScroll) {
          if (isFirefox()) {
            if (cs.overflowY != "hidden") {
              overflowStyle = "overflow-y: scroll !important;";
            }
          } else if (isIE()) {
            if (cs.overflowY != "hidden") {
              overflowStyle = "overflow-y: hidden !important;";
            }
          }
        }
        finalHeight = `height: ${finalHeight}px !important;`;

        // fix IE11 width when textarea has padding left/right
        let finalWidth = targetWidth;
        if (isIE()) {
          finalWidth += targetPaddingLeft + targetPaddingRight;
        }
        finalWidth = `width: ${finalWidth}px !important;`;

        let finalZIndex = parseInt(cs.zIndex);
        if (isNaN(finalZIndex) || finalZIndex == 0) {
          finalZIndex = 0;
        } else {
          finalZIndex = finalZIndex - 1;
        }
        finalZIndex = `z-index: ${finalZIndex} !important;`;
        targetBackgroundColor =
          newBackgroundColor == "rgba(0, 0, 0, 0)"
            ? targetBackgroundColor
            : newBackgroundColor;
        const finalBackgroundColor = `background-color: ${targetBackgroundColor} !important;`;
        const finalBorderColor = "border-color: transparent !important;";
        const finalResize = "resize: none !important;";
        const finalOutlineColor = "outline-color: transparent !important;";
        const dynamicCssText = `${cssText} ${finalBackgroundColor} ${finalBorderColor} ${finalResize} ${finalHeight} ${finalWidth} ${overflowStyle} ${finalOutlineColor} ${finalZIndex} ${
          this.config.customCss
        }`;

        this.setState({
          dynamicCssText,
          targetMargin,
          targetMarginBottom,
          targetMarginRight,
          targetBackgroundColor,
          targetPaddingLeft,
          targetPaddingRight,
          targetPaddingBottom,
          editorHasScroll,
          targetScrollTop: mutationTarget.scrollTop,
          targetFontSize,
          targetLineHeight,
        });
        if (mutationTarget.style["background-color"] != "rgba(0, 0, 0, 0)") {
          mutationTarget.style["background-color"] = "transparent";
        }
      });
      mouseOut$.subscribe(e => {
        if (
          e &&
          !mouseInTooltip(document.elementFromPoint(e.clientX, e.clientY)) &&
          !contentEditable
        ) {
          // not on tooltip
          this.mouseMoveConsole.log("mouse out textarea");
          for (let el of e.target.previousElementSibling.getElementsByClassName(
            "pt-entity",
          )) {
            el.className = removeEntityBackgroundStyle(el.className);
          }
          tooltipObserver.next(null);
        }
      });
      mouseMove$.subscribe(e => {
        if (!e) {
          return;
        }
        if (contentEditable) {
          return;
        }
        this.mouseX = e.clientX;
        this.mouseY = e.clientY;
        // Trying to fix #164088173
        // let path = e.path;
        // path.unshift(ptEditorContainer.firstElementChild);
        // console.log(e.path, ptEditorContainer.firstElementChild, path);
        // const mousemoveParams = {
        //   bubbles: e.bubbles,
        //   cancelable: e.cancelable,
        //   clientX: e.clientX,
        //   clientY: e.clientY,
        //   defaultPrevented: e.defaultPrevented,
        //   layerX: e.layerX,
        //   layerY: e.layerY,
        //   movementX: e.movementX,
        //   movementY: e.movementY,
        //   offsetX: e.offsetX,
        //   offsetY: e.offsetY,
        //   pageX: e.pageX,
        //   pageY: e.pageY,
        //   path: path,
        //   screenX: e.screenX,
        //   screenY: e.screenY,
        //   timeStamp: e.timeStamp,
        //   x: e.x,
        //   y: e.y,
        // };
        // console.log(new MouseEvent("mousemove", mousemoveParams));
        // ptEditorContainer.dispatchEvent(
        //   new MouseEvent("mousemove", mousemoveParams),
        // );
        // return;
        const target = e.target;
        const currentPtDiv = target.previousElementSibling;
        const currentHighlightEntity = this.state.highlightEntity;
        const mouseX = e.clientX - target.getBoundingClientRect().left;
        const mouseY = e.clientY - target.getBoundingClientRect().top;
        this.mouseMoveConsole.log("mouse X Y", mouseX, mouseY, target);
        let currentElLeft, currentElRight, currentElTop, currentElBottom;
        if (currentHighlightEntity) {
          currentElLeft = currentHighlightEntity.offsetLeft - target.scrollLeft;
          currentElTop = currentHighlightEntity.offsetTop - target.scrollTop;
          currentElRight = currentElLeft + currentHighlightEntity.offsetWidth;
          currentElBottom = currentElTop + currentHighlightEntity.offsetHeight;
          this.mouseMoveConsole.log(
            "current highlight entity",
            currentElLeft,
            currentElRight,
            currentElTop,
            currentElBottom,
          );
          if (
            mouseX >= currentElLeft &&
            mouseX <= currentElRight &&
            mouseY >= currentElTop &&
            mouseY <= currentElBottom
          ) {
            // still on same entity
            this.mouseMoveConsole.log("still in", currentHighlightEntity);
            return;
          }
          // check if it's in tooltip
          const tooltip = currentPtDiv.getElementsByClassName(
            "pt-tooltip-div",
          )[0];
          if (tooltip) {
            this.mouseMoveConsole.log(
              "mouse move observer on tooltip uncommit",
            );
            const cssTranslateArr = tooltip.style.transform.split(",");
            if (cssTranslateArr.length == 2) {
              // if display is none, there will not be transform style attribute, cssTranslaterArr is [""].
              const tooltipLeft = parseFloat(
                cssTranslateArr[0].replace(/[^0-9.-]/g, ""),
              );
              const tooltipTop = parseFloat(
                cssTranslateArr[1].replace(/[^0-9.-]/g, ""),
              );
              const tooltipRight = tooltipLeft + tooltip.offsetWidth;
              const tooltipBottom = tooltipTop + tooltip.offsetHeight;
              this.mouseMoveConsole.log(
                "current tooltip entity",
                tooltipLeft,
                tooltipRight,
                tooltipTop,
                tooltipBottom,
              );
              // check if mouse in tooltip or the area between tooltip and entity
              if (
                (mouseX >= tooltipLeft &&
                  mouseX <= tooltipRight &&
                  mouseY >= tooltipTop &&
                  mouseY <= tooltipBottom) ||
                mouseInBetweenTooltipAndEntity(
                  mouseX,
                  mouseY,
                  currentElRight,
                  tooltipLeft,
                  currentElTop,
                  currentElBottom,
                  tooltipTop,
                )
              ) {
                this.mouseMoveConsole.log(
                  "still in tooltip",
                  currentHighlightEntity,
                );
                return;
              }
            }
          }
          this.mouseMoveConsole.log(
            "REMOVE CLASS AND DISPATCH MOUSE OUT FROM",
            currentHighlightEntity,
          );
          this.setState(
            {
              highlightEntity: null,
            },
            () => {
              if (currentHighlightEntity === null) {
                return;
              }
              currentHighlightEntity.classList.remove(
                entityStyles[
                  this.extractEntityClassname(currentHighlightEntity.className)
                ],
              );
              const me = document.createEvent("MouseEvents");
              me.initEvent("mouseout", true, true);
              currentHighlightEntity.dispatchEvent(me);
            },
          );
        }

        let newHighlightEl = null;
        for (let el of currentPtDiv.getElementsByClassName("pt-entity")) {
          const elXLeft = el.offsetLeft - target.scrollLeft;
          const elYTop = el.offsetTop - target.scrollTop;
          const elXRight = elXLeft + el.offsetWidth;
          const elYBottom = elYTop + el.offsetHeight + 3; // 3 for the underline
          const container = this.editorContainer; //document.getElementById(this.editorContainerId);
          this.mouseMoveConsole.log(
            "current el",
            elXLeft,
            elXRight,
            elYTop,
            elYBottom,
          );
          if (elXRight > container.offsetWidth) {
            // TODO: for element on multiple lines
            if (
              mouseX >= elXLeft &&
              mouseX <= elXRight &&
              mouseY >= elYTop &&
              mouseY <= elYBottom
            ) {
              newHighlightEl = el;
              break;
            }
          } else {
            if (
              mouseX >= elXLeft &&
              mouseX <= elXRight &&
              mouseY >= elYTop &&
              mouseY <= elYBottom
            ) {
              newHighlightEl = el;
              break;
            }
          }
        }
        if (newHighlightEl) {
          this.mouseMoveConsole.log("in new entity", newHighlightEl);
          this.setState(
            {
              highlightEntity: newHighlightEl,
            },
            () => {
              for (let el of currentPtDiv.getElementsByClassName("pt-entity")) {
                el.className = removeEntityBackgroundStyle(el.className);
              }
              // removing background color for any other corrections
              newHighlightEl.classList.add(
                entityStyles[
                  this.extractEntityClassname(newHighlightEl.className)
                ],
              ); // adding background color
              const me = document.createEvent("MouseEvents");
              me.initEvent("mouseover", true, true);
              newHighlightEl.dispatchEvent(me);
            },
          );
          return;
        }
        this.mouseMoveConsole.log("not in any entity");
      });
      scrollTop$.subscribe(target => {
        const targetScrollTop = target.scrollTop;
        editorContainerDiv.scrollTop = targetScrollTop;
        this.setState({ targetScrollTop });
      });
    }

    input$.subscribe(param => {
      if (!param) {
        return;
      }
      const { realTarget: target, iframeEl } = param;
      const prevText = this.currentText;
      let newText = "";
      if (iframeEl) {
        newText = traverseContentEditableDomText(null, target).replace(
          /\xA0/g,
          " ",
        );
      } else {
        newText = (target.innerText || target.value || "").replace(
          /\xA0/g,
          " ",
        ); // backend cannot handle
      }
      this.currentText = newText;
      if (prevText !== newText) {
        const prevDelta = new Delta().insert(prevText);
        const newDelta = new Delta().insert(newText);
        const diff = prevDelta.diff(newDelta);
        this.editorStateSubject.next({ diff });
        this.hideTooltip();
      }
      const targetScrollTop = target.scrollTop;
      if (!contentEditable) {
        // textarea only, remove correction background color
        for (let el of divContainer.getElementsByClassName("pt-entity")) {
          el.className = removeEntityBackgroundStyle(el.className);
        }
      }
      if (this.instantLoad) {
        const jobKey = "1";
        this.editorStateSubject.next(
          generateInstantLoadResponse(`${jobKey}:${this.oTimestamp}`, jobKey),
        );
      }
      this.setState(
        () => {
          if (this.instantLoad) {
            return { targetScrollTop, targetFontSize: 21 };
          }
          return { targetScrollTop, targetOffsetHeight: target.offsetHeight };
        },
        () => {
          if (!contentEditable) {
            editorContainerDiv.scrollTop = targetScrollTop;
          }
        },
      );
    });
    const socket = this.socket;
    socket.on("error", message => {
      // eslint-disable-next-line
      this.errorConsole.log(`Socket Error: ${message}`);
      this.setState({
        socketError: message,
      });
    });
    socket.on("reconnect_attempt", number => {
      this.errorConsole.info(`${socket.id} Reconnecting attempt ${number}`);
      socket.io.opts.query = {
        clientId,
        clientSecret,
        extension,
        reconnecting: true,
        editorId: this.editorId,
        sandbox,
      };
    });
    socket.on("connect", () => {
      // eslint-disable-next-line
      this.reconnectDelay = Math.floor(Math.random() * 4000 + 1000);
      let subscription = this.job$.subscribe(job => {
        // TODO
        if (!job.key) {
          this.jobConsole.log("invalid job", JSON.stringify(job));
          return;
        }

        this.jobConsole.log("sending job: ", JSON.parse(job.getMessageJSON()));

        if (!this.instantLoad) {
          socket.emit(`${socket.id}:jobs`, job.getMessageJSON());
          this.editorStateSubject.next({ sentJob: job });
        }

        this.setState(
          prev => {
            const newBuffer = prev.buffer.add(job.key);
            return {
              ...prev,
              buffer: newBuffer,
            };
          },
          () => {
            this.onLoadingStateChanged();
          },
        );
      });
      socket.on(`${socket.id}:tokenized`, this.handleTokenizedResponse);
      socket.on(`${socket.id}:corrections`, this.handleCorrectionsResponse);
      socket.on(`${socket.id}:unchanged`, this.handleUnchangedResponse);
      socket.on(`${socket.id}:mongo`, this.handleMongoId);
      socket.on(`${socket.id}:editorId`, id => {
        this.editorId = id;
        this.responseConsole.log(`received editor id: ${id}`);
      });
      socket.on(`${socket.id}:config`, config => {
        this.responseConsole.log("received config: ", config);
        this.configSubject.next(Config({ ...this.config, ...config }));
      });
      socket.on(`${socket.id}:assets`, assets => {
        this.setState({ assets });
      });
      socket.on("disconnect", reason => {
        // eslint-disable-next-line
        console.log("[DISCONNECT] Reason: ", reason);
        subscription.unsubscribe();
      });
      socket.on("reconnect", attemptNumber => {
        // eslint-disable-next-line
        console.log(`[RECONNECTED] on Attempt Number ${attemptNumber}`);
      });
    });
    socket.connect();
  }

  componentWillUnmount() {
    this.socket.close();
  }

  setEditorId(id) {
    this.editorId = id;
  }

  exponentialBackoffReconnect() {
    const thisSocket = this.socket;
    if (!thisSocket.connected) {
      const { clientId, clientSecret, extension, sandbox } = this.props;
      thisSocket.io.opts.query = {
        clientId,
        clientSecret,
        extension,
        reconnecting: true,
        editorId: this.editorId,
        sandbox,
      };
      this.reconnectDelay = this.reconnectDelay * 2;
      thisSocket.connect();
    }
  }

  handleMongoId({ key, mongoId }) {
    this.mongoIdMap = this.mongoIdMap.set(key, mongoId);
  }

  onLoadingStateChanged() {
    this.props.onLoadingStateChanged(this.state.buffer.size);
  }

  feedback(params) {
    if (this.config.sendFeedback) {
      this.feedbackConsole.log("sending feedback with params: ", params);
      this.socket.emit(`${this.socket.id}:feedback`, JSON.stringify(params));
    }
  }

  handleCorrectionsResponse(message) {
    this.responseConsole.log("received corrections response: ", message);
    this.responseSubject.next({ response: message });
  }

  handleUnchangedResponse(message) {
    this.responseConsole.log(
      `received unchanged response: ${JSON.stringify(message)}`,
    );
    this.responseSubject.next({ response: message });
  }

  handleTokenizedResponse(message) {
    this.responseConsole.log(
      `received tokenized response: ${JSON.stringify(message)}`,
    );
    this.responseSubject.next({ response: message });
  }

  onClick(event) {
    event.stopPropagation();
  }

  hideTooltip() {
    this.setTooltipProps({
      correction: null,
      visible: false,
      reset: false,
    });
  }

  accept(tKey, propSentenceIndex) {
    const { textState } = this.state;
    const args = parseTransformationKey(tKey);
    const {
      jobKey,
      sentenceIndex: jobSentenceIndex,
      correctionIndex,
      transformationIndex,
    } = args;
    const cKey = correctionKey({
      jobKey,
      sentenceIndex: jobSentenceIndex,
      correctionIndex,
    });
    const job = textState.jobMap.get(jobKey);
    const hasCorrection = textState.correctionMap.has(cKey);
    if (hasCorrection && job) {
      let sentenceId = textState.correctionMap.get(cKey);
      if (!sentenceId) {
        sentenceId = textState.sentenceIdMap.get(sentenceKey(args));
      }
      let sentenceIndex = textState.sentenceIndexMap.get(sentenceId, -1);
      if (sentenceIndex === -1) {
        if (propSentenceIndex !== -1) {
          sentenceIndex = propSentenceIndex;
        } else {
          sentenceIndex = textState.sentences.findIndex(s =>
            s.corrections.has(cKey),
          );
        }
      }
      if (-1 === sentenceIndex) {
        return;
      }
      const sentence = textState.sentences.get(sentenceIndex);
      const tokens = sentence.tokens;
      const preTokens = tokens.takeUntil(token => token.correction === cKey);
      const preOffset = preTokens.reduce(
        (acc, token) => acc + token.text.length,
        0,
      );
      const correction = sentence.corrections.get(cKey);
      if (!correction) {
        return;
      }
      const transformation = correction.transformations[transformationIndex];
      const backendTransformIndices = [transformation.trIdx];
      const acceptParams = {
        jobId: this.mongoIdMap.get(jobKey),
        transformIndex: transformationIndex,
        isAccepted: true,
        sentence: sentence.text,
        sentenceIndex: jobSentenceIndex,
        offset: preOffset,
        backendTransformIndices,
      };
      this.feedback(acceptParams);
    }
    this.editorStateSubject.next({ accept: tKey, propSentenceIndex });
  } // end accept method

  ignore(cKey, propSentenceIndex) {
    const { textState } = this.state;
    const hasCorrection = textState.correctionMap.has(cKey);
    const args = parseCorrectionKey(cKey);
    const { jobKey, sentenceIndex: jobSentenceIndex } = args;
    const job = textState.jobMap.get(jobKey);
    if (hasCorrection && job) {
      let sentenceId = textState.correctionMap.get(cKey);
      if (!sentenceId) {
        sentenceId = textState.sentenceIdMap.get(sentenceKey(args));
      }
      let sentenceIndex = textState.sentenceIndexMap.get(sentenceId, -1);
      if (sentenceIndex === -1) {
        if (propSentenceIndex !== -1) {
          sentenceIndex = propSentenceIndex;
        } else {
          sentenceIndex = textState.sentences.findIndex(s =>
            s.corrections.has(cKey),
          );
        }
      }
      if (sentenceIndex === -1) {
        return;
      }
      const sentence = textState.sentences.get(sentenceIndex);
      const tokens = sentence.tokens;
      const preTokens = tokens.takeUntil(token => token.correction === cKey);
      const preOffset = preTokens.reduce(
        (acc, token) => acc + token.text.length,
        0,
      );
      const correction = sentence.corrections.get(cKey);
      if (!correction) {
        return;
      }
      const backendTransformIndices = correction.transformations.map(
        t => t.trIdx,
      );
      const rejectParams = {
        jobId: this.mongoIdMap.get(args.jobKey),
        transformIndex: -1,
        isAccepted: false,
        sentence: sentence.text,
        sentenceIndex: jobSentenceIndex,
        offset: preOffset,
        backendTransformIndices,
      };
      this.feedback(rejectParams);
    }
    this.editorStateSubject.next({ ignore: cKey, propSentenceIndex });
  } // end ignore method

  extractEntityClassname(childClassName) {
    if (childClassName.includes("suggestion")) {
      return "pt-suggestion-bg";
    }
    if (childClassName.includes("replacement")) {
      return "pt-correction-bg";
    }
    return "pt-correction-bg";
  }

  updateFocusedCorrection = (
    correction,
    position,
    sentenceId,
    sentenceIndex,
  ) => {
    if (!correction) {
      this.hideTooltip();
      return;
    }
    const { x, y, xDelta, xMoved } = extractPosition(
      this.editorContainer,
      position,
    );
    this.setTooltipProps({
      correction,
      position,
      sentenceId,
      sentenceIndex,
      x: `${x}px`,
      y: `${y + 4}px`,
      xDelta,
      xMoved,
      reset: false,
    });
  };

  registerEditorRef(el) {
    this.editorContainer = el;
  }

  render() {
    const {
      unobstrusive,
      targetValue,
      targetPlaceHolder,
      setTextareaSelection,
      targetCssText,
      tooltipObserver,
      underlineObserver,
      targetOffsetWidth,
      shadowDivOffsetHeight,
      shadowDivOffsetWidth,
      showTextState,
      contentEditable,
      target,
      iframeEl,
      targetOffsetLeft,
      targetOffsetTop,
      targetClientRectRight,
      targetClientRectBottom,
      iframeClientRectLeft,
      iframeClientRectTop,
      shadowDiv,
    } = this.props;
    const {
      focusedEntityKey,
      tooltipProps,
      assets,
      targetMarginRight,
      targetMarginBottom,
      editorHasScroll,
      targetPaddingBottom,
      dynamicCssText,
      config,
      targetScrollTop,
      textState,
      targetFontSize,
      targetOffsetHeight,
      targetLineHeight,
    } = this.state;
    if (this.state.socketError) {
      return null;
    }
    const value = {
      uid: this.state.uid,
      focusedEntityKey,
      tooltipProps,
      accept: this.accept,
      reject: this.ignore,
      tooltipObserver,
      underlineObserver,
      targetFontSize,
      textState,
    };
    return (
      <div id={this.editorContainerId}>
        <EditorContext.Provider value={value}>
          {!contentEditable && (
            <Draft
              unobstrusive={unobstrusive}
              targetValue={targetValue}
              targetPlaceHolder={targetPlaceHolder}
              registerEditorContainer={this.registerEditorRef}
              targetCssText={targetCssText}
              dynamicCssText={dynamicCssText}
              targetScrollTop={targetScrollTop}
              setTextareaSelection={setTextareaSelection}
              targetPaddingBottom={targetPaddingBottom}
              textState={textState}
              target={target}
              targetLineHeight={targetLineHeight}
            />
          )}
          {unobstrusive && assets && (
            <WaterMark
              assets={assets}
              targetMarginRight={targetMarginRight}
              targetMarginBottom={targetMarginBottom}
              linkUrl={config.linkUrl}
              editorHasScroll={editorHasScroll}
              targetPaddingBottom={targetPaddingBottom}
              targetOffsetHeight={targetOffsetHeight}
              targetOffsetWidth={targetOffsetWidth}
              shadowDivOffsetHeight={shadowDivOffsetHeight}
              shadowDivOffsetWidth={shadowDivOffsetWidth}
              showTextState={showTextState}
              textState={textState}
              target={target}
              contentEditable={contentEditable}
              iframeEl={iframeEl}
              targetOffsetLeft={targetOffsetLeft}
              targetOffsetTop={targetOffsetTop}
              targetClientRectRight={targetClientRectRight}
              targetClientRectBottom={targetClientRectBottom}
              iframeClientRectLeft={iframeClientRectLeft}
              iframeClientRectTop={iframeClientRectTop}
              shadowDiv={shadowDiv}
            />
          )}
          <PortableTooltip
            underlineObserver={underlineObserver}
            assets={assets}
            contentEditable={contentEditable}
            target={target}
            iframeEl={iframeEl}
            accept={this.accept}
            reject={this.ignore}
            hideTooltip={this.hideTooltip}
            width="200px"
            {...tooltipProps}
          />
        </EditorContext.Provider>
      </div>
    );
  }
}

DraftEditor.defaultProps = {
  socketUrl: defaultSocketUrl,
  apiEnv: defaultApiEnv,
  iframeEl: null,
  onGrammarScoreChanged: () => {},
  onLoadingStateChanged: () => {},
  sandbox: false,
  showTextState: () => {},
};

export default DraftEditor;
