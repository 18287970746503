import { Record } from "immutable";

export class DivUpdate extends Record({
  startOffset: -1,
  oldText: null, // text without last token's after
  newText: null, // text with last token's after
  addUnderline: false,
  removeUnderline: false,
  key: "DEFAULT_DIV_UPDATE_KEY",
  sentenceId: 0,
  signature: "DEFAULT_DIV_UPDATE_SIGNATURE",
  oldAfter: "",
  correction: null,
}) {
  constructor(args) {
    let { key, signature } = args;
    if (args.correction) {
      if (!key) {
        key = args.correction.key;
      }
      if (!signature) {
        signature = args.correction.signature;
      }
    }
    super({ ...args, key, signature });
  }
}
