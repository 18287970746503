/**
 * Create a string key for a particular sentence wrt a job
 *
 * @param {Object} param0 object containing fields for key
 * @returns {string}
 */

const delimiter = "_"; //";";

export const sentenceKey = ({ jobKey, sentenceIndex }) =>
  [jobKey, sentenceIndex].join(delimiter);
//`${jobKey};${sentenceIndex}`;

export const parseSentenceKey = key => {
  const [jobKey, sentenceIndex] = key.split(delimiter);
  //const [_, jobKey, sentenceIndex] = /(.+);(\d+)/.exec(key);
  return { jobKey, sentenceIndex: Number(sentenceIndex) };
};

/**
 * Create a string key for a particular token wrt a job
 *
 * @param {Object} param0 object containing fields for key
 * tokenIndex is the index of token in correction response
 * @returns {string}
 */
export const tokenKey = ({ jobKey, sentenceIndex, tokenIndex }) =>
  [jobKey, sentenceIndex, tokenIndex].join(delimiter);
//`${sentenceKey({ jobKey, sentenceIndex })};${tokenIndex}`;

export const parseTokenKey = key => {
  const [jobKey, sentenceIndex, tokenIndex] = key.split(delimiter);
  //const [_, jobKey, sentenceIndex, tokenIndex] = /(.+);(\d+);(\d+)/.exec(key);
  return {
    jobKey,
    sentenceIndex: Number(sentenceIndex),
    tokenIndex: Number(tokenIndex),
  };
};

/**
 * Create a string key for a particular correction
 *
 * @param {Object} param0 object containing fields for key
 * @returns {string}
 */
export const correctionKey = ({ jobKey, sentenceIndex, correctionIndex }) =>
  [jobKey, sentenceIndex, correctionIndex].join(delimiter);
//`${sentenceKey({ jobKey, sentenceIndex })};${correctionIndex}`;

export const parseCorrectionKey = key => {
  const [jobKey, sentenceIndex, correctionIndex] = key.split(delimiter);
  // const [_, jobKey, sentenceIndex, correctionIndex] = /(.+);(\d+);(\d+)/.exec(
  //   key,
  // );
  return {
    jobKey,
    sentenceIndex: Number(sentenceIndex),
    correctionIndex: Number(correctionIndex),
  };
};

/**
 * Create a string key for a particular transformation
 *
 * @param {Object} param0 object containing fields for key
 * @returns {string}
 */
export const transformationKey = ({
  jobKey,
  sentenceIndex,
  correctionIndex,
  transformationIndex,
}) =>
  [jobKey, sentenceIndex, correctionIndex, transformationIndex].join(delimiter);
// `${correctionKey({
//   jobKey,
//   sentenceIndex,
//   correctionIndex,
// })};${transformationIndex}`;

export const parseTransformationKey = key => {
  const [
    jobKey,
    sentenceIndex,
    correctionIndex,
    transformationIndex,
  ] = key.split(delimiter);
  // const [
  //   _,
  //   jobKey,
  //   sentenceIndex,
  //   correctionIndex,
  //   transformationIndex,
  // ] = /(.+);(\d+);(\d+);(\d+)/.exec(key);
  return {
    jobKey,
    sentenceIndex: Number(sentenceIndex),
    correctionIndex: Number(correctionIndex),
    transformationIndex: Number(transformationIndex),
  };
};

/**
 * Parse info from correction signature from backend response where we format in io
 *
 * @param {String} signature
 * @returns {Object}
 */
export const parseSignature = signature => {
  const [
    ,
    blockKey,
    sentenceIndex,
    tokenAffectedIds,
    type,
  ] = /(.+):(\d+):(.+):(.+)/.exec(signature);
  return {
    blockKey,
    sentenceIndex,
    tokenAffectedIds: tokenAffectedIds
      .split(",")
      .filter(e => e !== "")
      .map(e => parseInt(e)),
    type,
  };
};
