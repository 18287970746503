import Delta from "quill-delta";
import ReactDOM from "react-dom";

import {
  underlineFragmentInnerSpanClasses,
  underlineFragmentInnerSpanStyles,
} from "../components/editor/underlineFragment";
import { applyDeltaToNodes, domLeavesInOrder } from "./dom";
import entityStyles from "../css/ptEntity.css";
import { logger } from "./log";

let removeDivUpdatesConsole = logger(false);

const getAttributesSet = el => {
  return Array.from(el.attributes).map(attr => attr.name);
};

const removeDivUpdates = (removedDivUpdates, target) => {
  const targetOwnerDocument = target.ownerDocument;
  removedDivUpdates.forEach(divUpdateToRemove => {
    const { key, oldText, newText, oldAfter } = divUpdateToRemove;
    removeDivUpdatesConsole.log("removing underline fragments", {
      key,
      oldText,
      newText,
      oldAfter,
    });
    const underlineSpans = Array.from(
      target.getElementsByClassName(`pt-entity pt-${CSS.escape(`ck${key}`)}`),
    );
    let delta = null;
    // newText === null <-> we're removing underline because it's changed/outdated, not accept/ignore
    // so there is no specified text we want to substitute in, we just want to keep text/formatting as is in the
    // editor but without our underline
    // when newText !== null, we have a specific string we want to substitute from accepting/ignoring
    // in that case, the underlined text will always match the oldText since if it were modified its underline would have been removed
    if (newText !== null) {
      const realOldText = underlineSpans.reduce(
        (acc, span) => acc + span.textContent,
        "",
      );
      delta = new Delta()
        .insert(realOldText + oldAfter)
        .diff(new Delta().insert(newText));
    }
    removeDivUpdatesConsole.log("underline spans: ", underlineSpans);
    for (let i = 0; i < underlineSpans.length; ++i) {
      const parent = underlineSpans[i].parentNode;
      const underlineSpan = underlineSpans[i].cloneNode(true);
      removeDivUpdatesConsole.log(
        "removing underline in: ",
        underlineSpan,
        underlineSpan.textContent,
      );
      const underlineSpanChildren = underlineSpan.childNodes;
      const totalDocFrag = targetOwnerDocument.createDocumentFragment();
      for (let j = 0; j < underlineSpanChildren.length; j++) {
        // use a DocumentFragment to create tree how we want it and insert all at once,
        // avoid weird things happening with the DOM/normalization
        const docFrag = targetOwnerDocument.createDocumentFragment();
        const currentNodeChild = underlineSpanChildren[j];
        removeDivUpdatesConsole.log(
          "current child",
          currentNodeChild,
          currentNodeChild.textContent,
        );
        const withCorrectionInnerSpan =
          currentNodeChild.className &&
          currentNodeChild.className.includes(entityStyles["pt-decorated"]);

        const innerSpan = currentNodeChild.cloneNode(true);
        let newInnerSpan = null;
        if (withCorrectionInnerSpan) {
          const innerSpanExtraAttributeNames = getAttributesSet(
            innerSpan,
          ).filter(a => !["class", "id", "style"].includes(a));
          const innerSpanExtraClasses = innerSpan.classList
            ? Array.from(innerSpan.classList).filter(
              s => !underlineFragmentInnerSpanClasses.includes(s),
            )
            : [];
          const innerSpanExtraStyles = innerSpan.style
            ? Array.from(innerSpan.style).filter(
              s => !underlineFragmentInnerSpanStyles.includes(s),
            )
            : [];
          if (
            innerSpanExtraAttributeNames.length > 0 ||
            innerSpanExtraClasses.length > 0 ||
            innerSpanExtraStyles.length > 0
          ) {
            newInnerSpan = innerSpan;
            newInnerSpan.removeAttribute("id");
            newInnerSpan.classList.remove(...underlineFragmentInnerSpanClasses);
            for (const ourStyle of underlineFragmentInnerSpanStyles) {
              newInnerSpan.style.removeProperty(ourStyle);
            }
          }
        }

        if (newInnerSpan) {
          docFrag.appendChild(newInnerSpan);
        } else {
          const innerNodes = Array.from(innerSpan.childNodes);
          for (const innerNode of innerNodes) {
            docFrag.appendChild(innerNode);
          }
        }
        const leaves = Array.from(domLeavesInOrder(docFrag)).filter(
          leaf => !!leaf.data,
        ); // all text nodes in the document fragment DOM tree, in-order, filter out non-textnode
        if (newText !== null) {
          removeDivUpdatesConsole.log(
            { delta, leaves },
            i + 1 === underlineSpans.length &&
              j + 1 === underlineSpanChildren.length,
          );
          delta = applyDeltaToNodes(
            delta,
            leaves,
            i + 1 === underlineSpans.length &&
              j + 1 === underlineSpanChildren.length, // whether this is the last underline span for this div update
          );
        }
        // check if it is a non-textNode and class include our correction class
        if (withCorrectionInnerSpan) {
          removeDivUpdatesConsole.log(
            "underlineSpanChildren, unmount component at node",
            currentNodeChild,
            docFrag,
            underlineSpan,
          );
          ReactDOM.unmountComponentAtNode(currentNodeChild);
          underlineSpan.replaceChild(docFrag, currentNodeChild);
          underlineSpan.normalize(); // merge adjacent Text nodes etc
        } // if correction span
      } // for underlineSpanChildren
      const childNodeLength = underlineSpan.childNodes.length;
      for (let i = 0; i < childNodeLength; i++) {
        removeDivUpdatesConsole.log(underlineSpan.firstChild);
        totalDocFrag.appendChild(underlineSpan.firstChild);
      }
      //ReactDOM.unmountComponentAtNode(underlineSpans[i]); // unmounting removes all children but not the container node itself
      parent.replaceChild(totalDocFrag, underlineSpans[i]);
      parent.normalize(); // merge adjacent Text nodes etc
    } // for underlineSpan
  }); // forEach divUpdateToRemove
};

export default removeDivUpdates;
