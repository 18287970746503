import React, { Component } from "react";
import MdNotInterested from "react-icons/lib/md/not-interested";
import { BehaviorSubject } from "rxjs";
import styleCss from "../../css/style.css";
import entityStyles from "../../css/ptEntity.css";
import { transformationKey, parseCorrectionKey } from "../../utils/keys";

class PortableTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetail: false,
      showDetailText: "Show Detail",
      hoverOnFooter: false,
      yDelta: 0,
      moved: false,
    };
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { correction, visible } = this.props;
    if (nextProps.correction !== correction) {
      return true;
    }
    if (nextProps.visible !== visible) {
      return true;
    }
    if (nextState.showDetail !== this.state.showDetail) {
      return true;
    }
    if (nextState.showDetailText !== this.state.showDetailText) {
      return true;
    }
    if (nextState.hoverOnFooter !== this.state.hoverOnFooter) {
      return true;
    }
    if (nextState.yDelta !== this.state.yDelta) {
      return true;
    }
    return false;
  };

  componentDidUpdate = prevProps => {
    const {
      correction,
      position,
      reset,
      sentenceId,
      sentenceIndex,
      underlineObserver,
      visible,
    } = this.props;
    if (reset) {
      this.setState({ moved: false, yDelta: 0 });
      return;
    }
    if (!this.state.moved) {
      const windowHeight = window.innerHeight;
      const topDistance = this.div.getBoundingClientRect().top;
      const selfHeight = this.div.clientHeight;
      if (topDistance + selfHeight > windowHeight) {
        // move Y and X
        const toMove = selfHeight - (windowHeight - topDistance);
        const yDelta = -Math.min(toMove + 5, topDistance - 5);
        this.setState({ yDelta, moved: true });
      } else {
        this.setState({ moved: true });
      }
    }
    if (visible && correction !== prevProps.correction) {
      underlineObserver.next({
        correction,
        position,
        sentenceId,
        sentenceIndex,
      });
    }
  };

  onMouseEnter = e => {
    e.stopPropagation();
    const {
      correction,
      contentEditable,
      iframeEl,
      underlineObserver,
      position,
      sentenceId,
      sentenceIndex,
    } = this.props;
    let currentEntitySpan = null;
    if (contentEditable) {
      //toggle hover on span
      // TODO [#165067108]
      if (iframeEl) {
        const innerDoc = iframeEl.contentDocument
          ? iframeEl.contentDocument
          : iframeEl.contentWindow.document;
        if (innerDoc && innerDoc.querySelector("[contenteditable='true']")) {
          const elements = innerDoc.getElementsByClassName(
            `pt-entity pt-${CSS.escape(`ck${correction.key}`)}`,
          );
          if (elements.length > 0 && elements[0].firstElementChild) {
            currentEntitySpan = elements[0].firstElementChild;
          }
        }
      } else {
        const elements = document.getElementsByClassName(
          `pt-entity pt-${CSS.escape(`ck${correction.key}`)}`,
        );
        if (elements.length > 0 && elements[0].firstElementChild) {
          currentEntitySpan = elements[0].firstElementChild;
        }
      }
    } else {
      currentEntitySpan = document.getElementById(correction.key);
    }
    if (currentEntitySpan && !contentEditable) {
      currentEntitySpan.classList.add(
        entityStyles[this.extractEntityClassname(currentEntitySpan.className)],
      );
    }
    underlineObserver.next({
      correction,
      position,
      sentenceId,
      sentenceIndex,
    });
  };

  onMouseLeave = e => {
    e.stopPropagation();
    const {
      correction,
      contentEditable,
      iframeEl,
      underlineObserver,
    } = this.props;
    let currentEntitySpan = null;
    if (contentEditable) {
      //toggle hover on span
      if (iframeEl) {
        const innerDoc = iframeEl.contentDocument
          ? iframeEl.contentDocument
          : iframeEl.contentWindow.document;
        if (innerDoc && innerDoc.querySelector("[contenteditable='true']")) {
          currentEntitySpan = innerDoc.getElementsByClassName(
            `pt-entity pt-${CSS.escape(`ck${correction.key}`)}`,
          )[0];
          if (currentEntitySpan) {
            currentEntitySpan = currentEntitySpan.firstElementChild;
          }
        }
      } else {
        currentEntitySpan = document.getElementsByClassName(
          `pt-entity pt-${CSS.escape(`ck${correction.key}`)}`,
        )[0];
        if (currentEntitySpan) {
          currentEntitySpan = currentEntitySpan.firstElementChild;
        }
      }
    } else {
      currentEntitySpan = document.getElementById(correction.key);
    }
    if (currentEntitySpan && !contentEditable) {
      currentEntitySpan.classList.remove(
        entityStyles[this.extractEntityClassname(currentEntitySpan.className)],
      );
    }
    underlineObserver.next(null);
  };

  onClick = e => {
    e.stopPropagation();
  };

  stopPropagationEvent = e => {
    e.stopPropagation();
  };

  getUpdatedY = () => {
    const { contentEditable, target, iframeEl } = this.props;
    let calculatedY = parseFloat(this.props.y.slice(0, -2));
    if (contentEditable) {
      if (iframeEl) {
        const innerDoc = iframeEl.contentDocument
          ? iframeEl.contentDocument
          : iframeEl.contentWindow.document;
        const iframeScrollY = innerDoc
          ? innerDoc.pageYOffset || innerDoc.documentElement.scrollTop || 0
          : 0;
        calculatedY +=
          iframeEl.getBoundingClientRect().top +
          (window.pageYOffset || document.documentElement.scrollTop || 0) -
          iframeScrollY;
      } else {
        calculatedY += target.offsetTop;
      }
    }
    return calculatedY + this.state.yDelta + "px";
  };

  getUpdatedX = () => {
    const { contentEditable, target, iframeEl } = this.props;
    let calculatedX = parseFloat(this.props.x.slice(0, -2));
    if (contentEditable) {
      if (iframeEl) {
        const innerDoc = iframeEl.contentDocument
          ? iframeEl.contentDocument
          : iframeEl.contentWindow.document;
        const iframeScrollX = innerDoc
          ? innerDoc.pageXOffset || innerDoc.documentElement.scrollLeft || 0
          : 0;
        calculatedX +=
          iframeEl.getBoundingClientRect().left +
          (window.pageXOffset || document.documentElement.scrollLeft || 0) -
          iframeScrollX;
      } else {
        calculatedX += target.offsetLeft;
      }
    }
    if (this.state.yDelta === 0) {
      return calculatedX + "px";
    }
    return this.state.yDelta !== 0 && !this.props.xMoved
      ? calculatedX + this.props.xDelta + "px"
      : calculatedX + "px";
  };

  extractEntityClassname = childClassName => {
    if (childClassName.includes("suggestion")) {
      return "pt-suggestion-bg";
    }
    if (childClassName.includes("replacement")) {
      return "pt-correction-bg";
    }
    return "pt-correction-bg";
  };

  acceptCorrection = i => {
    const { accept, correction, hideTooltip } = this.props;
    const acceptSpan = document.getElementById(correction.key);
    if (acceptSpan) {
      acceptSpan.classList.remove(
        entityStyles[this.extractEntityClassname(acceptSpan.className)],
      );
    }
    accept(
      transformationKey({
        ...parseCorrectionKey(correction.key),
        transformationIndex: i,
      }),
    );
    hideTooltip();
  };

  rejectCorrection = () => {
    const { reject, correction, hideTooltip } = this.props;
    const rejectSpan = document.getElementById(correction.key);
    if (rejectSpan) {
      rejectSpan.classList.remove(
        entityStyles[this.extractEntityClassname(rejectSpan.className)],
      );
    }
    reject(correction.key);
    hideTooltip();
  };

  render() {
    const { width, correction, visible, assets } = this.props;
    if (correction === null || !correction.key) {
      return null;
    }
    const changes = correction.transformations.map(t => t.appliedText);
    const onlyDisplayStyle = {
      display: "none",
    };
    const transformWidthStyle = {
      transform: `translate(${this.getUpdatedX()}, ${this.getUpdatedY()})`,
      width,
    };
    let style = !visible ? onlyDisplayStyle : transformWidthStyle;
    style["position"] = "absolute";
    return (
      <div
        className={`${styleCss["tooltip-div"]} pt-tooltip-div`}
        style={style}
        ref={node => (this.div = node)}
        onClick={this.onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseDown={this.stopPropagationEvent}
        onMouseUp={this.onMouseUp}
        onDoubleClick={this.stopPropagationEvent}
        onMouseMove={this.stopPropagationEvent}
        onMouseOut={this.stopPropagationEvent}
        onMouseOver={this.stopPropagationEvent}
        onFocus={this.stopPropagationEvent}
        onBlur={this.stopPropagationEvent}
        onScroll={this.stopPropagationEvent}
        onSelect={this.stopPropagationEvent}
        onPointerDown={this.stopPropagationEvent}
        onPointerEnter={this.stopPropagationEvent}
        onPointerLeave={this.stopPropagationEvent}
        onPointerMove={this.stopPropagationEvent}
        onPointerOut={this.stopPropagationEvent}
        onPointerOver={this.stopPropagationEvent}
        onPointerUp={this.stopPropagationEvent}
      >
        <p className={styleCss["p-tooltip-header"]}>{correction.caption}</p>
        <ul className={styleCss["p-tooltip-ul"]}>
          {changes.map((change, i) => (
            <li
              className={styleCss["p-tooltip-li"]}
              key={i}
              onClick={() => {
                this.acceptCorrection(i);
              }}
            >
              <small>{change}</small>
            </li>
          ))}
          <li
            className={styleCss["p-tooltip-li"]}
            style={{ fontSize: "0.7em" }}
            key="ignore"
            onClick={() => {
              this.rejectCorrection();
            }}
          >
            <MdNotInterested style={{ marginRight: "8px" }} />
            <small>Ignore</small>
          </li>
        </ul>
        <a
          href="https://www.perfecttense.com"
          target="_blank"
          className={styleCss["p-footer-icon-wrapper"]}
        >
          <p
            className={`${styleCss["p-tooltip-footer"]} ${
              styleCss["p-tooltip-footer-no-hover"]
            }`}
          >
            <span style={{ fontSize: "8px" }}>Powered by</span>
            <img
              className={styleCss["p-tooltip-footer-img"]}
              src={this.state.hoverOnFooter ? assets.logoNegative : assets.logo}
            />
          </p>
        </a>
      </div>
    );
  }
}

PortableTooltip.defaultProps = {
  x: "0px",
  y: "0px",
  correction: null,
  width: "200px",
  accept: () => {},
  reject: () => {},
  background: "black",
  xDelta: 0,
  xMoved: false,
  underlineObserver: new BehaviorSubject(null),
  reset: true,
};

export default PortableTooltip;
