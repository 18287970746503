import React, { Component } from "react";
import styled from "@emotion/styled";
import { isFirefox } from "../../utils/browserDetection";
import Underline from "./underline";
import EditorContext from "../../utils/context";
import { logger } from "../../utils/log";

let draftConsole = logger(false);

const styleWithDisplayInline = props => {
  return (
    (props.dynamicCssText &&
      props.dynamicCssText.includes("display: inline;")) ||
    (props.targetCssText && props.targetCssText.includes("display: inline;"))
  );
};

// TODO style
const StyledDiv = styled.div`
  ${props => props.dynamicCssText || props.targetCssText} margin: 0;
  color: transparent !important;
  -webkit-text-fill-color: transparent !important;
`;

// TODO style
const FirefoxStyledDiv = styled.div`
  ${props => props.dynamicCssText || props.targetCssText} margin: 0;
  color: transparent !important;
  -webkit-text-fill-color: transparent !important;
  ${props => (styleWithDisplayInline(props) ? "display: inline-block;" : "")};
`;

class SentenceComponent extends Component {
  shouldComponentUpdate(nextProps) {
    const { sentence } = this.props;
    const nextSentence = nextProps.sentence;
    if (!nextSentence.equals(sentence)) {
      if (sentence && nextSentence) {
        if (
          sentence.text !== nextSentence.text ||
          sentence.corrections !== nextSentence.corrections
        ) {
          draftConsole.log("===sentence changed, rerender===", sentence.toJS());
          return true;
        }
      }
    }
    return false;
  }

  render() {
    const { sentence } = this.props;
    draftConsole.log(
      "sentence is:",
      sentence.toJS(),
      sentence.getRanges().toJS(),
    );
    return sentence.getRanges().map(c => {
      draftConsole.log("c is:", c);
      if (null === c.correction) {
        return c.text;
      } else {
        return (
          <Underline
            entityKey={c.correction}
            correctionKey={c.correction}
            signature={c.signature}
            text={c.text}
            sentenceId={sentence.id}
            correction={sentence.corrections.get(c.correction)}
            {...this.props}
          />
        );
      }
    });
  }
} // class SentenceComponent

export default class Draft extends Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate = nextProps => {
    if (nextProps.dynamicCssText !== this.props.dynamicCssText) {
      draftConsole.log("dynamicCssText changed");
      return true;
    }
    if (nextProps.targetPaddingBottom !== this.props.targetPaddingBottom) {
      draftConsole.log(
        "targetPaddingBottom changed",
        this.props.targetPaddingBottom,
        nextProps.targetPaddingBottom,
      );
      return true;
    }
    const nextTextState = nextProps.textState;
    const curTextState = this.props.textState;
    if ((!nextTextState || !curTextState) && (nextTextState || curTextState)) {
      return true;
    }
    if (nextTextState && !nextTextState.equals(curTextState)) {
      if (curTextState && nextTextState) {
        if (
          curTextState.text !== nextTextState.text ||
          nextTextState.correctionMap !== curTextState.correctionMap
        ) {
          draftConsole.log("===some sentences changed, rerender===");
          return true;
        }
      }
    }
    return false;
  };

  componentDidUpdate = () => {
    this.container.scrollTop = this.props.targetScrollTop;
  };

  registerContainer = el => {
    this.container = el;
    this.props.registerEditorContainer(el);
  };

  render() {
    const {
      targetCssText,
      dynamicCssText,
      targetPaddingBottom,
      textState,
      target,
      targetLineHeight,
    } = this.props;

    const ContainerDiv = isFirefox() ? FirefoxStyledDiv : StyledDiv;
    let extraStyle = {};
    if (target.clientHeight < target.scrollHeight) {
      extraStyle["paddingBottom"] = targetLineHeight;
    }
    if (isFirefox()) {
      extraStyle["marginBottom"] = `${targetPaddingBottom}px`;
    }
    if (textState) {
      draftConsole.log("building editor", textState.toJS());
    }
    return (
      <EditorContext.Consumer>
        {value => (
          <ContainerDiv
            innerRef={this.registerContainer}
            targetCssText={targetCssText}
            dynamicCssText={dynamicCssText}
          >
            <div style={extraStyle}>
              {textState &&
                textState.sentences.map((sentence, index) => (
                  <SentenceComponent
                    key={index}
                    sentence={sentence}
                    sentenceIndex={index}
                    {...value}
                  />
                ))}
            </div>
          </ContainerDiv>
        )}
      </EditorContext.Consumer>
    );
  }
}
