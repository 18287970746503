import applyDeltaToString from "./applyDeltaToString";

export const applyDeltaToNodes = (delta, nodes, isLast) => {
  if (!delta || !delta.ops || delta.ops.length === 0) {
    return null;
  }
  for (let i = 0; i < nodes.length; ++i) {
    const node = nodes[i];
    let text = "";
    if (node.data) {
      const { delta: newDelta, str } = applyDeltaToString(delta, node.data);
      text = str;
      delta = newDelta;
    }
    if (
      isLast &&
      i + 1 === nodes.length &&
      delta &&
      delta.ops &&
      delta.ops.length > 0 &&
      delta.ops[0].insert
    ) {
      text += delta.ops[0].insert;
    } // if
    node.parentNode.replaceChild(node.ownerDocument.createTextNode(text), node);
  } // for
  return delta;
}; // applyDeltaToNodes

export const addManualSeparator = currentNode => {
  // TODO use regex instead of array of strings
  if (
    [
      "P",
      "BR",
      "LI",
      "H1",
      "H2",
      "H3",
      "H4",
      "H5",
      "H6",
      "PRE",
      "BLOCKQUOTE",
      "TD",
      "DIV",
    ].includes(currentNode.nodeName)
  ) {
    return "\n";
  }
  return "";
};

export const addManualSeparatorOffset = currentNode =>
  addManualSeparator(currentNode).length;

export const traverseContentEditableDomText = (parentNode, currentNode) => {
  let text = "";
  if (currentNode.nodeName === "#text" && parentNode !== null) {
    text += currentNode.data;
  } else {
    const children = Array.from(currentNode.childNodes).filter(
      child =>
        child.nodeName === "#text" ||
        child.getAttribute("contenteditable") !== "false",
    );
    for (const child of children) {
      text +=
        traverseContentEditableDomText(currentNode, child) +
        addManualSeparator(child);
    }
  }
  return text;
}; // traverseContentEditableDomText

export function* domLeavesInOrder(node, reverse = false) {
  if (node.nodeName === "#text") {
    yield node;
  } else {
    const children = (reverse
      ? Array.from(node.childNodes).reverse()
      : Array.from(node.childNodes)
    ).filter(
      child =>
        child.nodeName === "#text" ||
        child.getAttribute("contenteditable") !== "false",
    );
    for (const child of children) {
      yield* domLeavesInOrder(child, reverse);
    }
    if (children.length === 0) {
      yield node;
    }
    yield { extraOffset: addManualSeparator(node) };
  }
}

export function* domNodesPostOrder(node, reverse = false) {
  const children = (reverse
    ? Array.from(node.childNodes).reverse()
    : Array.from(node.childNodes)
  ).filter(
    child =>
      child.nodeName === "#text" ||
      child.getAttribute("contenteditable") !== "false",
  );
  for (const child of children) {
    yield* domNodesPostOrder(child, reverse);
  }
  yield node;
}

export function getNodeOffset(root, target) {
  let offset = 0;
  for (const node of domNodesPostOrder(root)) {
    if (node === target) {
      return offset;
    } else if (node.nodeName === "#text") {
      offset += cleanedNodeText(node).length;
    } else {
      offset += addManualSeparatorOffset(node);
    }
  }
  return -1;
}

export function getLeafAtOffset(root, targetOffset) {
  let currentOffset = 0;
  for (const node of domLeavesInOrder(root)) {
    let nodeLength = 0;
    if ("extraOffset" in node) {
      nodeLength = node.extraOffset.length;
    } else {
      nodeLength = cleanedNodeText(node).length;
    }

    if (
      currentOffset <= targetOffset &&
      targetOffset <= currentOffset + nodeLength &&
      !("extraOffset" in node)
    ) {
      return { node, offset: currentOffset };
    } else {
      currentOffset += nodeLength;
    }
  }
  return null;
}

const cleanedNodeText = node => (node.data ? node.data : "");
