export const getElementStyles = (el, psuedoEl = null) => {
  const cs = document.defaultView.getComputedStyle(el, psuedoEl);
  let str = "";
  for (let i = 0; i < cs.length; i++) {
    const style = cs[i];
    str += `${style}: ${cs.getPropertyValue(style)};`;
  }
  return {
    cs,
    cssText: str,
    el,
  };
};
