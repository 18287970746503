const correctionInCurrentNode = (
  nodeStartOffset,
  nodeEndOffset,
  correctionStartOffset,
  correctionEndOffset,
) => {
  return (
    (nodeStartOffset <= correctionStartOffset &&
      nodeEndOffset > correctionStartOffset) ||
    (nodeEndOffset >= correctionEndOffset &&
      nodeStartOffset < correctionEndOffset) ||
    (nodeStartOffset >= correctionStartOffset &&
      nodeEndOffset <= correctionEndOffset)
  );
};

export default correctionInCurrentNode;
