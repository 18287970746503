export const isOpera = () => {
  return (
    (!!window.opr && !!window.opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(" OPR/") >= 0
  );
};

// Firefox 1.0+
export const isFirefox = () => {
  return typeof InstallTrigger !== "undefined";
};

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari = () => {
  return (
    /constructor/i.test(window.HTMLElement) ||
    (function(p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof window.safari !== "undefined" &&
          window.safari.pushNotification),
    )
  );
};

// Internet Explorer 6-11
export const isIE = () => {
  return /*@cc_on!@*/ false || !!document.documentMode;
};

// Edge 20+
export const isEdge = () => {
  return !isIE && !!window.StyleMedia;
};

// Chrome 1+
export const isChrome = () => {
  return !!window.chrome && !!window.chrome.webstore;
};

// Blink engine detection
export const isBlink = () => {
  return (isChrome || isOpera) && !!window.CSS;
};
