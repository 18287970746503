// take any amount of args, do nothing
const nop = (..._) => {};

// object with console methods all nop
const nopcon = {};
for (const field in console) {
  nopcon[field] = nop;
}

export const logger = cond => (cond ? console : nopcon);
