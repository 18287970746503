import { createContext } from "react";
import { BehaviorSubject } from "rxjs";

export default createContext({
  focusedEntityKey: null,
  accept: () => {},
  reject: () => {},
  tooltipObserver: new BehaviorSubject(null),
  targetFontSize: 0,
});
