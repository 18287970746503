import React, { Component } from "react";
import entityStyles from "../../css/ptEntity.css";

class Underline extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
  }

  shouldComponentUpdate = nextProps => {
    if (
      (this.props.focusedEntityKey === this.props.entityKey) !==
      (nextProps.focusedEntityKey === nextProps.entityKey)
    ) {
      return true;
    }
    if (nextProps.focusedEntityKey !== this.props.focusedEntityKey) {
      return true;
    }
    if (nextProps.offsetKey !== this.props.offsetKey) {
      return true;
    }

    if (nextProps.targetFontSize !== this.props.targetFontSize) {
      return true;
    }
    if (nextProps.text !== this.props.text) {
      return true;
    }
    if (nextProps.correction !== this.props.correction) {
      return true;
    }
    return false;
  };

  componentWillUnmount = () => {
    const { focusedEntityKey, correction, underlineObserver } = this.props;
    if (correction && focusedEntityKey === correction.key) {
      underlineObserver.next(null);
    }
  };

  componentDidUpdate = prevProps => {
    const {
      focusedEntityKey,
      underlineObserver,
      sentenceId,
      sentenceIndex,
      correction,
    } = this.props;
    if (correction && focusedEntityKey === correction.key) {
      const node = this.node.current;
      const position = node.getBoundingClientRect();
      underlineObserver.next({
        entityKey: correction.key,
        position,
        sentenceId,
        sentenceIndex,
        correction,
      });
    } else if (
      underlineObserver.value &&
      prevProps.correction &&
      prevProps.correction.key === underlineObserver.value.entityKey
    ) {
      underlineObserver.next(null);
    }
  };

  onMouseEnter = () => {
    const {
      correction,
      underlineObserver,
      sentenceId,
      sentenceIndex,
    } = this.props;
    const node = this.node.current;
    const position = node.getBoundingClientRect();
    underlineObserver.next({
      position,
      sentenceId,
      sentenceIndex,
      correction,
    });
  };

  onMouseLeave = () => {
    this.props.underlineObserver.next(null);
  };

  render() {
    const { correction, text, targetFontSize, contentEditable } = this.props;
    if (!correction || !correction.signature) {
      return text;
    }
    const className = correction.signature.includes("suggestion")
      ? entityStyles["pt-suggestion"]
      : entityStyles["pt-correction"];
    const spanFontClassName =
      targetFontSize > 14 ? entityStyles["thick"] : entityStyles["thin"];
    const contentEditableClassName = contentEditable
      ? entityStyles["within-content-editable"]
      : entityStyles["within-textarea"];
    return (
      <span
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        className={`${
          entityStyles["pt-decorated"]
        } ${className} ${spanFontClassName} ${contentEditableClassName} pt-entity`}
        id={correction.key}
        ref={this.node}
      >
        {text}
      </span>
    );
  }
}

export default Underline;
