import getTargetStartEndOffset from "./getTargetStartEndOffset";
import applyRangeToDom from "./applyRangeToDom";
import { logger } from "./log";
import removeDivUpdates from "./removeDivUpdates";

let observableConsole = logger(false);
let cursorConsole = logger(false);

const removeUnderlineFragments = (removedDivUpdates, target, iframeEl) => {
  const oldDom = target.cloneNode(true);
  const contentWindow = iframeEl ? iframeEl.contentWindow : window;
  const selection = contentWindow.getSelection();
  if (selection.rangeCount === 0) {
    removeDivUpdates(removedDivUpdates, target);
    return;
  }
  const oldSelectionRange = selection.getRangeAt(0);
  // check if is from telerik paste by checking startContainer is a non-textNode and class name
  observableConsole.log({ oldSelectionRange });
  const {
    targetStartOffset,
    targetEndOffset,
    startAtNextNode,
    endAtNextNode,
  } = getTargetStartEndOffset(target, oldSelectionRange);
  cursorConsole.log({
    targetStartOffset,
    targetEndOffset,
    startAtNextNode,
    endAtNextNode,
  });

  removeDivUpdates(removedDivUpdates, target);

  applyRangeToDom(
    contentWindow,
    oldDom,
    target,
    targetStartOffset,
    targetEndOffset,
    oldSelectionRange,
    startAtNextNode,
    endAtNextNode,
  );
};

export default removeUnderlineFragments;
