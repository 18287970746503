import React from "react";
import cn from "classnames";
import ErrorBoundary from "./ErrorBoundary";
import Editor from "./editor";

const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

class Root extends React.Component {
  constructor(props) {
    super(props);
    const uid = getParameterByName("uid");
    this.divRef = React.createRef();
    this.state = {
      uid,
      targetOffsetTop: 0,
      targetOffsetLeft: 0,
      targetOffsetHeight: 0,
      targetOffsetWidth: 0,
      shadowDivOffsetHeight: 0,
      shadowDivOffsetWidth: 0,
      targetClientRectRight: 0,
      targetClientRectBottom: 0,
      iframeClientRectTop: 0,
      iframeClientRectLeft: 0,
    };
  }

  componentDidMount() {
    const {
      dom$,
      overlayPosition$,
      target: targetEl,
      contentEditable,
      iframeEl,
    } = this.props;
    dom$.subscribe(() => {
      overlayPosition$.next(targetEl);
    });
    if (!contentEditable) {
      overlayPosition$.subscribe(target => {
        if (!target) {
          return;
        }
        const { offsetLeft, offsetTop, offsetHeight, offsetWidth } = target;
        const {
          targetOffsetTop,
          targetOffsetLeft,
          targetOffsetHeight,
          shadowDivOffsetHeight,
          targetOffsetWidth,
          shadowDivOffsetWidth,
        } = this.state;
        if (
          offsetTop == targetOffsetTop &&
          offsetLeft == targetOffsetLeft &&
          targetOffsetHeight == offsetHeight &&
          shadowDivOffsetHeight == this.divRef.current.offsetHeight &&
          targetOffsetWidth == offsetWidth &&
          shadowDivOffsetWidth == this.divRef.current.offsetWidth
        ) {
          return;
        }
        this.setState({
          targetOffsetTop: offsetTop,
          targetOffsetLeft: offsetLeft,
          targetOffsetHeight: offsetHeight,
          targetOffsetWidth: offsetWidth,
          shadowDivOffsetHeight: this.divRef.current.offsetHeight,
          shadowDivOffsetWidth: this.divRef.current.offsetWidth,
        });
      });
    } else {
      // contentEditable
      overlayPosition$.subscribe(target => {
        if (!target) {
          return;
        }
        const { offsetLeft, offsetTop, offsetHeight, offsetWidth } = target;
        const { right, bottom } = target.getBoundingClientRect();
        const {
          targetClientRectRight,
          targetClientRectBottom,
          targetOffsetTop,
          targetOffsetLeft,
          targetOffsetHeight,
          targetOffsetWidth,
          iframeClientRectLeft,
          iframeClientRectTop,
        } = this.state;
        if (
          right == targetClientRectRight &&
          bottom == targetClientRectBottom &&
          offsetTop == targetOffsetTop &&
          offsetLeft == targetOffsetLeft &&
          targetOffsetHeight == offsetHeight &&
          targetOffsetWidth == offsetWidth &&
          (!iframeEl ||
            (iframeEl &&
              iframeEl.getBoundingClientRect().left == iframeClientRectLeft &&
              iframeEl.getBoundingClientRect().top == iframeClientRectTop))
        ) {
          return;
        }
        this.setState({
          targetClientRectRight: right,
          targetClientRectBottom: bottom,
          targetOffsetTop: offsetTop,
          targetOffsetLeft: offsetLeft,
          targetOffsetHeight: offsetHeight,
          targetOffsetWidth: offsetWidth,
          iframeClientRectLeft: iframeEl
            ? iframeEl.getBoundingClientRect().left
            : 0,
          iframeClientRectTop: iframeEl
            ? iframeEl.getBoundingClientRect().top
            : 0,
        });
      });
    }
  }

  render() {
    const { unobstrusive, contentEditable } = this.props;
    const { targetOffsetTop, targetOffsetLeft } = this.state;
    const rootContainerClasses = cn({ EditorContainer: !unobstrusive });
    const absoluteStyle = {
      position: "absolute",
      top: 0,
      left: 0,
      marginTop: targetOffsetTop,
      marginLeft: targetOffsetLeft,
    };
    let style = contentEditable ? {} : absoluteStyle;
    return (
      <div className={rootContainerClasses} style={style} ref={this.divRef}>
        <ErrorBoundary>
          <Editor {...this.props} {...this.state} shadowDiv={this.divRef} />
        </ErrorBoundary>
      </div>
    );
  }
}

export default Root;
