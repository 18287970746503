import { List, Map, Record } from "immutable";
import Delta from "quill-delta";
import Sentence from "./sentence";
import { logger } from "./log";
import {
  parseTransformationKey,
  parseCorrectionKey,
  sentenceKey,
  correctionKey,
} from "./keys";
import { DivUpdate } from "./divUpdate";

let textStateConsole = logger(false);
let errorConsole = logger(true);

const scoreForSentences = sentences => {
  const numSentences = sentences.count();
  return numSentences > 0
    ? sentences.reduce((acc, s) => acc + s.score, 0) / numSentences
    : 100;
};

export default class TextState extends Record(
  {
    sentences: List(),
    text: "DEFAULT_TEXT_STATE_TEXT",
    tokenIdMap: Map(), // token key -> token id
    tokenSentenceMap: Map(), // token id -> sentence id
    sentenceIdMap: Map(), // sentence key -> sentence id
    sentenceIndexMap: Map(), // sentence id -> sentence index
    jobMap: Map(), // jobKey -> Job
    score: 0,
    correctionMap: Map(), // correctionId -> sentence id
    divUpdates: List(),
  },
  "TextState",
) {
  constructor(args = {}) {
    let sentenceList = List();
    if (args.sentences) {
      sentenceList = args.sentences.reduce(
        ({ acc, offset, offsetWithoutNewline }, s) => ({
          acc:
            s.text.length > 0
              ? acc.push(
                s.merge({
                  offsetInTextState: offset,
                  offsetInTextStateWithoutNewline: offsetWithoutNewline,
                }),
              )
              : acc,
          offset: offset + s.text.length,
          offsetWithoutNewline:
            offsetWithoutNewline + s.text.replace(/\r?\n|\r/g, "").length,
        }),
        { acc: List(), offset: 0, offsetWithoutNewline: 0 },
      ).acc;
    }
    super({
      ...args, // fix token offsets:
      sentences: sentenceList,
      correctionMap: sentenceList.reduce((acc, s) => {
        for (const key of s.corrections.keys()) {
          acc = acc.set(key, s.id);
        }
        return acc;
      }, Map()),
      text: sentenceList.reduce((acc, s) => acc + s.text, ""),
      sentenceIndexMap: !sentenceList.isEmpty()
        ? Map(sentenceList.map((s, i) => [s.id, i]))
        : Map(),
      score: sentenceList.isEmpty()
        ? 100
        : sentenceList.reduce((acc, s) => acc + s.score, 0) /
          sentenceList.count(),
    });
  }

  *getAllCorrections() {
    for (const sentence of this.sentences) {
      for (const correction of sentence.corrections.values()) {
        const { sentenceId, sentenceIndex } = sentence;
        yield { correction, sentenceId, sentenceIndex };
      }
    }
  }

  getCorrection(cKey) {
    const sentenceId = this.correctionMap.get(cKey, 0);
    if (0 === sentenceId) {
      return null;
    }
    const sentenceIndex = this.sentenceIndexMap.get(sentenceId, -1);
    if (-1 === sentenceIndex) {
      return null;
    }
    const sentence = this.sentences.get(sentenceIndex);
    if (!sentence) {
      return null;
    }
    return sentence.corrections.get(cKey);
  }

  /**
   * Applies a Delta to the TextState, returning a new TextState
   * @this {TextState}
   * @param {Delta} delta
   * @param {string} timestamp
   * @return
   */
  applyDelta(delta, timestamp) {
    textStateConsole.log("apply delta", delta.ops);
    // TODO fix for multiple sentences ?
    const {
      sentences,
      tokenIdMap,
      tokenSentenceMap,
      sentenceIndexMap,
      sentenceIdMap,
      divUpdates,
    } = this;
    let curDelta = delta;
    let outputSentences = List();
    let outputTokenIdMap = tokenIdMap;
    let outputTokenSentenceMap = tokenSentenceMap;
    let outputSentenceIndexMap = sentenceIndexMap;
    let outputSentenceIdMap = sentenceIdMap;
    let outputCorrectionMap = this.correctionMap;
    let outputDivUpdates = divUpdates;

    if (sentences.isEmpty()) {
      // if no sentences in input state,
      // set output sentences structure directly
      // instead of going thru the loop in the else branch
      const {
        sentence,
        tokenSentenceMapDiff,
        sentenceDivUpdates,
      } = new Sentence({
        timestamp,
      }).applyDelta(delta, timestamp, 0);
      // TODO
      if (sentence && sentence.text.length > 0) {
        outputSentences = List.of(sentence);
        outputTokenSentenceMap = tokenSentenceMapDiff.filter(v => v !== 0);
        outputSentenceIndexMap = Map.of(sentence.id, 0);
        outputDivUpdates = sentenceDivUpdates;
      } else {
        outputTokenIdMap = Map();
        outputTokenSentenceMap = Map();
        outputSentenceIndexMap = Map();
        outputDivUpdates = List();
      }
    } else {
      // sentences not empty
      const inSentences = sentences;
      let index = 0;
      let sentOffset = 0;
      let sentOffsetWithoutNewline = 0;
      let tmpSentence = null;
      let sentenceWaitForMerge = false;
      let prevChanged = false;
      let curChanged = false;
      const inputSentenceCount = inSentences.count();
      textStateConsole.log("inSentences: ", inSentences.toJS());
      for (let i = 0; i < inputSentenceCount; ++i) {
        const curSentence = inSentences.get(i);
        textStateConsole.log(
          "textState applyDelta, curSentence:",
          curSentence.toJS(),
        );
        // pass if the sentence is last sentence to apply any remaining delta
        const {
          delta: newDelta,
          sentence: newSentence,
          tokenSentenceMapDiff,
          removedCorrections,
          divUpdates: sentenceDivUpdates,
        } = curSentence.applyDelta(
          curDelta,
          timestamp,
          sentOffset,
          sentOffsetWithoutNewline,
          i === inputSentenceCount - 1,
        );
        textStateConsole.log(
          "textState applyDelta after curSentence applyDelta",
          {
            newDelta,
            newSentence: newSentence ? newSentence.toJS() : null,
          },
        );

        outputCorrectionMap = outputCorrectionMap.deleteAll(removedCorrections);
        outputDivUpdates = outputDivUpdates.concat(sentenceDivUpdates);

        if (!newSentence || newSentence.text !== curSentence.text) {
          // if changed, mark prev and next sentences as changed
          if (!outputSentences.isEmpty()) {
            outputSentences = outputSentences.setIn([-1, "changed"], true);
          }
          curChanged = true;
        } else {
          curChanged = false;
        }
        if (newSentence) {
          if (newSentence.text.length === 0) {
            // empty sentence, update outputCorrectionMap, sentenceIdMap and sentenceIndexMap
            textStateConsole.log(
              "Deleted. textState applyDelta empty newSentence",
            );
            outputSentenceIndexMap = outputSentenceIndexMap.delete(
              newSentence.id,
            );
            outputSentenceIdMap = outputSentenceIdMap.filterNot(
              sentenceId => sentenceId === newSentence.id,
            );
          } else {
            textStateConsole.log("newSentence is NOT changed.");
            // current sentence is not changed
            if (sentenceWaitForMerge) {
              textStateConsole.log(
                "previous merged sentence wait for being pushed",
                tmpSentence.toJS(),
              );
              // there is previously changed sentences
              sentOffset += tmpSentence.text.length;
              sentOffsetWithoutNewline += tmpSentence.text.replace(
                /\r?\n|\r/g,
                "",
              ).length;
              textStateConsole.log({
                sentOffset,
                sentOffsetWithoutNewline,
              });
              outputSentences = outputSentences.push(tmpSentence);
              // only update sentenceIndexMap if index has changed
              if (index !== sentenceIndexMap.get(tmpSentence.id, -1)) {
                outputSentenceIndexMap = outputSentenceIndexMap.set(
                  tmpSentence.id,
                  index,
                );
              }
              index += 1;
              tmpSentence = null;
              sentenceWaitForMerge = false;
            }
            textStateConsole.log(
              "pushing current unchanged sentence",
              newSentence.toJS(),
            );
            // then push current unchanged sentence
            sentOffset += newSentence.text.length;
            sentOffsetWithoutNewline += newSentence.text.replace(
              /\r?\n|\r/g,
              "",
            ).length;
            textStateConsole.log({
              sentOffset,
              sentOffsetWithoutNewline,
            });
            outputSentences = outputSentences.push(newSentence);
            // only update sentenceIndexMap if index has changed
            if (index !== sentenceIndexMap.get(newSentence.id, -1)) {
              outputSentenceIndexMap = outputSentenceIndexMap.set(
                newSentence.id,
                index,
              );
            }
            index += 1;
            if (prevChanged) {
              outputSentences = outputSentences.setIn([-1, "changed"], true);
            }
          }
        } else {
          // sentence deleted, remove from sentenceIdMap and sentenceIndexMap
          textStateConsole.log("Deleted. textState applyDelta no newSentence");
          outputSentenceIndexMap = outputSentenceIndexMap.delete(
            curSentence.id,
          );
          outputSentenceIdMap = outputSentenceIdMap.filterNot(
            sentenceId => sentenceId === curSentence.id,
          );
        }
        curDelta = newDelta;
        prevChanged = curChanged;

        if (!curDelta || !curDelta.ops || curDelta.ops.length == 0) {
          textStateConsole.log("all Delta checked");
          // pushing any unpushed sentence
          if (sentenceWaitForMerge) {
            textStateConsole.log(
              "previous merged sentence wait for being pushed",
              tmpSentence.toJS(),
            );
            // there is previously changed sentences
            sentOffset += tmpSentence.text.length;
            sentOffsetWithoutNewline += tmpSentence.text.replace(
              /\r?\n|\r/g,
              "",
            ).length;
            textStateConsole.log({
              sentOffset,
              sentOffsetWithoutNewline,
            });
            outputSentences = outputSentences.push(tmpSentence);
            // only update sentenceIndexMap if index has changed
            if (index !== sentenceIndexMap.get(tmpSentence.id, -1)) {
              outputSentenceIndexMap = outputSentenceIndexMap.set(
                tmpSentence.id,
                index,
              );
            }
            index += 1;
            tmpSentence = null;
            sentenceWaitForMerge = false;
          }
          textStateConsole.log(
            "textState applyDelta before applying map diff",
            outputTokenIdMap.toJS(),
            outputTokenSentenceMap.toJS(),
          );
          for (const [tokenId, sentenceId] of tokenSentenceMapDiff.entries()) {
            if (sentenceId) {
              outputTokenSentenceMap = outputTokenSentenceMap.set(
                tokenId,
                sentenceId,
              );
            } else {
              outputTokenSentenceMap = outputTokenSentenceMap.delete(tokenId);
              outputTokenIdMap = outputTokenIdMap.filterNot(
                tId => tId === tokenId,
              );
            }
          }
          textStateConsole.log("textState applyDelta, all Delta checked", {
            tokenSentenceMapDiff: tokenSentenceMapDiff.toJS(),
            outputTokenIdMap: outputTokenIdMap.toJS(),
            outputTokenSentenceMap: outputTokenSentenceMap.toJS(),
          });
        } // if delta empty
      } // for
    } // else sentence not empty

    const outputScore = scoreForSentences(outputSentences);

    const outputTextState = new TextState({
      ...this.toObject(),
      sentences: outputSentences,
      text: outputSentences.reduce((acc, { text }) => acc + text, ""),
      tokenIdMap: outputTokenIdMap,
      tokenSentenceMap: outputTokenSentenceMap,
      sentenceIndexMap: outputSentenceIndexMap,
      sentenceIdMap: outputSentenceIdMap,
      score: outputScore,
      divUpdates: outputDivUpdates,
    });
    textStateConsole.log(
      "TextState.applyDelta returning: ",
      outputTextState.toJS(),
    );
    return outputTextState;
  } // applyDelta()

  applyResponses(responses, timestamp) {
    return responses.reduce(
      (acc, response) => acc.applyResponse(response, timestamp),
      this,
    );
  }

  /**
   * Applies a response from the server to the text state.
   * Delegates to separate methods for each type of response.
   * @this {TextState}
   * @param {object} response the response object
   * @param timestamp timestamp of the response
   * @return {TextState}
   */
  applyResponse(response, timestamp) {
    textStateConsole.log("current response", response);
    const { key } = response;
    const job = this.jobMap.get(key);
    if (job) {
      textStateConsole.log({ job: job.toJS() });
      if (response.tokenized) {
        textStateConsole.log("applying tokenized response");
        const newState = this.applyTokenizedResponse(response, timestamp);
        textStateConsole.log("newState", newState.toJS());
        const storedResponses = job.storedResponses;
        if (storedResponses.isEmpty()) {
          return newState;
        } else {
          textStateConsole.log("applying stored responses");
          // if there are stored corrections/unchanged responses for this job, apply them now
          return newState
            .updateIn(["jobMap", key, "storedResponses"], () => List())
            .applyResponses(storedResponses, timestamp);
        }
      } else if (response.corrections) {
        textStateConsole.log(
          "job tokenized response is:",
          job.tokenizedResponse,
        );
        if (job.tokenizedResponse) {
          textStateConsole.log("applying corrections response");
          // if tokenized response has been received, apply the corrections response
          return this.applyCorrectionsResponse(response, timestamp);
        } else {
          textStateConsole.log("storing corrections response");
          // if tokenized response has not yet been received, store the corrections response
          return this.updateIn(["jobMap", key, "storedResponses"], m =>
            m.push(response),
          );
        }
      } else {
        if (job.tokenizedResponse) {
          textStateConsole.log("applying unchanged response");
          // if tokenized response has been received, apply the unchanged response
          return this.applyUnchangedResponse(response, timestamp);
        } else {
          textStateConsole.log("storing unchanged response");
          // if tokenized response has not yet been received, store the unchanged response
          return this.updateIn(["jobMap", key, "storedResponses"], m =>
            m.push(response),
          );
        }
      }
    } else {
      // job stale/not found
      textStateConsole.log(`No job found for key ${key}`);
      return this;
    }
  } // applyResponse()

  /**
   * Applies a tokenized response to the text state.
   * Returns a new TextState object resulting from applying the response,
   * with the new data added to the token and sentence maps,
   * and with the relevant Sentences tokenized.
   * @this {TextState}
   * @param {object} response a tokenized response object received from the server
   * @param {string} timestamp
   * @return {TextState}
   */
  applyTokenizedResponse(response, timestamp) {
    textStateConsole.log("apply tokenized response", response);
    const { tokenized, key } = response;
    const job = this.jobMap.get(key);
    const { changedSentences, timestamp: jobTimestamp } = job;
    const numJobSentences = changedSentences.count();
    // Sentence Map has been altered such that
    // some sentences are now not
    const staleSentences = changedSentences.some(
      s => this.sentenceIndexMap.get(s, -1) === -1,
    );
    if (staleSentences) {
      return this;
    }
    const jobSentenceIndices = changedSentences.map(s =>
      this.sentenceIndexMap.get(s),
    );
    textStateConsole.log(
      "changed sentences indices are:",
      jobSentenceIndices.toJS(),
    );
    let jobSentences = jobSentenceIndices.map(i => this.sentences.get(i));
    textStateConsole.log({
      jobSentences: jobSentences.toJS(),
      job: job.toJS(),
      originalTextstate: this.toJS(),
    });
    const staleTimestamp = jobSentences.some(
      s => Number(s.timestamp) > Number(jobTimestamp),
    );
    if (staleTimestamp) {
      return this;
    }

    const startSentenceIndex = jobSentenceIndices.first();
    let newSentences = List();
    let updatedSentenceIndexMap = this.sentenceIndexMap;
    let updatedSentenceIdMap = this.sentenceIdMap;
    let newSentenceIds = List();
    const theSentence = jobSentences.reduce(
      (acc, jobSentence) => jobSentence.mergeWithExistingSentence(acc),
      null,
    );
    textStateConsole.log({ theSentence: theSentence.toJS() });
    const theSentenceText = theSentence.text;
    const theSentenceLength = theSentenceText.length;
    let sentenceIndex = startSentenceIndex;
    // for each new sentence range from tokenized response
    for (let i = 0; i < tokenized.length; ++i) {
      const startOffset = tokenized[i];
      const startOffsetWithoutNewline = theSentenceText
        .slice(0, tokenized[i])
        .replace(/\r?\n|\r/g, "").length;
      const endOffset =
        i + 1 < tokenized.length ? tokenized[i + 1] : theSentenceLength; // why we used this.text.length instead of sentence length?
      const curTokenizedSentenceLength = endOffset - startOffset;
      const postLength =
        theSentenceLength - (startOffset + curTokenizedSentenceLength); // <-- This was wrong

      textStateConsole.log("building delta in tokenized response", {
        startOffset,
        endOffset,
        curTokenizedSentenceLength,
        postLength,
        i,
      });
      const delta = new Delta()
        .delete(startOffset) // skip til start
        .retain(curTokenizedSentenceLength) // retain tokenized section
        .delete(postLength); // skip til end
      // apply delta to select new sentence tokens

      // TODO do something less hacky here?
      const { sentence: outputSentence } = theSentence.applyDelta(
        delta,
        timestamp,
        startOffset + theSentence.offsetInTextState,
        startOffsetWithoutNewline + theSentence.offsetInTextStateWithoutNewline,
      ); // << TODO this seems wrong
      if (outputSentence) {
        // outputSentence can be null
        const {
          tokens,
          text,
          corrections,
          tokenIndexMap,
          offsetInTextState,
          offsetInTextStateWithoutNewline,
          score,
        } = outputSentence;
        const newSentence = new Sentence({
          tokens,
          text,
          corrections,
          tokenIndexMap,
          offsetInTextState,
          offsetInTextStateWithoutNewline,
          score,
          timestamp,
        });
        newSentences = newSentences.push(newSentence);
        updatedSentenceIndexMap = updatedSentenceIndexMap.set(
          newSentence.id,
          sentenceIndex,
        );
        updatedSentenceIdMap = updatedSentenceIdMap.set(
          sentenceKey({
            jobKey: key,
            sentenceIndex,
          }),
          newSentence.id,
        );
        newSentenceIds = newSentenceIds.push(newSentence.id);
        sentenceIndex += 1;
      }
    } // for

    const lastNewSentenceIndex = startSentenceIndex + newSentences.count() - 1;

    let splicedSentences = this.sentences.splice(
      startSentenceIndex,
      numJobSentences,
      ...newSentences,
    );
    for (
      let i = startSentenceIndex + newSentences.count();
      i < splicedSentences.count();
      ++i
    ) {
      updatedSentenceIndexMap = updatedSentenceIndexMap.set(
        splicedSentences.get(i).id,
        i,
      );
    }
    let newTextState = this.merge({
      sentences: splicedSentences,
      sentenceIndexMap: updatedSentenceIndexMap,
      sentenceIdMap: updatedSentenceIdMap,
      jobMap: this.jobMap.mergeIn([key], {
        tokenizedResponse: response,
        tokenizedTimestamp: timestamp,
      }),
    });
    newTextState = newTextState.updateMap();

    newTextState = newTextState.setIn(
      ["jobMap", job.key, "tokenizedSentenceIds"],
      newSentenceIds,
    );

    if (job.longText) {
      // if job was originally too long, set things up to ignore response for last sentence and send it again
      textStateConsole.log("long text");
      textStateConsole.log({
        newTextState: newTextState.toJS(),
        lastNewSentenceIndex,
      });
      newTextState = newTextState
        .updateIn(["sentences", lastNewSentenceIndex], s =>
          s.merge({
            changed: true,
            timestamp: Date.now(),
          }),
        )
        .setIn(
          // put a value in sentenceResponses for the last sentence so no corrections/unchanged response will be applied to it
          ["jobMap", job.key, "sentenceResponses", tokenized.length - 1],
          "ignore response",
        );
    }
    //newTextState = this.updateSentenceIdMap(newTextState);
    return new TextState({ ...newTextState.toObject() });
  } // applyTokenizedResponse()

  /**
   * update necessary map with updated sentences
   * @param {TextState} newTextState
   * @return {TextState}
   */
  updateMap() {
    //TODO: in commit e4bd1acd I convert id toString to make it work, but seems it's not the case anymore. why?
    const newTextState = this;
    const newSentenceIndexMap = newTextState.sentences.reduce(
      (newMap, sentence, index) => newMap.set(sentence.id, index),
      Map(),
    );
    const newSentenceKeys = newTextState.sentenceIdMap.reduce(
      (acc, sentenceId, sentenceKey) =>
        newSentenceIndexMap.has(sentenceId) ? acc.push(sentenceKey) : acc,
      List(),
    );
    const newCorrectionMap = newTextState.correctionMap.filter(
      (_correction, correctionKey) =>
        newSentenceKeys.includes(
          sentenceKey(parseCorrectionKey(correctionKey)),
        ),
    );
    const newTokenSentenceMap = newTextState.tokenSentenceMap.filter(
      sentenceId => newSentenceIndexMap.has(sentenceId),
    );
    const newTokenIdMap = newTextState.tokenIdMap.filter(tokenId =>
      newTokenSentenceMap.has(tokenId),
    );
    return newTextState.merge({
      sentenceIndexMap: newSentenceIndexMap,
      tokenSentenceMap: newTokenSentenceMap,
      tokenIdMap: newTokenIdMap,
      correctionMap: newCorrectionMap,
    });
  }

  /**
   * update self sentenceIdMap by removing anything whose sentence ID not exist anymore
   * looks like we are using old sentenceId in applyCorrectionsResponse
   * cannot delete right now, but when should we delete
   * @param {TextState} newTextState
   * @return {TextState}
   */
  updateSentenceIdMap(newTextState) {
    const newSentenceIdMap = newTextState.sentenceIdMap.filter(sentenceId =>
      newTextState.sentenceIndexMap.has(sentenceId),
    );
    return newTextState.merge({ sentenceIdMap: newSentenceIdMap });
  }

  /**
   * Applies a corrections response object to the TextState.
   * Returns a new TextState object resulting from applying it,
   * with corrections and scores updated on the relevant Sentences,
   * and with the new token keys from the response added to tokenIdMap
   *
   * @this {TextState}
   * @param {Object} response the corrections response object to apply
   * @return {TextState}
   */
  applyCorrectionsResponse(response, timestamp) {
    textStateConsole.log("applyCorrectionsResponse", response);
    const { key } = response; // <- this sentenceIndex here before was wrong.
    // ^ if dirty sentence is index 1, and only one sentence is touched, the sentenceIndex in returned response will be 0
    let { sentenceIndex: jobSentenceIndex } = response;
    const job = this.jobMap.get(key);
    const sentenceId = job.tokenizedSentenceIds.get(jobSentenceIndex);
    textStateConsole.log("correction", {
      jobSentenceIndex,
      responseText: response.currentTokens.reduce(
        (acc, token) => acc + token.value + token.after,
        "",
      ),
      jobId: job.id,
    });
    if (job.sentenceResponses.has(jobSentenceIndex)) {
      textStateConsole.log(
        "======job.sentenceResponses has jobSentenceIndex======",
      );
      //TODO: Do we need this check?
      return this;
    }

    let outputTokenIdMap = this.tokenIdMap;
    let outputTokenSentenceMap = this.tokenSentenceMap;
    let outputCorrectionMap = this.correctionMap;
    let outputDivUpdates = this.divUpdates;
    textStateConsole.log(outputDivUpdates.toJS());
    const sentenceIndex = this.sentenceIndexMap.get(sentenceId, -1);
    if (-1 === sentenceIndex) {
      // sentence not in sentence map
      errorConsole.log(
        "!!!!!!!!!!!!ERROR: sentenceIndex is -1, sentence not in sentence map",
      );
      return this;
    }
    const oldSentence = this.sentences.get(sentenceIndex);
    if (!oldSentence) {
      // sentence not in sentence list
      errorConsole.log(
        "!!!!!!!!!!!!ERROR: no oldSentence, sentence not in sentence list",
      );
      return this;
    }

    if (Number(oldSentence.timestamp) > Number(job.tokenizedTimestamp)) {
      textStateConsole.log(
        "sentence modified since tokenized received; ignoring corrections",
      );
      return this.merge({
        jobMap: this.jobMap.updateIn([key, "sentenceResponses"], sr =>
          sr.set(jobSentenceIndex, response),
        ),
      });
    }

    // add corrections to sentence
    const {
      updatedSentence,
      tokenIdMapDiff,
      tokenSentenceMapDiff,
      correctionMapDiff,
      divUpdates: sentenceDivUpdates,
    } = oldSentence.applyCorrections(
      response,
      timestamp,
      job,
      this.tokenIdMap,
      jobSentenceIndex,
    );
    textStateConsole.log(sentenceDivUpdates.toJS());
    outputDivUpdates = outputDivUpdates.concat(sentenceDivUpdates);

    textStateConsole.log(
      "after oldSentence.applyCorrections before updating map diff",
      outputTokenIdMap.toJS(),
      outputTokenSentenceMap.toJS(),
      outputCorrectionMap.toJS(),
    );
    for (const [k, v] of tokenIdMapDiff.entries()) {
      if (v) {
        outputTokenIdMap = outputTokenIdMap.set(k, v);
        outputTokenSentenceMap = outputTokenSentenceMap.set(v, sentenceId);
      } else {
        const id = outputTokenIdMap.get(k, 0);
        outputTokenIdMap = outputTokenIdMap.delete(k);
        outputTokenSentenceMap = outputTokenSentenceMap.delete(id);
      }
    }

    for (const [k, v] of tokenSentenceMapDiff.entries()) {
      outputTokenSentenceMap = v
        ? outputTokenSentenceMap.set(k, v)
        : outputTokenSentenceMap.delete(k);
    }

    for (const [k, v] of correctionMapDiff.entries()) {
      outputCorrectionMap = outputCorrectionMap.set(k, v);
    }

    // for each correction, update sentence.tokens
    return this.merge({
      sentences: this.sentences.set(sentenceIndex, updatedSentence),
      tokenIdMap: outputTokenIdMap,
      tokenSentenceMap: outputTokenSentenceMap,
      correctionMap: outputCorrectionMap,
      score:
        this.score +
        (updatedSentence.score - oldSentence.score) / this.sentences.count(),
      jobMap: this.jobMap.updateIn([key, "sentenceResponses"], sr =>
        sr.set(jobSentenceIndex, response),
      ),
      divUpdates: outputDivUpdates,
    });
  } // applyCorrectionsResponse()

  /**
   * Applies an unchangedresponse object to the TextState.
   * Returns a new TextState object with the score updated.
   *
   * @this {TextState}
   * @param {Object} response
   * @returns {TextState}
   */
  applyUnchangedResponse(response, timestamp) {
    const { key, sentenceIndex: jobSentenceIndex } = response;
    const job = this.jobMap.get(key);
    const sentenceId = job.tokenizedSentenceIds.get(jobSentenceIndex);
    textStateConsole.log("unchanged", {
      responseSentenceIndex: jobSentenceIndex,
      sentenceId,
      jobId: job.id,
    });

    // TODO: should this be index from job or actual index
    if (job.sentenceResponses.has(jobSentenceIndex)) {
      return this;
    }

    const sentenceIndex = this.sentenceIndexMap.get(sentenceId);
    const oldSentence = this.sentences.get(sentenceIndex);

    if (!oldSentence) {
      errorConsole.log(
        "====oldSentence does not exist in unchanged response===",
      );
      return this;
    }

    const newSentence =
      Number(oldSentence.timestamp) <= Number(timestamp)
        ? oldSentence.applyUnchangeResponse(timestamp)
        : oldSentence;

    const newSentences = this.sentences.set(sentenceIndex, newSentence);

    const divUpdates = oldSentence
      .getRanges()
      .filter(r => !!r.correction)
      .map(
        ({ text, correction }) =>
          new DivUpdate({
            key: correction,
            correction: oldSentence.corrections.get(correction),
            removeUnderline: true,
            startOffset:
              oldSentence.offsetInTextState +
              oldSentence.tokens.find(t => t.correction === correction)
                .offsetInSentence,
            oldText: text,
            newText: text,
          }),
      );

    return this.merge({
      sentences: newSentences,
      score: scoreForSentences(newSentences),
      jobMap: this.jobMap.updateIn([key, "sentenceResponses"], sr =>
        sr.set(jobSentenceIndex, response),
      ),
      divUpdates: this.divUpdates.concat(divUpdates),
    });
  } // applyUnchangedResponse()

  /**
   * Accepts a transformation.
   * Updates the Sentence, replacing the tokens and updating score.
   * Updates score and removes no longer needed key mappings.
   * Returns a new TextState object with the result.
   *
   * @this {TextState}
   * @param {string} tKey transformation key for transformation to accept
   * @param {string} timestamp
   * @returns {TextState}
   */
  accept(tKey, timestamp, propSentenceIndex = -1) {
    textStateConsole.log({ tKey });
    const args = parseTransformationKey(tKey);
    const { jobKey, sentenceIndex: jobSentenceIndex, correctionIndex } = args;
    const cKey = correctionKey({
      jobKey,
      sentenceIndex: jobSentenceIndex,
      correctionIndex,
    });
    const job = this.jobMap.get(jobKey);
    if (!job) {
      // TODO
    }

    let sentenceId = this.correctionMap.get(cKey);
    if (!sentenceId) {
      sentenceId = this.sentenceIdMap.get(sentenceKey(args));
    }
    let sentenceIndex = this.sentenceIndexMap.get(sentenceId, -1);
    if (sentenceIndex === -1) {
      if (propSentenceIndex !== -1) {
        sentenceIndex = propSentenceIndex;
      } else {
        sentenceIndex = this.sentences.findIndex(s => s.corrections.has(cKey));
      }
    }
    // TODO check if still no sentenceIndex found
    const oldSentence = this.sentences.get(sentenceIndex);
    const {
      updatedSentence,
      tokenIdMapDiff,
      tokenSentenceMapDiff,
      divUpdate,
    } = oldSentence.accept(tKey, this.tokenIdMap, timestamp);
    const offsetDiff =
      updatedSentence.offsetInTextState +
      updatedSentence.text.length -
      (oldSentence.offsetInTextState + oldSentence.text.length);
    const offsetDiffWithoutNewline =
      updatedSentence.offsetInTextStateWithoutNewline +
      updatedSentence.text.replace(/\r?\n|\r/g, "").length -
      (oldSentence.offsetInTextStateWithoutNewline +
        oldSentence.text.replace(/\r?\n|\r/g, "").length);
    const updatedSentences = this.sentences
      .set(sentenceIndex, updatedSentence)
      .map((s, i) => {
        if (i > sentenceIndex) {
          return s
            .update("offsetInTextState", offset => offset + offsetDiff)
            .update(
              "offsetInTextStateWithoutNewline",
              offsetWithoutNewline =>
                offsetWithoutNewline + offsetDiffWithoutNewline,
            );
        } else {
          return s;
        }
      });

    const updatedTokenIdMap = tokenIdMapDiff.reduce(
      (tokenIdMap, id, key) =>
        id ? tokenIdMap.set(key, id) : tokenIdMap.delete(key),
      this.tokenIdMap,
    );
    const updatedTokenSentenceMap = tokenSentenceMapDiff.reduce(
      (tokenSentenceMap, sentenceId, tokenId) =>
        sentenceId
          ? tokenSentenceMap.set(tokenId, sentenceId)
          : tokenSentenceMap.delete(tokenId),
      this.tokenSentenceMap,
    );

    return this.merge({
      text: updatedSentences.reduce((acc, s) => acc + s.text, ""),
      sentences: updatedSentences,
      tokenIdMap: updatedTokenIdMap,
      tokenSentenceMap: updatedTokenSentenceMap,
      score:
        this.score +
        (updatedSentence.score - oldSentence.score) / this.sentences.count(),
      correctionMap: this.correctionMap.delete(cKey),
      divUpdates: divUpdate ? List.of(divUpdate) : List(),
    });
  } // accept()

  /**
   * Ignores a correction.
   * Removes no-longer-needed key mappings related to the ignored correction.
   * Updates the score.
   * Returns a new TextState object with the result.
   *
   * @this {TextState}
   * @param {string} cKey the correction key of the correction to ignore
   * @param {string} timestamp
   * @returns {TextState}
   */
  ignore(cKey, timestamp, propSentenceIndex = -1) {
    const args = parseCorrectionKey(cKey);
    let sentenceId = this.correctionMap.get(cKey);
    if (!sentenceId) {
      sentenceId = this.sentenceIdMap.get(sentenceKey(args));
    }
    let sentenceIndex = this.sentenceIndexMap.get(sentenceId, -1);
    if (sentenceIndex === -1) {
      if (propSentenceIndex !== -1) {
        sentenceIndex = propSentenceIndex;
      } else {
        sentenceIndex = this.sentences.findIndex(s => s.corrections.has(cKey));
      }
    }
    const oldSentence = this.sentences.get(sentenceIndex);
    const updatedSentence = oldSentence.ignore(cKey, timestamp);

    const underlinedTokens = oldSentence.tokens.filter(
      t => t.correction === cKey,
    );
    const underlinedText = underlinedTokens.reduce(
      (acc, t, index) =>
        index === underlinedTokens.size - 1 ? acc + t.value : acc + t.text,
      "",
    );
    const lastTokenAfter = underlinedTokens.last().after;
    return this.merge({
      sentences: this.sentences.set(sentenceIndex, updatedSentence),
      score:
        this.score +
        (updatedSentence.score - oldSentence.score) / this.sentences.count(),
      correctionMap: this.correctionMap.delete(cKey),
      divUpdates: List.of(
        new DivUpdate({
          key: cKey,
          correction: oldSentence.corrections.get(cKey),
          removeUnderline: true,
          startOffset:
            oldSentence.offsetInTextState +
            oldSentence.tokens.find(t => t.correction === cKey)
              .offsetInSentence,
          oldText: underlinedText,
          newText: underlinedText + lastTokenAfter,
          oldAfter: lastTokenAfter,
        }),
      ),
    });
  } // ignore()
}
