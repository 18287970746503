import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "css.escape";

import React from "react";
import ReactDOM from "react-dom";
import Root from "./components";
import { BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import ResizeObserver from "resize-observer-polyfill";
import { initializeTextarea } from "./utils/initializeEditor";

const defaultSocketUrl = "https://io.perfecttense.com";
const defaultApiEnv = "production";

function getRealTarget(target) {
  // get real target
  let realTarget = null;
  let iframeEl = null;
  if (target.contentEditable === "true") {
    realTarget = target;
  } else {
    if (target.querySelector("[contenteditable='true']")) {
      realTarget = target.querySelector("[contenteditable='true']");
    } else {
      // check iframe
      // TODO: search from target or document?
      const iframes = target.querySelectorAll("iframe");
      for (let i = 0; i < iframes.length; i++) {
        iframeEl = iframes[i];
        const innerDoc = iframeEl.contentDocument
          ? iframeEl.contentDocument
          : iframeEl.contentWindow.document;
        if (innerDoc && innerDoc.querySelector("[contenteditable='true']")) {
          realTarget = innerDoc.querySelector("[contenteditable='true']");
          break;
        }
      } // for
    } // else
  } // else
  if (!realTarget) {
    //console.log("No target Found!!!");
  }
  return { realTarget, originalTarget: target, iframeEl };
}

// TODO also remove grammarly tags
function getHtmlForClipboard(contentWindow) {
  const selection = contentWindow.getSelection();
  if (selection.rangeCount === 0) {
    return "";
  }
  const range = selection.getRangeAt(0);
  //console.log(range);
  const contents = range.cloneContents();
  //console.log(Array.from(contents.childNodes));
  const container = contentWindow.document.createElement("div");
  container.appendChild(contents);
  const underlineSpans = Array.from(
    container.querySelectorAll("span.pt-entity"),
  );
  for (const underlineSpan of underlineSpans) {
    const parent = underlineSpan.parentNode;
    const innerSpan = underlineSpan.childNodes[0];
    const children = Array.from(innerSpan.childNodes);
    while (children.length > 0) {
      parent.insertBefore(children.pop(), underlineSpan);
    }
    parent.removeChild(underlineSpan);
    parent.normalize();
  }
  //console.log(container);
  return { copiedHTML: container.innerHTML, copiedText: container.innerText };
}

function createEditorForRealTarget(
  elementNumber,
  creds,
  originalTarget,
  iframeEl,
  overlayPositionObservers,
  dom$,
) {
  const realTarget = overlayPositionObservers[elementNumber]["element"];
  const el = document.createElement("div");
  el.setAttribute("class", "perfecttense-div");
  el.setAttribute("style", "display: inline;");
  originalTarget.parentElement.insertBefore(el, originalTarget);
  realTarget.dataset.ptEditor = true;
  realTarget.dataset.gramm_editor = false;
  realTarget.setAttribute("spellcheck", false);
  const targetElementId = realTarget.id || elementNumber;

  const contentWindow = iframeEl ? iframeEl.contentWindow : window;

  // TODO also do this for the shadow div in the textarea case (in case something weird happens)
  realTarget.addEventListener("copy", e => {
    const { copiedHTML, copiedText } = getHtmlForClipboard(contentWindow);
    //console.log(copiedHTML);
    e.clipboardData.setData("text/html", copiedHTML);
    e.clipboardData.setData("text/plain", copiedText);
    e.preventDefault();
  });
  realTarget.addEventListener("cut", e => {
    const { copiedHTML, copiedText } = getHtmlForClipboard(contentWindow);
    e.clipboardData.setData("text/html", copiedHTML);
    e.clipboardData.setData("text/plain", copiedText);
    contentWindow.getSelection().deleteFromDocument();
    e.preventDefault();
  });

  let input$ = new BehaviorSubject({}); // not adding input delta right now
  const telerikRemoveDivUpdate$ = new BehaviorSubject();

  // for telerik paste
  const mutationIncludeRemoveTelerikPasteDiv = mutations => {
    if (mutations.length > 0) {
      return mutations.some(mutation =>
        Array.from(mutation.removedNodes).some(
          node =>
            node.className && node.className.includes("k-paste-container"),
        ),
      );
    }
    return false;
  };
  const targetMutationObserver = new MutationObserver(mutations => {
    if (mutationIncludeRemoveTelerikPasteDiv(mutations)) {
      telerikRemoveDivUpdate$.next("remove");
    }
    input$.next({ realTarget, iframeEl });
  });
  targetMutationObserver.observe(realTarget, {
    characterData: true,
    childList: true,
    subtree: true,
  });

  input$.next({ realTarget, iframeEl });
  const underlineObserver = new BehaviorSubject(null);
  const tooltipObserver = underlineObserver.pipe(
    distinctUntilChanged(),
    debounceTime(500),
  );
  const overlayPosition$ = overlayPositionObservers[elementNumber]["observer"];
  const mutationObserver = new MutationObserver(function() {
    for (let arrayLikeObj of Object.entries(overlayPositionObservers)) {
      arrayLikeObj[1]["observer"].next(arrayLikeObj[1]["element"]);
    } //fix the bug on a multi-editor page, resizing one, others will not know offset changed
  });
  mutationObserver.observe(realTarget, {
    attributes: true,
    attributeFilter: ["style"],
  });
  window.addEventListener("resize", () => {
    overlayPosition$.next(realTarget);
  });
  window.addEventListener("scroll", () => {
    setTimeout(() => {
      overlayPosition$.next(realTarget);
    }, 100);
  });
  // inject css
  if (iframeEl) {
    const innerDoc = iframeEl.contentDocument
      ? iframeEl.contentDocument
      : iframeEl.contentWindow.document;
    let styleTag = null;
    for (let styleSheet of document.styleSheets) {
      try {
        if (
          styleSheet.cssRules[0].selectorText.includes(
            "span.ptEntity_pt-decorated",
          )
        ) {
          styleTag = styleSheet.ownerNode.cloneNode(true);
          break;
        }
      } catch (err) {
        // TODO conditional logging macros for non-prod env
        // eslint-disable-next-line
        //console.error(err);
      }
    }
    innerDoc.head.appendChild(styleTag);
  }
  // TODO style protection?
  ReactDOM.render(
    <Root
      contentEditable={true}
      unobstrusive={true}
      targetElementId={targetElementId}
      input$={input$}
      underlineObserver={underlineObserver}
      tooltipObserver={tooltipObserver}
      target={realTarget}
      clientId={creds.clientId || ""}
      clientSecret={creds.clientSecret || ""}
      extension={creds.extension || false}
      socketUrl={creds.socketUrl ? creds.socketUrl : defaultSocketUrl}
      sandbox={creds.sandbox ? creds.sandbox : false}
      apiEnv={creds.apiEnv ? creds.apiEnv : defaultApiEnv}
      onGrammarScoreChanged={creds.onGrammarScoreChanged}
      showTextState={creds.showTextState}
      iframeEl={iframeEl}
      overlayPosition$={overlayPosition$}
      dom$={dom$}
      telerikRemoveDivUpdate$={telerikRemoveDivUpdate$}
    />,
    el,
  );
}

function* getPerfecttenseElements(doc) {
  yield* doc.getElementsByClassName("perfecttense");
  for (const iframe of doc.getElementsByTagName("iframe")) {
    if (iframe.contentDocument) {
      yield* getPerfecttenseElements(iframe.contentDocument);
    }
  }
}

const PerfectTenseEditor = (creds = {}) => {
  // listen to any resize event on whole dom in case textarea resize/reposition
  const domResizes$ = new BehaviorSubject(null);
  const domResizeObserver = new ResizeObserver(function() {
    domResizes$.next("");
  });

  const dom$ = domResizes$.pipe(debounceTime(600));
  domResizeObserver.observe(document.body);
  // if (!(isFirefox() || isChrome())) {
  //   console.info(
  //     "Perfect Tense currently supports Chrome and Firefox. Please go to https://www.perfecttense.com to request support for your browser.",
  //   );
  //   return;
  // }
  let elementNumber = 0;
  let overlayPositionObservers = {};
  const perfecttenseElements = Array.from(getPerfecttenseElements(document)); //document.getElementsByClassName("perfecttense");
  for (let target of perfecttenseElements) {
    overlayPositionObservers[elementNumber] = {
      observer: new BehaviorSubject(null).pipe(debounceTime(200)),
      element: target,
    };
    elementNumber++;
  }
  elementNumber = 0;
  for (let target of perfecttenseElements) {
    if (target.nodeName === "TEXTAREA") {
      const el = document.createElement("div");
      const comp = initializeTextarea(
        target,
        target.id || elementNumber,
        overlayPositionObservers,
        dom$,
        creds,
        el,
      );
      el.setAttribute("class", "perfecttense-div");
      el.setAttribute("style", "display: inline;");
      target.parentElement.insertBefore(el, target);
      ReactDOM.render(comp, el);
    } else {
      const { realTarget, originalTarget, iframeEl } = getRealTarget(target);
      if (!realTarget) {
        const currentElementNum = elementNumber;
        const mutationObserver = new MutationObserver(
          (_mutations, observer) => {
            const { realTarget: rt, originalTarget, iframeEl } = getRealTarget(
              target,
            );
            if (rt) {
              overlayPositionObservers[currentElementNum]["element"] = rt;
              createEditorForRealTarget(
                currentElementNum,
                creds,
                originalTarget,
                iframeEl,
                overlayPositionObservers,
                dom$,
              );
              observer.disconnect();
            }
          },
        );
        mutationObserver.observe(target, {
          attributeFilter: ["contentEditable"],
          childList: true,
          subtree: true,
          attributes: true,
        });
      } else {
        overlayPositionObservers[elementNumber]["element"] = realTarget;
        createEditorForRealTarget(
          elementNumber,
          creds,
          originalTarget,
          iframeEl,
          overlayPositionObservers,
          dom$,
        );
      }
    } // end else
    elementNumber++;
  } // for
};

export default PerfectTenseEditor;
