import React from "react";
import ReactDOM from "react-dom";
import UnderlineFragment from "../components/editor/underlineFragment";
import { addManualSeparatorOffset } from "./dom";
import correctionInCurrentNode from "./correctionInCurrentNode";

const addDivUpdates = (
  editor,
  sortedDivUpdates,
  parentNode,
  currentNode,
  offset,
) => {
  // should we skip "pre", "code", "blockquote" tag?
  if (sortedDivUpdates.size === 0) {
    return { offset: offset, updatedSortedDivUpdates: sortedDivUpdates };
  }
  editor.observableConsole.log("addDivUpdates", {
    sortedDivUpdates: sortedDivUpdates.toJS(),
    parentNode,
    currentNodeName: currentNode.nodeName,
    currentNodeText: currentNode.textContent,
    offset,
  });
  const currentDocument = currentNode.ownerDocument;
  let updatedSortedDivUpdates = sortedDivUpdates;
  if (currentNode.nodeName === "#text" && parentNode !== null) {
    editor.observableConsole.log(`text node; data: ${currentNode.data}`);
    const divUpdate = sortedDivUpdates.first();
    const { startOffset, oldText, key, sentenceId, correction } = divUpdate;
    const endOffset = startOffset + oldText.length;
    const nodeText = currentNode.data;
    const nodeEnd = offset + nodeText.length;
    editor.observableConsole.log({ offset, startOffset, endOffset, nodeEnd });
    if (correctionInCurrentNode(offset, nodeEnd, startOffset, endOffset)) {
      if (nodeEnd >= endOffset) {
        updatedSortedDivUpdates = sortedDivUpdates.shift();
      }
      const underlineStart = startOffset - offset;
      const underlineEnd = endOffset - offset;

      const pre = nodeText.substring(0, underlineStart);
      const underlineText = nodeText.substring(underlineStart, underlineEnd);
      const post = nodeText.substring(underlineEnd);

      const underlineFragmentContainer = currentDocument.createElement("span");
      underlineFragmentContainer.id = `${key}-${offset}`;
      //  IE only adds classname in first arg. So add more classnames seperately.
      underlineFragmentContainer.classList.add("pt-entity");
      underlineFragmentContainer.classList.add(`pt-${CSS.escape(`ck${key}`)}`);

      let postOffset = offset;
      if (post.length > 0) {
        const postTextNode = currentDocument.createTextNode(post);
        parentNode.replaceChild(postTextNode, currentNode);
        parentNode.insertBefore(underlineFragmentContainer, postTextNode);
        const returnObj = addDivUpdates(
          editor,
          updatedSortedDivUpdates,
          parentNode,
          postTextNode,
          offset + (nodeText.length - post.length),
        );
        postOffset = returnObj.offset;
        updatedSortedDivUpdates = returnObj.updatedSortedDivUpdates;
      } else {
        parentNode.replaceChild(underlineFragmentContainer, currentNode);
      }
      if (pre.length > 0) {
        const preTextNode = currentDocument.createTextNode(pre);
        parentNode.insertBefore(preTextNode, underlineFragmentContainer);
      }
      editor.observableConsole.log("render underline", {
        underlineText,
        offset,
        pre,
        post,
      });
      ReactDOM.render(
        <UnderlineFragment
          underlineObserver={editor.props.underlineObserver}
          targetFontSize={editor.state.targetFontSize}
          text={underlineText}
          sentenceId={sentenceId}
          correction={correction}
          offset={offset}
        />,
        underlineFragmentContainer,
      );
      if (postOffset > offset) {
        offset = postOffset;
      } else {
        offset += nodeText.length;
      }
    } else {
      offset += nodeText.length;
    }
  } else if (
    currentNode.nodeName === "SPAN" &&
    Array.from(currentNode.classList).includes("pt-entity")
  ) {
    // apply underline only if current divUpdate is newer, otherwise just shift it
    const previouseJobId = currentNode.id.split(":")[0];
    const divUpdate = sortedDivUpdates.first();
    const { startOffset, oldText, key, sentenceId, correction } = divUpdate;
    const currentJobId = key.split(":")[0];
    const endOffset = startOffset + oldText.length;
    const nodeText = currentNode.textContent;
    const nodeEnd = offset + nodeText.length;
    editor.observableConsole.log({ offset, startOffset, endOffset, nodeEnd });
    if (
      (offset <= startOffset && nodeEnd > startOffset) ||
      (nodeEnd >= endOffset && offset < endOffset)
    ) {
      if (nodeEnd >= endOffset) {
        updatedSortedDivUpdates = sortedDivUpdates.shift();
      }
      if (currentJobId > previouseJobId) {
        const underlineStart = startOffset - offset;
        const underlineEnd = endOffset - offset;

        const pre = nodeText.substring(0, underlineStart);
        const underlineText = nodeText.substring(underlineStart, underlineEnd);
        const post = nodeText.substring(underlineEnd);

        const underlineFragmentContainer = currentDocument.createElement(
          "span",
        );
        underlineFragmentContainer.id = `${key}-${offset}`;
        //  IE only adds classname in first arg. So add more classnames seperately.
        underlineFragmentContainer.classList.add("pt-entity");
        underlineFragmentContainer.classList.add(
          `pt-${CSS.escape(`ck${key}`)}`,
        );

        let postOffset = offset;
        if (post.length > 0) {
          const postTextNode = currentDocument.createTextNode(post);
          parentNode.replaceChild(postTextNode, currentNode);
          parentNode.insertBefore(underlineFragmentContainer, postTextNode);
          const returnObj = addDivUpdates(
            editor,
            updatedSortedDivUpdates,
            parentNode,
            postTextNode,
            offset + (nodeText.length - post.length),
          );
          postOffset = returnObj.offset;
          updatedSortedDivUpdates = returnObj.updatedSortedDivUpdates;
        } else {
          parentNode.replaceChild(underlineFragmentContainer, currentNode);
        }
        if (pre.length > 0) {
          const preTextNode = currentDocument.createTextNode(pre);
          parentNode.insertBefore(preTextNode, underlineFragmentContainer);
        }
        editor.observableConsole.log("render underline", {
          underlineText,
          offset,
          pre,
          post,
        });
        ReactDOM.render(
          <UnderlineFragment
            underlineObserver={editor.props.underlineObserver}
            targetFontSize={editor.state.targetFontSize}
            text={underlineText}
            sentenceId={sentenceId}
            correction={correction}
            offset={offset}
          />,
          underlineFragmentContainer,
        );
        if (postOffset > offset) {
          offset = postOffset;
        } else {
          offset += nodeText.length;
        }
      } else {
        offset += nodeText.length;
      }
    } else {
      offset += nodeText.length;
    }
  } else if (currentNode.innerText) {
    const children = Array.from(currentNode.childNodes);
    for (const child of children) {
      editor.observableConsole.log(
        "current child:",
        child.nodeName,
        child.textContent,
      );
      const returnObj = addDivUpdates(
        editor,
        updatedSortedDivUpdates,
        currentNode,
        child,
        offset,
      );
      offset = returnObj.offset + addManualSeparatorOffset(child);
      updatedSortedDivUpdates = returnObj.updatedSortedDivUpdates;
    }
  }
  editor.observableConsole.log("return", {
    offset,
    updatedSortedDivUpdates: updatedSortedDivUpdates.toJS(),
    currentNode,
  });
  return {
    offset,
    updatedSortedDivUpdates,
  };
}; // addDivUpdates

export default addDivUpdates;
