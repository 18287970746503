import React from "react";

import { BehaviorSubject, never } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { getElementStyles } from "./css";
import Root from "../components";

const defaultSocketUrl = "https://io.perfecttense.com";
const defaultApiEnv = "production";

export const initializeTextarea = (
  target,
  elementNumber,
  overlayPositionObservers,
  dom$ = never(),
  creds,
  divContainer,
) => {
  const targetElementId = target.id || elementNumber;
  target.dataset.ptEditor = true;
  target.dataset.gramm_editor = false;
  target.dataset.testid = targetElementId;
  target.setAttribute("spellcheck", false);
  const scrollTop$ = new BehaviorSubject(target);
  let input$ = new BehaviorSubject({}); // not adding input delta right now
  const mouseMove$ = new BehaviorSubject(null);
  const mouseOut$ = new BehaviorSubject(null);
  const mutations$ = new BehaviorSubject(null);
  const underlineObserver = new BehaviorSubject(null);
  const tooltipObserver = underlineObserver.pipe(
    distinctUntilChanged(),
    debounceTime(200),
  );
  const overlayPosition$ = new BehaviorSubject(null).pipe(debounceTime(200));
  const mutationObserver = new MutationObserver(function(mutations) {
    const mutation = mutations[mutations.length - 1];
    const mutationTarget = mutation.target;
    mutations$.next(mutationTarget);
    for (let arrayLikeObj of Object.entries(overlayPositionObservers)) {
      arrayLikeObj[1]["observer"].next(target);
    } //fix the bug on a multi-editor page, resizing one, others will not know offset changed
  });

  const css$ = mutations$.pipe(debounceTime(300));
  mutationObserver.observe(target, {
    attributes: true,
    attributeFilter: ["style"],
  });
  const updateOriginalTextareaIfPortable = value => {
    target.value = value;
    target.innerText = value;
  };
  const setTextareaSelection = (startIndex, endIndex, direction = "none") => {
    target.focus();
    target.setSelectionRange(startIndex, endIndex, direction);
  };
  target.addEventListener("scroll", e => {
    scrollTop$.next(e.target);
  });
  target.addEventListener("input", e => {
    input$.next({ realTarget: e.target, iframeEl: null });
    mutations$.next(e.target);
    setTimeout(() => {
      overlayPosition$.next(e.target);
    }, 100);
  });
  target.addEventListener("mousemove", e => {
    mouseMove$.next(e);
  });
  target.addEventListener("mouseout", e => {
    mouseOut$.next(e);
  });
  window.addEventListener("resize", () => {
    mutations$.next(target);
    setTimeout(() => {
      overlayPosition$.next(target);
    }, 100);
  });
  window.addEventListener("scroll", () => {
    setTimeout(() => {
      mutations$.next(target);
      overlayPosition$.next(target);
    }, 100);
  });

  const { cs, cssText } = getElementStyles(target);
  const targetMargin = cs.margin;
  const targetPaddingLeft = parseFloat(cs.paddingLeft);
  const targetPaddingRight = parseFloat(cs.paddingRight);
  const targetPaddingBottom = parseFloat(cs.paddingBottom);
  const targetLineHeight = cs.lineHeight;
  const targetMarginBottom = parseFloat(cs.marginBottom);
  const targetMarginRight = parseFloat(cs.marginRight);
  const targetBackgroundColor = cs.backgroundColor;
  const targetPlaceHolder = target.placeholder;
  const targetValue = target.value;
  input$.next({ realTarget: target, iframeEl: null });
  target.style["background-color"] = "transparent";
  target.style["position"] = "relative";
  //target.style["z-index"] = "auto";
  // TODO style protection
  return (
    <Root
      unobstrusive={true}
      targetPlaceHolder={targetPlaceHolder}
      targetValue={targetValue}
      updateOriginalTextareaIfPortable={updateOriginalTextareaIfPortable}
      targetCssText={cssText}
      targetMarginBottom={targetMarginBottom}
      targetMarginRight={targetMarginRight}
      targetMargin={targetMargin}
      targetPaddingLeft={targetPaddingLeft}
      targetPaddingRight={targetPaddingRight}
      targetBackgroundColor={targetBackgroundColor}
      targetPaddingBottom={targetPaddingBottom}
      targetElementId={targetElementId}
      setTextareaSelection={setTextareaSelection}
      scrollTop$={scrollTop$}
      input$={input$}
      mouseMove$={mouseMove$}
      mouseOut$={mouseOut$}
      underlineObserver={underlineObserver}
      tooltipObserver={tooltipObserver}
      css$={css$}
      dom$={dom$}
      target={target}
      overlayPosition$={overlayPosition$}
      clientId={creds.clientId || ""}
      clientSecret={creds.clientSecret || ""}
      extension={creds.extension || false}
      socketUrl={creds.socketUrl ? creds.socketUrl : defaultSocketUrl}
      sandbox={creds.sandbox ? creds.sandbox : false}
      apiEnv={creds.apiEnv ? creds.apiEnv : defaultApiEnv}
      onGrammarScoreChanged={creds.onGrammarScoreChanged}
      showTextState={creds.showTextState}
      divContainer={divContainer}
      targetLineHeight={targetLineHeight}
    />
  );
};
