import { List } from "immutable";

import Job from "./job";

export const updateFakeJobToTextStateForInstantLoad = (
  oTimestamp,
  initialTextState,
) => {
  const jobKey = "1";
  const key = `${jobKey}:${oTimestamp}`;
  const text =
    "Perfect Tense are a very smart spellin gand grammar checker.\n\nYou can puts Perfect Tense in any text arae on your website, to instantly offer these functionality to all you're users. Try it out four yourself!\n";
  const fakeJob = new Job({
    key,
    text,
    timestamp: oTimestamp,
    changedSentences: List([1]),
    longText: false,
  });

  return initialTextState.merge({
    jobMap: initialTextState.jobMap.set(key, fakeJob),
  });
};
