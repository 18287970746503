import { logger } from "./log";
import addDivUpdates from "./addDivUpdates";
import removeUnderlineFragments from "./removeUnderlineFragments";

let observableConsole = logger(false);

const applyDivUpdates = (
  textState,
  editor,
  editorStateSubject,
  isTelerikPaste,
) => {
  const sortedDivUpdates = textState.divUpdates
    .filter(divUpdate => divUpdate.addUnderline)
    .sortBy(divUpdate => divUpdate.startOffset);
  observableConsole.log("sortedDivUpdates:", sortedDivUpdates.toJS());
  const removedDivUpdates = textState.divUpdates.filter(
    divUpdate => divUpdate.removeUnderline,
  );

  // for each removeUnderline DivUpdate, remove all UnderlineFragments with specified correction key
  observableConsole.log(removedDivUpdates.toJS());

  if (!isTelerikPaste && removedDivUpdates.size > 0) {
    removeUnderlineFragments(
      removedDivUpdates,
      editor.props.target,
      editor.props.iframeEl,
    );
  }

  //after removing underlines that need to be removed, add any new underlines
  if (sortedDivUpdates.size > 0) {
    addDivUpdates(editor, sortedDivUpdates, null, editor.props.target, 0);
  }

  const appliedDivUpdateKeys = (isTelerikPaste
    ? sortedDivUpdates
    : sortedDivUpdates.concat(removedDivUpdates)
  ).map(divUpdate => divUpdate.key);
  editorStateSubject.next({ appliedDivUpdateKeys });
};

export default applyDivUpdates;
