import restoreSelectionAfterCorrections from "./restoreSelectionAfterCorrections";
import { logger } from "./log";

let cursorConsole = logger(false);

const applyRangeToDom = (
  contentWindow,
  oldDom,
  target,
  targetStartOffset,
  targetEndOffset,
  oldSelectionRange,
  startAtNextNode,
  endAtNextNode,
) => {
  try {
    const outputSelection = contentWindow.getSelection();
    //outputSelection.empty();
    const outputSelectionRange = contentWindow.document.createRange();
    const restoredRangeObj = restoreSelectionAfterCorrections(
      oldDom,
      target,
      targetStartOffset,
      targetEndOffset,
      oldSelectionRange.collapsed,
      startAtNextNode,
      endAtNextNode,
    );
    outputSelectionRange.setStart(
      restoredRangeObj.startContainer,
      restoredRangeObj.startOffset,
    );
    outputSelectionRange.setEnd(
      restoredRangeObj.endContainer,
      restoredRangeObj.endOffset,
    );
    cursorConsole.log(oldSelectionRange, outputSelectionRange);
    outputSelection.removeAllRanges();
    outputSelection.addRange(outputSelectionRange);
  } catch (e) {
    cursorConsole.log(e.message);
  }
};

export default applyRangeToDom;
