import { List } from "immutable";

import { BehaviorSubject, from, timer, EMPTY } from "rxjs";
import {
  bufferCount,
  map,
  tap,
  filter,
  scan,
  debounce,
  distinctUntilKeyChanged,
  concatMap,
  delayWhen,
  pluck,
  bufferTime,
} from "rxjs/operators";
import Job from "./job";
import { Config } from "./configSchema";
import TextState from "./textState";

import applyDivUpdates from "./applyDivUpdates";
import getTargetStartEndOffset from "./getTargetStartEndOffset";
import applyRangeToDom from "./applyRangeToDom";
import removeUnderlineFragments from "./removeUnderlineFragments";
import { checkIsPasteFromDelta } from "./checkIsPasteFromDelta";
import { updateFakeJobToTextStateForInstantLoad } from "./updateFakeJobToTextStateForInstantLoad";

// usage:
// import initObservables from '...';
// in constructor of Editor:
// initObservables.call(this);
export default function() {
  let observableConsole = this.observableConsole;
  let cursorConsole = this.cursorConsole;
  this.configSubject = new BehaviorSubject(Config());
  this.configSubject.subscribe(config => {
    this.setState(prev => ({ ...prev, config }));
  });

  const debounceTimeSubject = new BehaviorSubject(
    timer(
      this.configSubject.value.debounceTime,
      this.configSubject.value.debounceTime,
    ),
  );

  const debounceInput = debounce(x => {
    const debounceTime =
      x.debounceTime === undefined
        ? debounceTimeSubject.value
        : timer(x.debounceTime);
    return debounceTime;
  });

  this.debounceTimeSubscription = this.configSubject
    .pipe(distinctUntilKeyChanged("debounceTime"))
    .subscribe(({ debounceTime }) =>
      debounceTimeSubject.next(timer(debounceTime, debounceTime)),
    );

  const jobRateLimitSubject = new BehaviorSubject(
    timer(
      this.configSubject.value.throttleTime,
      this.configSubject.value.throttleTime,
    ),
  );

  const limitJobRate = delayWhen(() => {
    return jobRateLimitSubject.value;
  });

  this.rateLimitSubscription = this.configSubject
    .pipe(distinctUntilKeyChanged("throttleTime"))
    .subscribe(({ throttleTime }) => {
      jobRateLimitSubject.next(timer(throttleTime, throttleTime));
    });

  const oTimestamp = Date.now();
  this.oTimestamp = oTimestamp + 100;

  this.editorStateSubject = new BehaviorSubject({});

  let initialTextState = new TextState({ score: 100, timestamp: oTimestamp });

  if (this.instantLoad) {
    initialTextState = updateFakeJobToTextStateForInstantLoad(
      this.oTimestamp,
      initialTextState,
    );
  }

  this.responseSubject = new BehaviorSubject(null);
  const responseBatchSize = this.configSubject.value.responseBatchSize;
  const maxTimeDelay = this.configSubject.value.responseMaxTimeDelay;
  const responseSubjectBuffer = this.responseSubject.pipe(
    bufferTime(maxTimeDelay, null, responseBatchSize),
    filter(x => x.length > 0),
  );
  responseSubjectBuffer.subscribe(x => {
    this.editorStateSubject.next({
      responses: x.filter(x => !!x).map(x => x.response),
    });
  });

  this.textStateSubject = new BehaviorSubject({ textState: initialTextState });

  const updateTextState = scan(
    (textStateMap, delta) => {
      const { textState, prevDiff } = textStateMap;
      const timestamp = Date.now();
      if (delta.diff) {
        const { diff } = delta;
        this.jobConsole.log("updateTextState: diff ", diff);
        const updatedTextState = textState.applyDelta(diff, timestamp);
        return {
          textState: updatedTextState,
          debounceTime: checkIsPasteFromDelta(diff, prevDiff)
            ? this.configSubject.value.debounceTimeOnPaste
            : undefined,
          prevDiff: diff,
        };
      } else if (delta.responses) {
        // delta.response is an array of response
        this.jobConsole.log("updateTextState: responses", delta.responses);
        const updatedTextState = textState.applyResponses(
          delta.responses,
          timestamp,
        );
        return { textState: updatedTextState };
      } else if (delta.timeout) {
        // TODO
      } else if (delta.accept) {
        this.jobConsole.log(`updateTextState: accept ${delta.accept}`);
        const updatedTextState = textState.accept(
          delta.accept,
          timestamp,
          delta.propSentenceIndex,
        );
        return {
          textState: updatedTextState,
          debounceTime: this.configSubject.value.debounceTimeOnAccept,
        };
      } else if (delta.ignore) {
        this.jobConsole.log(`updateTextState: ignore ${delta.ignore}`);
        const updatedTextState = textState.ignore(
          delta.ignore,
          timestamp,
          delta.propSentenceIndex,
        );
        return { textState: updatedTextState };
      } else if (delta.sentJob) {
        const job = delta.sentJob;
        this.jobConsole.log(`updateTextState: sent job ${job}`);
        const { jobMap, sentences } = textState;
        observableConsole.log(
          "===WE SET SENTENCE CHANGED TO FALSE=== sending job:",
          job.toJS(),
        );
        return {
          textState: textState.merge({
            sentences: sentences.map(s => s.set("changed", false)),
            jobMap: jobMap.set(job.key, job), // TODO
          }),
        }; // TODO
        // TODO update "changed" fields of sentences
      } else if (delta.appliedDivUpdateKeys) {
        const newDivUpdates = textState.divUpdates.filter(
          divUpdate => !delta.appliedDivUpdateKeys.includes(divUpdate.key),
        );
        return {
          textState: newDivUpdates.equals(textState.divUpdates)
            ? textState
            : textState.merge({ divUpdates: newDivUpdates }),
        };
      } else {
        // TODO log error
        return { textState };
      }
    },
    { textState: initialTextState, prevDiff: undefined },
  );

  const textState$ = this.editorStateSubject.pipe(updateTextState);

  textState$.subscribe(textStateMap => {
    this.jobConsole.log(
      "textState: ",
      textStateMap,
      textStateMap.textState.toJS(),
    );
    this.textStateSubject.next(textStateMap);
  });

  // TODO should this be debounced like job$ ?
  this.grammarScore$ = this.textStateSubject.pipe(
    map(obj => obj.textState),
    distinctUntilKeyChanged("score"),
    tap(({ score }) => this.grammarScoreConsole.log(`grammar score: ${score}`)),
    pluck("score"),
  );

  this.grammarScore$.subscribe(rawGrammarScore => {
    const constrainedGrammarScore = Math.max(0, Math.min(rawGrammarScore, 100));
    this.setState(
      prev => ({ ...prev, grammarScore: rawGrammarScore }),
      () => {
        this.grammarScoreConsole.log("new grammar score in subscription");
        this.props.onGrammarScoreChanged(
          constrainedGrammarScore,
          this.props.targetElementId,
        );
      },
    );
  });

  this.textStateSubject
    .pipe(
      map(obj => obj.textState),
      bufferCount(2, 1),
      filter(
        ([prev, curr]) =>
          prev.text !== curr.text ||
          prev.score !== curr.score ||
          prev.jobMap !== curr.jobMap ||
          prev.correctionMap !== curr.correctionMap ||
          prev.divUpdates !== curr.divUpdates,
      ),
      map(([_, curr]) => curr),
    )
    .subscribe(textState => {
      this.setState(prev => {
        this.currentText = textState.text;
        const x = this.props.target;
        if (x) {
          x.value = textState.text;
        }
        return { ...prev, textState };
      });
    });

  if (this.props.contentEditable) {
    const editor = this;

    // telerik paste
    editor.props.telerikRemoveDivUpdate$.subscribe(param => {
      if (param === "remove") {
        removeUnderlineFragments(
          editor.state.textState.divUpdates.filter(
            divUpdate => divUpdate.removeUnderline,
          ),
          editor.props.target,
          editor.props.iframeEl,
        );
      }
    });
    this.textStateSubject
      .pipe(
        map(obj => obj.textState),
        filter(obj => obj && !obj.divUpdates.isEmpty()),
        distinctUntilKeyChanged("divUpdates"),
      )
      .subscribe(textState => {
        try {
          observableConsole.log(
            "divUpdates changed, textState:",
            textState.toJS(),
          );
          const oldDom = editor.props.target.cloneNode(true);
          const iframeEl = editor.props.iframeEl;
          const contentWindow = iframeEl ? iframeEl.contentWindow : window;

          const selection = contentWindow.getSelection();
          if (selection.rangeCount === 0) {
            applyDivUpdates(
              textState,
              editor,
              this.editorStateSubject,
              isTelerikPaste,
            );
            return;
          }

          const oldSelectionRange = selection.getRangeAt(0);
          // check if is from telerik paste by checking startContainer is a non-textNode and class name
          const isTelerikPaste = !!(
            (oldSelectionRange.startContainer.className &&
              oldSelectionRange.startContainer.className.includes(
                "k-paste-container",
              )) ||
            oldSelectionRange.startContainer.ownerDocument.querySelector(
              "div.k-paste-container",
            )
          );
          observableConsole.log({ oldSelectionRange, isTelerikPaste });
          const {
            targetStartOffset,
            targetEndOffset,
            startAtNextNode,
            endAtNextNode,
          } = getTargetStartEndOffset(editor.props.target, oldSelectionRange);
          cursorConsole.log({
            targetStartOffset,
            targetEndOffset,
            startAtNextNode,
            endAtNextNode,
          });

          applyDivUpdates(
            textState,
            editor,
            this.editorStateSubject,
            isTelerikPaste,
          );

          // restore selection
          if (!isTelerikPaste) {
            applyRangeToDom(
              contentWindow,
              oldDom,
              editor.props.target,
              targetStartOffset,
              targetEndOffset,
              oldSelectionRange,
              startAtNextNode,
              endAtNextNode,
            );
          }
        } catch (e) {
          console.log(`Failed to apply div updates, Error: ${e.message}`);
          console.error(e);
        }
      }); // subscribe
  } // if contentEditable

  this.job$ = this.textStateSubject.pipe(
    debounceInput,
    map(obj => obj.textState),
    tap(() => observableConsole.log("job$")),
    concatMap(textState => {
      const { sentences, text } = textState;
      observableConsole.log("text state in job$:", textState.toJS());
      if (
        !sentences ||
        0 === sentences.count() ||
        !text ||
        0 === text.trim().length
      ) {
        observableConsole.log("empty");
        return EMPTY;
      }
      // get groups of consecutive dirty chunks; map to jobs, concat observables
      const dirty = sentences
        .map((sentence, index) => ({ sentence, index }))
        .filter(({ sentence }) => sentence.changed);

      if (dirty.isEmpty()) {
        observableConsole.log("dirty empty");
        return EMPTY;
      }

      observableConsole.log("dirty sentence is not empty", dirty.toJS());

      let groups = [];
      let prevIndex = -2; // make sure first iteration prevIndex is not actual index
      for (const elem of dirty) {
        if (prevIndex + 1 === elem.index) {
          // add to group
          groups[groups.length - 1].push(elem);
        } else {
          // new group
          groups.push([elem]);
        }
        prevIndex = elem.index;
      }

      // create a job from each group of consecutive dirty chunks
      return from(
        groups.map(group => {
          observableConsole.log(
            "current group",
            group.map(g => [g.index, g.sentence.toJS()]),
          );
          const text = group.map(s => s.sentence.text).join("");
          return new Job({
            text: text
              .substring(0, this.config.maxLength)
              .replace(/\xA0/g, " "), // backend cannot handle
            changedSentences: List(group.map(s => s.sentence.id)),
            timestamp: Date.now(),
            longText: text.length > this.config.maxLength,
          });
        }),
      );
    }),
    tap(x => observableConsole.log("### emitted:", x ? x.toJS() : x)),
    filter(job => job.text.trim().length > 0),
    tap(() => observableConsole.log("job$, after filter trim")),
    limitJobRate,
    tap(() => observableConsole.log("job$, after limit job rate")),
  );
}
