import React, { Component } from "react";
import entityStyles from "../../css/ptEntity.css";
import { distinctUntilChanged } from "rxjs/operators";

export const underlineFragmentInnerSpanClasses = [
  entityStyles["pt-decorated"],
  entityStyles["pt-entity"],
  entityStyles["pt-correction"],
  entityStyles["pt-correction-bg"],
  entityStyles["pt-suggestion"],
  entityStyles["pt-suggestion-bg"],
  entityStyles["within-content-editable"],
  entityStyles["within-textarea"],
  entityStyles["thin"],
  entityStyles["thick"],
];

export const underlineFragmentInnerSpanStyles = [
  "width",
  "text-align",
  "text-decoration",
  "text-decoration-line",
  "text-decoration-style",
  "text-decoration-color",
  "margin",
  "margin-top",
  "margin-right",
  "margin-bottom",
  "margin-left",
  "padding-bottom",
  "background-color",
  "transition",
  "transition-duration",
  "transition-timing-function",
  "transition-delay",
  "transition-property",
  "-ms-transition",
  "-webkit-transition",
  "background-image",
  "background-repeat",
  "background-repeat-x",
  "background-repeat-y",
  "background-position",
  "background-position-x",
  "background-position-y",
  "background-size",
];

const ptDecoratedStyles = {
  width: "100%",
  textAlign: "center",
  textDecoration: "none",
  margin: "3rem auto 0",
  paddingBottom: "1px",
};

const ptSuggestionBg = {
  backgroundColor: "rgba(255, 178, 43, 0.28)",
  transition: "background-color 500ms linear",
  msTransition: "background-color 500ms linear",
  WebkitTransition: "background-color 500ms linear",
};
const ptSuggestion = {
  backgroundColor: "transparent",
  backgroundImage: "linear-gradient(90deg, #ffb22b, #ffb22b)",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "0 100%",
};
const ptReplacementBg = {
  backgroundColor: "rgba(252, 75, 108, 0.28)",
  transition: "background-color 500ms linear",
  msTransition: "background-color 500ms linear",
  WebkitTransition: "background-color 500ms linear",
};
const ptReplacement = {
  backgroundColor: "transparent",
  backgroundImage: "linear-gradient(90deg, #fc4b6c, #fc4b6c)",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "0 100%",
};

const ptThickSize = {
  backgroundSize: "100% 3px",
};

const ptThinSize = {
  backgroundSize: "100% 2px",
};

const withinContentEditable = {
  paddingBottom: "2px",
};

export default class UnderlineFragment extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      focusedEntityKey: null,
      underlineObserverValue: props.underlineObserver.value,
      hoverOnCard: false,
    };
  }

  componentDidMount() {
    this.underlineSubscription = this.props.underlineObserver
      .pipe(
        distinctUntilChanged((a, b) => {
          if (!a || !b) {
            return a === b;
          } else {
            return (
              a.correction === b.correction &&
              a.position === b.position &&
              a.sentenceId === b.sentenceId
            );
          }
        }),
      )
      .subscribe(underlineObserverValue => {
        this.setState(prev => ({ ...prev, underlineObserverValue }));
      });
  }

  componentWillUnmount() {
    this.underlineSubscription.unsubscribe();
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (
      (this.state.focusedEntityKey === this.props.correction.key) !==
      (nextState.focusedEntityKey === nextProps.correction.key)
    ) {
      return true;
    }
    if (nextProps.offset !== this.props.offset) {
      return true;
    }

    if (nextProps.targetFontSize !== this.props.targetFontSize) {
      return true;
    }
    if (nextProps.text !== this.props.text) {
      return true;
    }
    if (nextProps.correction !== this.props.correction) {
      return true;
    }
    if (
      nextState.underlineObserverValue !== this.state.underlineObserverValue
    ) {
      return true;
    }
    if (nextState.hoverOnCard !== this.state.hoverOnCard) {
      return true;
    }
    return false;
  };

  onMouseEnter = () => {
    const {
      underlineObserver,
      sentenceId,
      sentenceIndex,
      correction,
    } = this.props;
    const node = this.node.current;
    const position = node.getBoundingClientRect();
    underlineObserver.next({
      position,
      sentenceId,
      sentenceIndex,
      correction,
    });
  };

  onMouseLeave = () => {
    this.props.underlineObserver.next(null);
  };

  render() {
    const { correction, text, targetFontSize } = this.props;
    const { underlineObserverValue } = this.state;
    if (!correction || !correction.signature) {
      return text;
    }

    const fontStyles = targetFontSize > 14 ? ptThickSize : ptThinSize;

    let bgStyle;

    if (correction.signature.includes("suggestion")) {
      if (
        underlineObserverValue &&
        underlineObserverValue.correction === correction
      ) {
        bgStyle = ptSuggestionBg;
      } else {
        bgStyle = ptSuggestion;
      }
    } else {
      if (
        underlineObserverValue &&
        underlineObserverValue.correction === correction
      ) {
        bgStyle = ptReplacementBg;
      } else {
        bgStyle = ptReplacement;
      }
    }

    return (
      <span
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        ref={this.node}
        className={entityStyles["pt-decorated"]}
        style={{
          ...ptDecoratedStyles,
          ...bgStyle,
          ...fontStyles,
          ...withinContentEditable,
        }}
      >
        {text}
      </span>
    );
  }
}
