import { List, Map, Record, Set } from "immutable";
import {
  tokenKey,
  correctionKey,
  parseCorrectionKey,
  parseTransformationKey,
} from "./keys";
import Token from "./token";
import { Correction } from "./correction";
import { logger } from "./log";
import { DivUpdate } from "./divUpdate";

let sentenceConsole = logger(false);

const SENTENCE_NEXT_ID = Symbol("SENTENCE_NEXT_ID");
/**
 * todo summarize class
 */
export default class Sentence extends Record(
  {
    id: 0, // Int
    tokens: List(), // List<Token>
    text: "DEFAULT_SENTENCE_TEXT", // String
    corrections: Map(), // Map<String, Correction>
    tokenIndexMap: Map(), // token id -> token index
    offsetInTextState: 0, // Int (character offset)
    score: 100, // Number
    timestamp: "0000_DEFAULT_SENTENCE_TIMESTAMP", // String
    changed: false, // Boolean
    offsetInTextStateWithoutNewline: 0,
  },
  "Sentence",
) {
  constructor(args = {}) {
    let tokenList = List();
    if (args.tokens) {
      tokenList = args.tokens.reduce(
        ({ acc, offset, offsetWithoutNewline }, t) => ({
          acc:
            t.text.length > 0
              ? acc.push(
                t.merge({
                  offsetInSentence: offset,
                  offsetInSentenceWithoutNewline: offsetWithoutNewline,
                }),
              )
              : acc,
          offset: offset + t.text.length,
          offsetWithoutNewline:
            offsetWithoutNewline + t.text.replace(/\r?\n|\r/g, "").length,
        }),
        { acc: List(), offset: 0, offsetWithoutNewline: 0 },
      ).acc;
    }
    if (args.id) {
      Sentence[SENTENCE_NEXT_ID] = Math.max(
        args.id,
        Sentence[SENTENCE_NEXT_ID],
      );
    }
    super({
      ...args, // fix token offsets:
      tokens: tokenList,
      text: tokenList.reduce((acc, t) => acc + t.text, ""),
      tokenIndexMap: !tokenList.isEmpty()
        ? Map(tokenList.map((t, i) => [t.id, i]))
        : Map(),
      id: args.id || Sentence[SENTENCE_NEXT_ID]++,
      score: args.corrections
        ? args.corrections.reduce((acc, c) => acc - c.penalty, 100)
        : 100,
    });
  }
  /**
   * Applies a Delta to the Sentence
   * @param {Delta} delta
   * @param {string} timestamp
   * @param {number} sentOffset TODO
   * @param {number} sentOffsetWithoutNewline
   * @param {boolean} isLastSentence
   * @return {Object}
   */
  applyDelta(
    delta,
    timestamp,
    sentOffset,
    sentOffsetWithoutNewline,
    isLastSentence = false,
  ) {
    if (!delta || !delta.ops || 0 === delta.ops.length) {
      return {
        delta: null,
        sentence: this.merge({
          offsetInTextState: sentOffset,
          offsetInTextStateWithoutNewline: sentOffsetWithoutNewline,
        }),
        sentenceChanged: false,
        tokenSentenceMapDiff: Map(),
        divUpdates: List(),
      };
    }
    const { tokens, corrections, tokenIndexMap } = this;
    // build up output tokens as we iterate thru
    let outputTokens = List();
    // "" for corrections
    let outputCorrections = corrections;
    let outputTokenIndexMap = tokenIndexMap;
    let tokenSentenceMapDiff = Map();
    let removedCorrections = Set();
    let outputChanged = this.changed;
    let divUpdates = List();
    // current delta; each step it's updated to the returned remainder delta
    let curDelta = delta;
    // current character offset in the sentence
    let offset = 0; //sentOffset;
    let offsetWithoutNewline = 0;
    let i = 0;
    let inTokens = tokens;
    if (tokens.isEmpty()) {
      inTokens = List.of(
        new Token({
          offsetInSentence: 0,
          offsetInSentenceWithoutNewline: 0,
          correction: null,
          ignored: null,
          value: "",
          after: "",
        }),
      );
    }
    let prevCorrection = null;
    let prevIgnored = null;
    const inputTokensCount = inTokens.count();
    for (let [index, curToken] of inTokens.entries()) {
      // sentenceConsole.log("sentence apply token, curToken:", curToken.toJS());
      const {
        delta: newDelta,
        token: newToken,
        tokenChanged,
        correctionsDiff,
      } = curToken.applyDelta(
        curDelta,
        offset,
        offsetWithoutNewline,
        prevCorrection,
        prevIgnored,
        isLastSentence && inputTokensCount - 1 === index,
      ); // last param isLastTokenInLastSentence, so we apply any remain delta to the token
      sentenceConsole.log(
        "after token apply delta, newToken:",
        newToken.toJS(),
      );
      if (tokenChanged) {
        sentenceConsole.log("token is changed");
        outputChanged = true;
        prevCorrection = curToken.correction;
        prevIgnored = curToken.ignored;
        // no longer corresponds to a job-sent token; remove job-relative keys from token id map
        // its corrections are no longer valid without all their tokensAffected, so remove them
        outputCorrections = correctionsDiff.reduce(
          (acc, correction, correctionKey) =>
            correction ? acc : acc.delete(correctionKey),
          outputCorrections,
        );
        removedCorrections = removedCorrections.union(correctionsDiff.keySeq());
      } else {
        prevCorrection = null;
        prevIgnored = null;
      }

      if (newToken) {
        sentenceConsole.log("there is new token", newToken.toJS());
        if (newToken.text.length === 0) {
          //empty token
          tokenSentenceMapDiff = tokenSentenceMapDiff.set(newToken.id, 0);
          outputTokenIndexMap = outputTokenIndexMap.delete(newToken.id);
        } else {
          // if token was not deleted, add it to the output list
          outputTokens = outputTokens.push(newToken);
          if (
            newToken.offsetInSentence !== curToken.offsetInSentence ||
            newToken.offsetInSentenceWithoutNewline !==
              curToken.offsetInSentenceWithoutNewline
          ) {
            outputTokenIndexMap = outputTokenIndexMap.set(newToken.id, i);
          }
          offset += newToken.text.length;
          offsetWithoutNewline += newToken.text.replace(/\r?\n|\r/g, "").length;
          i++;
        }
      } else {
        sentenceConsole.log("there is no new token");
        // if token was deleted, add it to collection of deleted tokens
        tokenSentenceMapDiff = tokenSentenceMapDiff.set(curToken.id, 0);
        outputTokenIndexMap = outputTokenIndexMap.delete(curToken.id);
      }
      // continue with remainder delta
      curDelta = newDelta;
    } // for

    // handle any remaining delta
    if (isLastSentence && curDelta && curDelta.ops && curDelta.ops[0].insert) {
      sentenceConsole.log(
        "all tokens in last sentence covered, yet delta of insert remaining, creating new token",
        curDelta.ops,
      );
      // should only have one insert.
      const lastNewToken = new Token({
        offsetInSentence: offset,
        offsetInSentenceWithoutNewline: offsetWithoutNewline,
        correction: null,
        ignored: null,
        value: curDelta.ops.pop().insert,
        after: "",
      });
      outputTokens = outputTokens.push(lastNewToken);
      outputTokenIndexMap = outputTokenIndexMap.set(lastNewToken.id, i);
      tokenSentenceMapDiff = tokenSentenceMapDiff.set(lastNewToken.id, this.id);
      offset += lastNewToken.text.length;
      offsetWithoutNewline += lastNewToken.text.replace(/\r?\n|\r/g, "").length;
      i++;
      outputChanged = true;
    }

    for (const c of removedCorrections) {
      divUpdates = divUpdates.push(
        new DivUpdate({
          key: c,
          correction: this.corrections.get(c),
          removeUnderline: true,
          startOffset:
            this.offsetInTextState +
            this.tokens.find(t => t.correction === c).offsetInSentence,
          oldText: null,
          newText: null,
          sentenceId: this.id,
          signature: this.corrections.getIn([c, "signature"]),
        }),
      );
    }

    curDelta =
      curDelta && curDelta.ops && curDelta.ops.length > 0 ? curDelta : null;

    sentenceConsole.log(
      "sentence apply to delta",
      curDelta,
      tokenSentenceMapDiff.toJS(),
    );
    if (
      outputTokens.count() === 0 ||
      (outputTokens.count() === 1 && outputTokens.first().text === "")
    ) {
      outputTokens = List();
      return {
        delta: curDelta,
        sentence: null,
        tokenSentenceMapDiff,
        divUpdates,
      };
    }
    const outputSentenceText = outputTokens.reduce(
      (acc, t) => acc + t.text,
      "",
    );

    outputCorrections = outputCorrections.filter((v, k) =>
      outputTokens.some(t => t.correction === k),
    );

    const outputSentence = new Sentence({
      ...this.toObject(),
      tokens: outputTokens,
      text: outputSentenceText,
      tokenIndexMap: outputTokenIndexMap,
      corrections: outputCorrections,
      offsetInTextState: sentOffset,
      offsetInTextStateWithoutNewline: sentOffsetWithoutNewline,
      score: outputCorrections.reduce((acc, corr) => acc - corr.penalty, 100),
      timestamp: outputChanged ? timestamp : this.timestamp,
      changed: outputChanged,
    });

    sentenceConsole.log(
      "sentence apply to delta return",
      outputSentence.toJS(),
    );
    return {
      delta: curDelta,
      sentence: outputSentence,
      tokenSentenceMapDiff,
      removedCorrections,
      divUpdates,
      sentenceChanged: outputChanged,
    };
  } // applyDelta()

  /**
   * Applies a corrections response to the sentence
   *
   * @param {Object} response
   * @param {Job} job
   * @param {Map} tokenIdMap
   * @param {Number} jobSentenceIndex
   * @returns {Object}
   */
  applyCorrections(response, timestamp, job, tokenIdMap, jobSentenceIndex) {
    sentenceConsole.log("applyCorrections", response);
    const { corrections: correctionsRaw, currentTokens } = response;
    const corrections = correctionsRaw
      .map(
        (c, i) =>
          new Correction({
            ...c,
            key: correctionKey({
              jobKey: job.key,
              sentenceIndex: jobSentenceIndex,
              correctionIndex: i,
            }),
          }),
      )
      .filter(c => c.applicable); // TODO: we are skipping chained correction for now.
    let newCorrections = Map(); // <- before we initiated with this.corrections, but then the previous corrections were kept.
    let tokenIdMapDiff = Map();
    let tokenSentenceMapDiff = Map();
    let updatedTokenIndexMap = Map(); // <- before we initiated with this.tokenIndexMap, but then the previous tokens were kept.
    let correctionMapDiff = Map();
    let offsetInSentence = 0;
    let offsetInSentenceWithoutNewline = 0;
    let tokenResponseIdStateIndexMap = Map(); // maping token id in response => token index in textState
    sentenceConsole.log({ curSentence: this.toJS() });
    let divUpdates = List();
    let newTokens = currentTokens.reduce((acc, t, index) => {
      const newToken = new Token({
        value: t.value,
        after: t.after,
        offsetInSentence,
        offsetInSentenceWithoutNewline,
      });
      tokenResponseIdStateIndexMap = tokenResponseIdStateIndexMap.set(
        t.id,
        index,
      );
      offsetInSentence += newToken.text.length;
      offsetInSentenceWithoutNewline += newToken.text.replace(/\r?\n|\r/g, "")
        .length;
      tokenIdMapDiff = tokenIdMapDiff.set(
        tokenKey({
          jobKey: job.key,
          sentenceIndex: jobSentenceIndex,
          tokenIndex: index,
        }),
        newToken.id,
      );
      tokenSentenceMapDiff = tokenSentenceMapDiff.set(newToken.id, this.id);
      updatedTokenIndexMap = updatedTokenIndexMap.set(newToken.id, index);
      return acc.push(newToken);
    }, List());
    sentenceConsole.log({
      newTokens: newTokens.toJS(),
      tokenIdMapDiff: tokenIdMapDiff.toJS(),
      tokenSentenceMapDiff: tokenSentenceMapDiff.toJS(),
      tokenResponseIdStateIndexMap: tokenResponseIdStateIndexMap.toJS(),
    });

    // Copy ignored corrections info from old tokens to new
    let oldTokenIndex = 0;
    let newTokenIndex = 0;
    while (
      oldTokenIndex < this.tokens.count() &&
      newTokenIndex < newTokens.count()
    ) {
      const oldToken = this.tokens.get(oldTokenIndex);
      if (!oldToken.ignored) {
        ++oldTokenIndex;
      } else {
        const oldTokenStart = oldToken.offsetInSentence;
        const oldTokenEnd = oldTokenStart + oldToken.text.length;
        const newToken = newTokens.get(newTokenIndex);
        const newTokenStart = newToken.offsetInSentence;
        const newTokenEnd = newTokenStart + newToken.text.length;
        if (newTokenEnd < oldTokenStart) {
          ++newTokenIndex;
        } else if (oldTokenEnd < newTokenStart) {
          ++oldTokenIndex;
        } else {
          // overlap - copy old ignored to new token
          sentenceConsole.log("copying ignored data", {
            oldToken: oldToken.toJS(),
            newToken: newToken.toJS(),
          });
          newTokens = newTokens.setIn(
            [newTokenIndex, "ignored"],
            oldToken.ignored,
          );
          if (newTokenEnd < oldTokenEnd) {
            ++newTokenIndex;
          } else if (oldTokenEnd < newTokenEnd) {
            ++oldTokenIndex;
          } else {
            ++newTokenIndex;
            ++oldTokenIndex;
          }
        }
      }
    }
    sentenceConsole.log({
      oldTokens: this.tokens.toJS(),
      newTokens: newTokens.toJS(),
    });

    sentenceConsole.log(
      "current sentence tokenIndexMap updating from:",
      this.tokenIndexMap.toJS(),
      "to",
      updatedTokenIndexMap.toJS(),
    );
    for (let i = 0; i < corrections.length; ++i) {
      const correction = corrections[i];
      sentenceConsole.log("current correction:", correction.toJS());
      const key = correction.key;
      sentenceConsole.log("current correction:", correction.toJS());
      let affected = Set();
      for (const token of correction.tokensAffected) {
        sentenceConsole.log({
          currentToken: token,
          correctionBasicInfo: correction.basicInfo.toJS(),
        });
        const theTokenKey = tokenKey({
          jobKey: job.key,
          sentenceIndex: jobSentenceIndex,
          tokenIndex: currentTokens.findIndex(t => t.id === token.id),
        });
        const tokenId =
          tokenIdMapDiff.get(theTokenKey) || tokenIdMap.get(theTokenKey);
        const tokenIndex = updatedTokenIndexMap.get(tokenId, -1);
        sentenceConsole.log({ theTokenKey, tokenId, tokenIndex });
        if (-1 === tokenIndex) {
          sentenceConsole.log("!!!TOKEN INDEX NOT FOUND!!!");
          continue;
        }
        const prevIgnored = newTokens.getIn([tokenIndex, "ignored"]);
        sentenceConsole.log({
          prev: prevIgnored ? prevIgnored.basicInfo.toJS() : null,
          now: correction.basicInfo.toJS(),
        });
        if (prevIgnored && prevIgnored.basicInfo.equals(correction.basicInfo)) {
          sentenceConsole.log("ignored");
          newTokens.setIn([tokenIndex, "ignored"], correction);
          affected = Set();
          break;
        }
        sentenceConsole.log("correction key:", key);
        const prevCorrection = newTokens.getIn([tokenIndex, "correction"]);
        if (
          prevCorrection &&
          parseCorrectionKey(prevCorrection).jobKey === job.key
        ) {
          // overlaps with a higher-penalty correction from the same job; don't apply
          sentenceConsole.log("overlap");
          affected = Set();
          break;
        } else {
          affected = affected.add(Map({ tokenIndex, key, correction }));
        }
      } // for token of tokensAffected
      for (const a of affected) {
        const tokenIndex = a.get("tokenIndex");
        const key = a.get("key");
        const correction = a.get("correction");
        // add correction key to affected token
        newTokens = newTokens.setIn([tokenIndex, "correction"], key);
        newCorrections = newCorrections.set(key, correction);
        correctionMapDiff = correctionMapDiff.set(key, this.id);
      }
      const sortedAffected = affected.sort(
        (a, b) => a.get("tokenIndex") - b.get("tokenIndex"),
      );
      if (!affected.isEmpty()) {
        const lastToken = newTokens.get(
          sortedAffected.last().get("tokenIndex"),
        );
        const underlinedText =
          sortedAffected
            .butLast()
            .reduce(
              (acc, x) => acc + newTokens.get(x.get("tokenIndex")).text,
              "",
            ) + lastToken.value;
        const lastTokenAfter = lastToken.after;
        divUpdates = divUpdates.push(
          new DivUpdate({
            key,
            correction,
            addUnderline: true,
            startOffset:
              this.offsetInTextState +
              newTokens.get(sortedAffected.first().get("tokenIndex"))
                .offsetInSentence,
            oldText: underlinedText,
            newText: underlinedText + lastTokenAfter,
            sentenceId: this.id,
            signature: correction.signature,
            oldAfter: lastTokenAfter,
          }),
        );
      }
    } // for i in 0...corrections.length
    // remove old corrections
    let startOffset = this.offsetInTextState;
    for (const { text, correction } of this.getRanges()) {
      if (correction) {
        correctionMapDiff = correctionMapDiff.set(correction, null);
        divUpdates = divUpdates.push(
          new DivUpdate({
            key: correction,
            correction: this.corrections.get(correction),
            removeUnderline: true,
            startOffset,
            newText: null,
            sentenceId: this.id,
            signature: this.corrections.get([correction, "signature"]),
          }),
        );
      }
      startOffset += text.length;
    }
    // for (const [k, v] of this.corrections.entries()) {
    //   correctionMapDiff = correctionMapDiff.set(k, null);
    //   divUpdates = divUpdates.push(
    //     new DivUpdate({
    //       key: k,
    //       correction: v,
    //       removeUnderline: true,
    //       newText: null,
    //       sentenceId: this.id,
    //       signature: v.signature,
    //     }),
    //   );
    // }
    const updatedSentence = new Sentence({
      ...this.toObject(),
      tokens: newTokens,
      corrections: newCorrections,
      timestamp,
    });
    sentenceConsole.log("updated sentence:", updatedSentence.toJS()); // TODO
    return {
      updatedSentence,
      tokenIdMapDiff,
      tokenSentenceMapDiff,
      correctionMapDiff,
      divUpdates,
    };
  } // applyCorrections()

  /**
   * if sentence response is unchange, we update sentence by:
   * set score to 100, remove corrections, remove corrections in tokens, changed to false, timestamp
   * @param {Integer} timestamp
   * @return {Sentence}
   */
  applyUnchangeResponse(timestamp) {
    const newTokens = this.tokens.map(token =>
      token.merge({ correction: null }),
    );
    return this.merge({
      timestamp,
      changed: false,
      score: 100,
      corrections: Map(),
      tokens: newTokens,
    });
  }

  /**
   *
   * @param {Sentence} existingSentence
   * @return {Sentence}
   */
  mergeWithExistingSentence(existingSentence) {
    if (existingSentence) {
      let mergedSentence = existingSentence;
      sentenceConsole.log("merging sentences", {
        existingSentence: existingSentence.toJS(),
        currentSentence: this.toJS(),
      });
      const mergedTokens = this.mergeTokens(mergedSentence.tokens);
      const mergedCorrections = mergedSentence.corrections.merge(
        this.corrections,
      );
      mergedSentence = mergedSentence
        .set("text", mergedSentence.text + this.text)
        .set("changed", true)
        .set("tokens", mergedTokens)
        .set("corrections", mergedCorrections);
      return new Sentence(mergedSentence.toObject());
    } else {
      return this;
    }
  }

  /**
   *
   * @param {List} existingTokens
   * @return {List}
   */
  mergeTokens(existingTokens) {
    return existingTokens.concat(this.tokens);
  }

  /**
   * Accepts a transformation
   * @this {Sentence}
   * @param {string} tKey the transformation key
   * @param {Map} tokenIdMap the enclosing TextState's tokenIdMap
   * @param {Job} job
   * @param {string} timestamp
   */
  accept(tKey, tokenIdMap, timestamp) {
    const args = parseTransformationKey(tKey);
    const { jobKey, sentenceIndex, transformationIndex } = args;
    const cKey = correctionKey(args);
    const correction = this.corrections.get(cKey);
    const transformation = correction.transformations[transformationIndex];
    sentenceConsole.log("entered accept", {
      curSentence: this.toJS(),
      tokenIdMap: tokenIdMap.toJS(),
      correctionKey: cKey,
    });
    const startIndex = this.tokens.findIndex(
      token => token.correction === cKey,
    );
    if (startIndex === -1) {
      sentenceConsole.log(
        "!!!!!!Cannot Accept. No Token With Given Correction Key",
      );
      return {
        updatedSentence: this,
        tokenIdMapDiff: Map(),
        tokenSentenceMapDiff: Map(),
      };
    }
    const affected = this.tokens.slice(
      startIndex,
      startIndex + transformation.tokensAffected.length,
    );
    const startOffset = affected.first().offsetInSentence;
    const startOffsetWithoutNewline = affected.first()
      .offsetInSentenceWithoutNewline;
    const added = transformation.tokensAdded.reduce((acc, { value, after }) => {
      const prev = acc.get(-1);
      return acc.push(
        new Token({
          value,
          after,
          offsetInSentence: prev
            ? prev.offsetInSentence + prev.text.length
            : startOffset,
          offsetInSentenceWithoutNewline: prev
            ? prev.offsetInSentenceWithoutNewline +
              prev.text.replace(/\r?\n|\r/g, "").length
            : startOffsetWithoutNewline,
        }),
      );
    }, List());
    const pre = this.tokens.take(startIndex);
    const lastAdded = added.get(-1);
    const lastAffected = affected.get(-1);
    const offsetDiff =
      lastAdded.offsetInSentence +
      lastAdded.text.length -
      (lastAffected.offsetInSentence + lastAffected.text.length);
    const offsetDiffWithoutNewline =
      lastAdded.offsetInSentenceWithoutNewline +
      lastAdded.text.replace(/\r?\n|\r/g, "").length -
      (lastAffected.offsetInSentenceWithoutNewline +
        lastAffected.text.replace(/\r?\n|\r/g, "").length);
    const shifted = this.tokens
      .skip(startIndex + affected.count())
      .map(t =>
        t
          .update("offsetInSentence", offset => offset + offsetDiff)
          .update(
            "offsetInSentenceWithoutNewline",
            offsetWithoutNewline =>
              offsetWithoutNewline + offsetDiffWithoutNewline,
          ),
      );
    sentenceConsole.log({
      added: added.toJS(),
      startIndex,
      startOffset,
      startOffsetWithoutNewline,
      pre: pre.toJS(),
      affected: affected.toJS(),
      offsetDiff,
      offsetDiffWithoutNewline,
      shifted: shifted.toJS(),
    });
    const newTokens = pre.concat(added, shifted);
    const updatedTokenIndexMap = newTokens.reduce(
      (newTokenIndexMap, token, index) => newTokenIndexMap.set(token.id, index),
      Map(),
    );

    // TODO
    // tokenKey is not unique (before/after accept), so we need to regenerate through newTokens
    // e.g. tokenAffected and tokenAdded length different
    const tokenIdMapDiff = newTokens
      .reduce(
        (acc, token) =>
          acc.set(
            tokenKey({
              jobKey,
              sentenceIndex,
              tokenIndex: updatedTokenIndexMap.get(token.id),
            }),
            token.id,
          ),
        Map([
          ...affected.map(t => [
            tokenKey({
              jobKey,
              sentenceIndex,
              tokenIndex: this.tokenIndexMap.get(t.id),
            }),
            0,
          ]),
        ]),
      )
      .filter((v, k) => tokenIdMap.get(k) !== v);
    const tokenSentenceMapDiff = Map([
      ...added.map(t => [t.id, this.id]),
      ...affected.map(t => [t.id, 0]),
    ]);
    const updatedSentence = new Sentence({
      ...this.toObject(),
      tokens: newTokens,
      corrections: this.corrections.delete(cKey),
      timestamp,
      tokenIndexMap: updatedTokenIndexMap,
      changed: true,
    });

    const divUpdate = new DivUpdate({
      key: cKey,
      correction,
      startOffset: startOffset + this.offsetInTextState,
      removeUnderline: true,
      oldText: affected.reduce(
        (acc, t, index) =>
          index === affected.size - 1 ? acc + t.value : acc + t.text,
        "",
      ),
      newText: added.reduce((acc, t) => acc + t.text, ""),
      oldAfter: affected.last().after,
      sentenceId: this.id,
      signature: correction.signature,
    });
    return { updatedSentence, tokenIdMapDiff, tokenSentenceMapDiff, divUpdate };
  } // accept()

  /**
   *
   * @param {*} tKey
   * @param {*} tokenIdMap
   * @param {*} timestamp
   */
  ignore(cKey, timestamp) {
    const correction = this.corrections.get(cKey);
    // correction.tokensAffected id is from backend response
    // we cannot find the affected token by id and update its correction directly
    // we need to traverse tokens and set correction to null if correction is cKey
    sentenceConsole.log({ correctionBasicInfo: correction.basicInfo.toJS() });
    const newTokens = this.tokens.map(token => {
      if (token.correction === cKey) {
        return token.merge({
          correction: null,
          ignored: correction,
        });
      }
      return token;
    });
    const updatedSentence = this.merge({
      tokens: newTokens, // remove corrections from tokens
      corrections: this.corrections.delete(cKey),
      timestamp, // ?
      score: this.score + correction.penalty, // add back penalties
    });
    return updatedSentence;
  } // ignore()

  /**
   * @return {List} ...
   */
  getRanges() {
    let ret = List();
    let p = this.tokens;
    let hasCorrection = false;
    let trailing = "";
    while (!p.isEmpty()) {
      const c = p.first().correction;
      hasCorrection = !!c;
      const chunk = p.takeWhile(token => token.correction === c);
      const signature =
        hasCorrection && this.corrections.getIn([c, "signature"], null);
      if (hasCorrection) {
        if (trailing.length > 0) {
          ret = ret.push({ text: trailing, correction: null, signature: null });
          trailing = "";
        }
        const chunkWithoutLast = chunk.skipLast(1);
        const lastToken = chunk.takeLast(1).first();
        const text = `${chunkWithoutLast.reduce(
          (acc, t) => `${acc}${t.text}`,
          "",
        )}${lastToken.value}`;
        trailing = lastToken.after;
        ret = ret.push({ text, correction: c, signature });
      } else {
        ret = ret.push({
          text: chunk.reduce((acc, t) => `${acc}${t.text}`, trailing),
          correction: null,
          signature: null,
        });
        trailing = "";
      }
      p = p.skip(chunk.count());
    }
    if (trailing.length > 0) {
      ret = ret.push({ text: trailing, correction: null, signature: null });
    }
    return ret;
  } // getRanges
} // class Sentence
Sentence[SENTENCE_NEXT_ID] = 1;
