export const generateInstantLoadResponse = (key, jobKey) => {
  return {
    responses: [
      {
        key,
        tokenized: [0, 62, 182],
        whitespaceBefore: "",
      },
      {
        corrections: [
          {
            afterText: " ",
            applicable: true,
            applied: -1,
            decoratedText: "four",
            initialEndIndex: 15,
            initialStartIndex: 11,
            jobKey,
            key: `${jobKey}:2:4,:replacement`,
            message: "This phrase appears to be misspelled or misused.",
            signature: `${jobKey}:2:4,:replacement`,
            transformations: [
              {
                requestId: 3,
                ruleId: "1.0.0",
                sentAfterTransform: "Try it out for yourself!",
                tokensAffected: [
                  {
                    id: 4,
                    value: "four",
                    after: " ",
                  },
                ],
                tokensAdded: [
                  {
                    id: 7,
                    value: "for",
                    after: " ",
                  },
                ],
                confidence: 1,
                isSuggestion: false,
                hasReplacement: true,
                message: "This phrase appears to be misspelled or misused.",
                penalty: 225,
                appliedAfterText: " ",
                appliedText: "for",
                key,
                sentenceIndex: 2,
                signature: `${jobKey}:2:4,:replacement`,
                type: "replacement",
              },
            ],
          },
        ],
        currentTokens: [
          {
            id: 1,
            value: "Try",
            after: " ",
          },
          {
            id: 2,
            value: "it",
            after: " ",
          },
          {
            id: 3,
            value: "out",
            after: " ",
          },
          {
            id: 4,
            value: "four",
            after: " ",
          },
          {
            id: 5,
            value: "yourself",
            after: "",
          },
          {
            id: 6,
            value: "!",
            after: "",
          },
        ],
        key,
        score: -125,
        sentenceIndex: 2,
      },
      {
        corrections: [
          {
            afterText: " ",
            applicable: true,
            applied: -1,
            caption: "Change the Verb",
            decoratedText: "are",
            initialEndIndex: 17,
            initialStartIndex: 14,
            jobKey,
            key: `${jobKey}:0:3,:replacement`,
            message: "Consider changing the verb form.",
            signature: `${jobKey}:0:3,:replacement`,
            transformations: [
              {
                requestId: 23,
                ruleId: "2.0",
                sentAfterTransform:
                  "Perfect Tense is a very smart spelling and grammar checker.\n\n",
                tokensAffected: [
                  {
                    id: 3,
                    value: "are",
                    after: " ",
                  },
                ],
                tokensAdded: [
                  {
                    id: 14,
                    value: "is",
                    after: " ",
                  },
                ],
                confidence: 1,
                isSuggestion: false,
                hasReplacement: true,
                message: "Consider changing the verb form.",
                penalty: 241.66666667500002,
                appliedAfterText: " ",
                appliedText: "is",
                key,
                sentenceIndex: 0,
                signature: `${jobKey}:0:3,:replacement`,
                type: "replacement",
              },
            ],
          },
          {
            afterText: " ",
            applicable: true,
            applied: -1,
            decoratedText: "spellin gand",
            initialEndIndex: 43,
            initialStartIndex: 31,
            jobKey,
            key: `${jobKey}:0:7,8,:replacement`,
            message: "This phrase appears to be misspelled or misused.",
            signature: `${jobKey}:0:7,8,:replacement`,
            transformations: [
              {
                requestId: 3,
                ruleId: "1.0.0",
                sentAfterTransform:
                  "Perfect Tense are a very smart spelling and grammar checker.\n\n",
                tokensAffected: [
                  {
                    id: 7,
                    value: "spellin",
                    after: " ",
                  },
                  {
                    id: 8,
                    value: "gand",
                    after: " ",
                  },
                ],
                tokensAdded: [
                  {
                    id: 12,
                    value: "spelling",
                    after: " ",
                  },
                  {
                    id: 13,
                    value: "and",
                    after: " ",
                  },
                ],
                confidence: 1,
                isSuggestion: false,
                hasReplacement: true,
                message: "This phrase appears to be misspelled or misused.",
                penalty: 225,
                appliedAfterText: " ",
                appliedText: "spelling and",
                key,
                sentenceIndex: 0,
                signature: `${jobKey}:0:7,8,:replacement`,
                type: "replacement",
              },
            ],
          },
        ],
        currentTokens: [
          {
            id: 1,
            value: "Perfect",
            after: " ",
          },
          {
            id: 2,
            value: "Tense",
            after: " ",
          },
          {
            id: 3,
            value: "are",
            after: " ",
          },
          {
            id: 4,
            value: "a",
            after: " ",
          },
          {
            id: 5,
            value: "very",
            after: " ",
          },
          {
            id: 6,
            value: "smart",
            after: " ",
          },
          {
            id: 7,
            value: "spellin",
            after: " ",
          },
          {
            id: 8,
            value: "gand",
            after: " ",
          },
          {
            id: 9,
            value: "grammar",
            after: " ",
          },
          {
            id: 10,
            value: "checker",
            after: "",
          },
          {
            id: 11,
            value: ".",
            after: "\n\n",
          },
        ],
        key,
        score: -366.666666675,
        sentenceIndex: 0,
      },
      {
        corrections: [
          {
            afterText: " ",
            applicable: true,
            applied: -1,
            decoratedText: "you're",
            initialEndIndex: 112,
            initialStartIndex: 106,
            jobKey,
            key: `${jobKey}:1:21,22,:replacement`,
            message: "This phrase appears to be misspelled or misused.",
            signature: `${jobKey}:1:21,22,:replacement`,
            transformations: [
              {
                requestId: 3,
                ruleId: "1.0.0",
                sentAfterTransform:
                  "You can put Perfect Tense in any text area on your website, to instantly offer these functionality to all your users. ",
                tokensAffected: [
                  {
                    id: 21,
                    value: "you",
                    after: "",
                  },
                  {
                    id: 22,
                    value: "'re",
                    after: " ",
                  },
                ],
                tokensAdded: [
                  {
                    id: 26,
                    value: "your",
                    after: " ",
                  },
                ],
                confidence: 1,
                isSuggestion: false,
                hasReplacement: true,
                message: "This phrase appears to be misspelled or misused.",
                penalty: 225,
                appliedAfterText: " ",
                appliedText: "your",
                key,
                sentenceIndex: 1,
                signature: `${jobKey}:1:21,22,:replacement`,
                type: "replacement",
              },
            ],
          },
          {
            afterText: " ",
            applicable: true,
            applied: -1,
            decoratedText: "arae",
            initialEndIndex: 42,
            initialStartIndex: 38,
            jobKey,
            key: `${jobKey}:1:9,:replacement`,
            message: "This phrase appears to be misspelled or misused.",
            signature: `${jobKey}:1:9,:replacement`,
            transformations: [
              {
                requestId: 3,
                ruleId: "1.0.0",
                sentAfterTransform:
                  "You can put Perfect Tense in any text area on your website, to instantly offer these functionality to all you're users. ",
                tokensAffected: [
                  {
                    id: 9,
                    value: "arae",
                    after: " ",
                  },
                ],
                tokensAdded: [
                  {
                    id: 25,
                    value: "area",
                    after: " ",
                  },
                ],
                confidence: 1,
                isSuggestion: false,
                hasReplacement: true,
                message: "This phrase appears to be misspelled or misused.",
                penalty: 225,
                appliedAfterText: " ",
                appliedText: "area",
                key,
                sentenceIndex: 1,
                signature: `${jobKey}:1:9,:replacement`,
                type: "replacement",
              },
            ],
          },
          {
            afterText: " ",
            applicable: true,
            applied: -1,
            caption: "Check Grammar",
            decoratedText: "these functionality",
            initialEndIndex: 98,
            initialStartIndex: 79,
            jobKey,
            key: `${jobKey}:1:17,18,:replacement`,
            message:
              "Consider replacing 'these functionality ' with 'this functionality'",
            signature: `${jobKey}:1:17,18,:replacement`,
            transformations: [
              {
                requestId: 33,
                ruleId: "0.0",
                sentAfterTransform:
                  "You can put Perfect Tense in any text area on your website to instantly offer this functionality to all your users. ",
                tokensAffected: [
                  {
                    id: 17,
                    value: "these",
                    after: " ",
                  },
                  {
                    id: 18,
                    value: "functionality",
                    after: " ",
                  },
                ],
                tokensAdded: [
                  {
                    id: 27,
                    value: "this",
                    after: " ",
                  },
                  {
                    id: 18,
                    value: "functionality",
                    after: " ",
                  },
                ],
                confidence: 1,
                isSuggestion: false,
                hasReplacement: true,
                message:
                  "Consider replacing 'these functionality ' with 'this functionality'",
                penalty: 166.666666675,
                appliedAfterText: " ",
                appliedText: "this functionality",
                key,
                sentenceIndex: 1,
                signature: `${jobKey}:1:17,18,:replacement`,
                type: "replacement",
              },
            ],
          },
        ],
        currentTokens: [
          {
            id: 1,
            value: "You",
            after: " ",
          },
          {
            id: 2,
            value: "can",
            after: " ",
          },
          {
            id: 3,
            value: "put",
            after: " ",
          },
          {
            id: 4,
            value: "Perfect",
            after: " ",
          },
          {
            id: 5,
            value: "Tense",
            after: " ",
          },
          {
            id: 6,
            value: "in",
            after: " ",
          },
          {
            id: 7,
            value: "any",
            after: " ",
          },
          {
            id: 8,
            value: "text",
            after: " ",
          },
          {
            id: 9,
            value: "arae",
            after: " ",
          },
          {
            id: 10,
            value: "on",
            after: " ",
          },
          {
            id: 11,
            value: "your",
            after: " ",
          },
          {
            id: 12,
            value: "website",
            after: "",
          },
          {
            id: 13,
            value: ",",
            after: " ",
          },
          {
            id: 14,
            value: "to",
            after: " ",
          },
          {
            id: 15,
            value: "instantly",
            after: " ",
          },
          {
            id: 16,
            value: "offer",
            after: " ",
          },
          {
            id: 17,
            value: "these",
            after: " ",
          },
          {
            id: 18,
            value: "functionality",
            after: " ",
          },
          {
            id: 19,
            value: "to",
            after: " ",
          },
          {
            id: 20,
            value: "all",
            after: " ",
          },
          {
            id: 21,
            value: "you",
            after: "",
          },
          {
            id: 22,
            value: "'re",
            after: " ",
          },
          {
            id: 23,
            value: "users",
            after: "",
          },
          {
            id: 24,
            value: ".",
            after: " ",
          },
        ],
        key,
        score: -516.666666675,
        sentenceIndex: 1,
      },
    ],
  };
};
