import React, { Component } from "react";
import styleCss from "../../css/style.css";
import cn from "classnames";

const LogoWatermark = ({ imgSrc, linkUrl }) => (
  <a
    href={linkUrl}
    target="_blank"
    className={styleCss["p-footer-icon-wrapper"]}
  >
    <img style={{ height: 25, verticalAlign: "middle" }} src={imgSrc} />
  </a>
);
const LinkWatermark = ({ imgSrc, linkUrl }) => (
  <a
    href={linkUrl}
    target="_blank"
    className={styleCss["p-footer-icon-wrapper"]}
  >
    <p className={styleCss["p-footer-icon-text"]}>Proofreading by</p>
    <img
      src={imgSrc}
      style={{ width: 70, height: 35, verticalAlign: "middle" }}
    />
  </a>
);

class WaterMark extends Component {
  state = {
    hovered: false,
    offsetHeightDiff: 0,
    offsetWidthDiff: 0,
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.assets.logo !== nextProps.assets.logo) {
      return true;
    }
    if (this.props.assets.icon !== nextProps.assets.icon) {
      return true;
    }
    if (this.props.linkUrl !== nextProps.linkUrl) {
      return true;
    }
    if (this.state.hovered !== nextState.hovered) {
      return true;
    }
    if (this.props.editorHasScroll !== nextProps.editorHasScroll) {
      this.setState({
        offsetHeightDiff:
          this.getElementProp(nextProps.shadowDiv.current, "offsetHeight") -
          this.getElementProp(nextProps.target, "offsetHeight"),
        offsetWidthDiff:
          this.getElementProp(nextProps.target, "offsetWidth") -
          this.getElementProp(nextProps.shadowDiv.current, "offsetWidth"),
      });
      return true;
    }
    if (
      nextState.offsetHeightDiff != this.state.offsetHeightDiff ||
      this.state.offsetWidthDiff != nextState.offsetWidthDiff
    ) {
      return true;
    }
    if (
      this.props.targetOffsetWidth != nextProps.targetOffsetWidth ||
      this.props.shadowDivOffsetHeight != nextProps.shadowDivOffsetHeight ||
      this.props.shadowDivOffsetWidth != nextProps.shadowDivOffsetWidth ||
      this.props.targetClientRectRight != nextProps.targetClientRectRight ||
      this.props.targetClientRectBottom != nextProps.targetClientRectBottom ||
      this.props.targetOffsetLeft != nextProps.targetOffsetLeft ||
      this.props.targetOffsetTop != nextProps.targetOffsetTop ||
      this.props.iframeClientRectLeft != nextProps.iframeClientRectLeft ||
      this.props.iframeClientRectTop != nextProps.iframeClientRectTop
    ) {
      return true;
    }
    return false;
  };

  onMouseEnter = () => {
    this.props.showTextState(this.props.textState);
    this.setState({
      hovered: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };

  getElementProp = (el, prop) => {
    return el ? el[prop] : 0;
  };

  getWaterMarkTop = waterMarkHeight => {
    const { target, iframeEl } = this.props;
    if (iframeEl) {
      return (
        iframeEl.offsetHeight +
        iframeEl.getBoundingClientRect().top +
        (window.pageYOffset || document.documentElement.scrollTop || 0) -
        waterMarkHeight
      );
    }
    return target.offsetTop + target.offsetHeight - waterMarkHeight;
  };

  getWaterMarkLeft = waterMarkWidth => {
    const { target, iframeEl } = this.props;
    if (iframeEl) {
      return (
        iframeEl.offsetWidth +
        iframeEl.getBoundingClientRect().left +
        (window.pageXOffset || document.documentElement.scrollLeft || 0) -
        waterMarkWidth
      );
    }
    return target.offsetLeft + target.offsetWidth - waterMarkWidth;
  };

  render() {
    const { assets, linkUrl, contentEditable, shadowDiv, target } = this.props;
    const { hovered } = this.state;
    let watermarkOffsetBottom =
      this.getElementProp(shadowDiv.current, "offsetHeight") -
      this.getElementProp(target, "offsetHeight");
    let watermarkOffsetRight = 5;
    let waterMarkWidth = hovered ? 85 : 40;
    let waterMarkHeight = hovered ? 65 : 40;
    const ceStyle = {
      position: "absolute",
      top: `${this.getWaterMarkTop(waterMarkHeight)}px`,
      left: `${this.getWaterMarkLeft(waterMarkWidth)}px`,
    };
    const nonCeStyle = {
      position: "absolute",
      bottom: `${5 + watermarkOffsetBottom}px`,
      right: `${watermarkOffsetRight}px`,
    };
    const style = contentEditable ? ceStyle : nonCeStyle;
    const classNames = cn(
      { [styleCss["link-watermark"]]: hovered },
      { [styleCss["logo-watermark"]]: !hovered },
    );
    return (
      <div
        className={classNames}
        style={style}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {hovered && <LinkWatermark imgSrc={assets.logo} linkUrl={linkUrl} />}
        {!hovered && <LogoWatermark imgSrc={assets.icon} linkUrl={linkUrl} />}
      </div>
    );
  }
}

export default WaterMark;
