import { Set, Record } from "immutable";

export const Config = Record(
  {
    debounceTime: 2000, // delay time between user's keystroke and sending request (ms)
    throttleTime: 500, // rate at which to send requests (ms)
    maxLength: 2000, // maximum request length that can be sent at a time
    maxBatch: 5, // maximum number of requests that can be sent in parallel
    contextWindowLeft: 0, // how many preceding sentences sent as relevant context for grammar
    contextWindowRight: 0, // how many succeeding sentences sent as relevant context for grammar
    active: false, // should the portable editor run on this page?
    grammarScore: false, // whether or not to calculate + display grammar score
    sendFeedback: false, // indicate whether or not to send feedback with user interactions
    isSensitive: true, // indicate whether any request text can be saved
    dictionaries: Set(), // additional dictionaries to be sent with requests
    customWords: Set(), // additional custom words to be sent with requests
    linkUrl: "https://www.perfecttense.com",
    customCss: "",
    responseBatchSize: 30, // max buffer size for correction response
    responseMaxTimeDelay: 100, // max delay for correction response
    debounceTimeOnPaste: 500, // delay time between user's paste action and sending request (ms)
    debounceTimeOnAccept: 0, // delay time between user's accept correction action and sending request (ms)
  },
  "Config",
);
