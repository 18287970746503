import { Record, List, Map } from "immutable";

const JOB_NEXT_ID = Symbol("JOB_NEXT_ID");

export default class Job extends Record(
  {
    key: "DEFAULT_JOB_KEY",
    text: "DEFAULT_JOB_TEXT",
    timestamp: "0000_DEFAULT_JOB_TIMESTAMP", // time job created
    changedSentences: List(), // ids of sentences sent for corrections, in order
    tokenizedResponse: null, // null | tokenized
    tokenizedTimestamp: null, // null | datetime
    sentenceResponses: Map(), // map sentence index -> response TODO ?
    storedResponses: List(),
    tokenizedSentenceIds: List(), // new sentence ids after tokenized response
    id: 0,
    longText: false,
  },
  "Job",
) {
  constructor(obj = {}) {
    const { timestamp = "0000_DEFAULT_JOB_TIMESTAMP" } = obj;
    const id = obj.id || Job[JOB_NEXT_ID]++;
    const key = `${id}:${timestamp}`;
    super({ ...obj, id, key });
  }
  getMessageJSON() {
    const { key, text, timestamp } = this;
    return JSON.stringify({ key, text, timestamp });
  }
}
Job[JOB_NEXT_ID] = 1;
